@import "../../styles/fira-theme.scss";

.PickStoreWrapper {
  width: 575px;
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 29px 29px 21px 29px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  > .BackButton {
    position: absolute;
    font-size: 30px;
    margin: 0;
    padding: 0;
    top: 25px;
    left: 29px;
    background: none;
    border: none;
    cursor: pointer;
  }
  > .Title {
    margin-bottom: 24px;
  }
  > .StoreList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px 43px;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 21px;
  }
  > .StoreListMin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 21px;
    > .StoreItem {
      margin: 0px 21.5px 0px 21.5px;
    }
  }
}

.StoreItem {
  cursor: pointer;
  &.Active {
    > .ImagePreview {
      border: 2px solid $primary-color;
    }
  }
  > .ImagePreview {
    font-size: 0;
    margin: 0 auto;
    width: 84px;
    height: 84px;
    min-width: 84px;
    min-height: 84px;
    background-color: $dark-gray-color;
    border-radius: 5px;
    margin-bottom: 6px;
    box-sizing: border-box;
    object-fit: contain;
  }
  > .StoreName {
    margin: 0;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 14px;
    max-height: 36.5px;
    overflow-wrap: anywhere;
    word-break: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    > .ImagePreview {
      border: 2px solid $primary-color;
    }
  }
}
