@import "../../styles/fira-theme.scss";

#VideoControls {
  background-color: transparent;
  height: 39px;
  position: absolute;

  .video_controls_wrapper {
    display: grid;
    grid-template-columns: 40px 40px 1fr 40px;
    height: 100%;
    width: 100%;

    .video_control {
      display: grid;
      place-items: center;
      background-color: $video_controls_bg_color;
      border-radius: 6px;
      cursor: pointer;

      #progressBar {
        --scrollbar-background: #ccc;
        -webkit-appearance: none;
        height: $volumen_bar_height;
        width: calc(100% - 32px);
        background-repeat: no-repeat;
        cursor: pointer;
      }

      /* Input Thumb */
      #progressBar::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: $volumen_bar_thumb_size;
        width: $volumen_bar_thumb_size;
        border-radius: 50%;
        background: var(--scrollbar-background);
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
      }

      #progressBar::-moz-range-thumb {
        -webkit-appearance: none;
        height: $volumen_bar_thumb_size;
        width: $volumen_bar_thumb_size;
        border-radius: 50%;
        background: var(--scrollbar-background);
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
      }

      #progressBar::-ms-thumb {
        -webkit-appearance: none;
        height: $volumen_bar_thumb_size;
        width: $volumen_bar_thumb_size;
        border-radius: 50%;
        background: var(--scrollbar-background);
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
      }

      #progressBar::-webkit-slider-thumb:hover {
        background: var(--scrollbar-background);
      }

      #progressBar::-moz-range-thumb:hover {
        background: var(--scrollbar-background);
      }

      #progressBar::-ms-thumb:hover {
        background: var(--scrollbar-background);
      }

      /* Input Track */
      #progressBar::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }

      #progressBar::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }

      #progressBar::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }

      #progressBar::-ms-thumb .pointer {
        cursor: pointer;
      }
    }
  }
}
