@import "../../../styles/fira-theme.scss";
.dashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  scroll-behavior: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .countersWrapper {
    display: grid;
    gap: 7px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    &.mobileCounter {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }
  }

  .desktopContainer {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 50%;
    grid-template-rows: 1fr;
    gap: 16px;
    &__firstCol,
    &__secondCol {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .mobileContainer {
    display: grid;
    max-width: 100%;
    grid-template-columns: minmax(0, 1fr);
    gap: 16px;
    .subGrid {
      max-height: 475px;
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "views interactions"
        "list interactions"
        "list interactions"
        "list interactions";
      &__list {
        grid-area: list;
        max-height: 340px;
      }
      &__views {
        grid-area: views;
      }
      &__interactions {
        grid-area: interactions;
      }
    }
  }
}
