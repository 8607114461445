@import "../../styles/fira-theme.scss";

.InputTextAreaWrapper {
  position: relative;
  display: flex;
  align-items: center;

  .InputTextArea {
    resize: none;
    left: 77px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid;
    border-color: $medium-gray-color;
    padding-left: 6px;
    padding-right: 6px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    height: 150px;
    width: 90px;
    &:focus {
      outline: none;
      border-color: $primary-color;

      + .left {
        color: $primary-color;
      }
    }

    &:disabled {
      border-color: $medium-gray-color;
      background-color: $main-background;
    }

    &.Error {
      padding-right: 22px;
    }

    &.Check {
      padding-right: 22px;
    }

    &.left {
      padding-left: 42px;
    }

    &.right {
      padding-right: 42px;
    }
  }
}
