@import "../../../styles/fira-theme.scss";

.EventPopUpDetailWrapper {
  display: flex;
  width: 272px;
  max-height: 494px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--blanco, #fff);
  box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;

  .PopupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #abafb1;
    font-size: 23px;
    height: 30px;
    .icon {
      cursor: pointer;
      &:hover {
        border-radius: 4px;
        border: 1px solid #abafb1;
      }
    }
    .left {
      justify-content: space-between;
      display: flex;
      gap: 4px;
    }
    .right {
      display: flex;
      margin-left: auto;
      font-size: 14px;
    }
  }

  .PopupInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    .EventInfo {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;
      gap: 8px;
      border-bottom: 1px solid #d5dde0;
      padding-bottom: 16px;
      .EventName {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        overflow-wrap: break-word;
        width: 100%;
      }
      .StoreName {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      .Status {
        display: flex;
        align-items: center;
        gap: 2px;
        margin-bottom: 8.5px;
        .Icon {
          display: flex;
          align-items: center;
          font-size: 18px;
          &.yellow {
            color: $primary-color;
          }
          &.black {
            color: black;
          }
          &.gray {
            color: #d5dde0;
          }
        }
        .Text {
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
        }
      }

      .Date {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
      }
    }

    .popUpButton {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      width: 100%;
      justify-content: center;

      &.canceled {
        color: #abafb1;
      }
      .alertIcon {
        color: $danger-color;
        font-size: 20px;
      }
      .icon {
        font-size: 20px;
      }
      > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .ModeratorsListWrapper {
      width: 100%;
      gap: 16px;
      display: flex;
      flex-direction: column;
      .Avatar {
        width: 34px;
        height: 34px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        border-radius: 34px;
        justify-content: center;
        color: $light-text;
        border: 1px solid $light-background;
        box-sizing: border-box;
        .image {
          width: 34px;
          height: 34px;
          border-radius: 34px;
        }
      }
      .ModeratorElement {
        display: flex;
        align-items: center;
        width: 100%;

        .Name {
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
        }
        .Role {
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
        }
        &.single {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          .left {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            > p {
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
          }
        }
        &.list {
          display: flex;
          .info {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-left: 8px;
          }
        }
      }
      .PreviewContainer {
        width: 100%;
        display: flex;
        .MultiElement {
          display: flex;
          width: 100%;
          cursor: pointer;
          .Title {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
          .MoreMods {
            position: relative;
            width: 34px;
            height: 34px;
            border-radius: 34px;
            display: flex;
            border: 1px dashed #d5dde0;
            background: $light-background;
            left: -23px;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            box-sizing: border-box;
          }
          .arrow {
            width: 30px;
            font-size: 8px;
            display: flex;
            align-items: center;
            margin-left: auto;
            justify-content: center;
          }
        }
      }
      .ListContainer {
        width: 100%;
        display: flex;
        max-height: 200px;
        overflow-y: auto;
        flex-direction: column;
        height: 100%;
        gap: 16px;
      }
    }
  }
}
