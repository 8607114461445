@import "../../../styles/fira-theme.scss";

.deleteModalWrapper {
  width: 276px;
  height: 133px;
  background-color: $light-background;
  border-radius: 10px;
  padding: 19px 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .buttonGroup {
    display: flex;
  }
}
