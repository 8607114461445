@import "../../styles/fira-theme.scss";

.statisticsContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .statisticsCounters {
    display: flex;
    flex-direction: row;
    gap: 8px;
    max-width: 100%;
  }

  .widgetsWrapper {
    gap: 16px;
    display: flex;
    flex-direction: row;
    .leftWidgets {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 80%;
      max-height: 370px;
    }
    .rightWidgets {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-height: 1015px;
    }
  }
}
