@import "../../styles/fira-theme.scss";

.storeStatsContainer {
  background: $light-background;
  border-radius: 15px;
  padding: 16px 15px 15px 20px;
  gap: 8px;
  min-width: 279px;
  width: auto;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;

    .headerTitle {
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
    }
    .smallText {
      font-size: 11px;
    }
  }

  .chartContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .itemContent {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 33%;
    min-width: 100px;
    height: -webkit-fit-content;

    .itemPie {
      display: flex;
      min-width: 100px;
      width: 100%;
      .inner {
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.CustomTooltip {
  background: #ffffff;
  padding: 5px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  .textValue {
    margin-left: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  .textLabel {
    margin-left: 5px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #abafb1;
  }
}

.TextLegend {
  color: #000;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.TextLabelChart {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.CustomLegend {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 16px;
  border: 1px solid #d5dde0;
  border-radius: 15px;
  width: 18%;
  padding-left: 15px;
  padding-bottom: 20px;
  margin-bottom: 15px;

  @media (max-width: 1100px) {
    width: 100%;
    padding-bottom: 20px;
  }

  .TitleLegend {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 15px;
  }

  .LegendItem {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    @media (max-width: 1100px) {
      flex-direction: row;
      overflow-x: auto;
      gap: 20px;
      margin-left: 20px;
      margin-right: 30px;
    }
    .LegendLabel {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      position: relative;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 25px;

      .Icon {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        flex-shrink: 0;
      }
      .LegendText {
        margin-left: 10px;
        font-size: 12px;
        width: 150px;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .LegendValue {
      font-size: 24px;
      font-weight: 600;
      margin-left: 30px;
    }
  }
}
.elementsGroup {
  display: flex;
  flex-direction: row;
  height: 322px;

  @media (max-width: 1100px) {
    flex-direction: column;
    height: 450px;
  }
}

.emptyElements {
  display: flex;
  justify-content: center;
  height: 250px;
  align-items: center;
}

.skeleton {
  height: 300px;
  width: 100%;
}
