@import "../../styles/fira-theme.scss";

.DateRangePicker {
  position: relative;
  .pickerButton {
    height: 30px;
    width: 167px;
    border-radius: 6px;
    background-color: $light-background;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 10px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    cursor: pointer;
    .icon {
      color: #dadada;
      font-size: 30px;
    }
    &.opened {
      border: 1px solid $primary-color;
      .icon {
        color: $primary-color;
      }
    }
    .dateText {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 5px;
    }
  }
  .calendarContainer {
    position: absolute;

    background: $light-background;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    right: 10px;
    z-index: 3;
    margin-top: 13px;
    padding: 28px 16px;
    display: flex;
    gap: 17px;
    flex-direction: column;

    .selectFilter {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }

    .rangeInputs {
      display: flex;
      flex-direction: row;
      gap: 56px;
    }
    .calendarRange {
      width: 100px;
    }
  }
}
