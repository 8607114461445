@import "../../../../styles/fira-theme.scss";

.GeneralInformationFormWrapper {
  width: 100%;
  height: 100%;
  &__Form {
    display: grid;
    width: 100%;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-auto-rows: auto;
    box-sizing: border-box;
    padding: 24px;
    gap: 24px;
    &__FormGroup {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;
      label {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &__TextArea {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 24px;
    label {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .Wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    > label {
      font-weight: 700;
      font-size: 16px;
    }
    .InputsWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      .Input {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}
