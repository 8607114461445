@import "../../../styles/fira-theme.scss";

.WidgetRadioButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  &__Title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  &__Options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    .Option {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 8px;
      label {
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
}
