@import "../../../styles/fira-theme.scss";

.URLInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  &__Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
