@import "../../../styles/fira-theme.scss";

.DialogWrapper {
  width: 768px;
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .HeaderDialog {
    display: flex;
    width: 100%;
    height: 72px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
    border-bottom: 1px solid #d5dde0;
  }

  .ProductImages {
    display: flex;
    gap: 8px;
    align-items: center;
    border-bottom: 1px solid #d5dde0;
    width: 95%;
    padding-left: 20px;
    padding-top: 20px;

    &.columnMode {
      flex-direction: column;
    }

    @media (max-width: 950px) {
      flex-direction: column;
    }

    .ProductImageList {
      width: auto;
      max-width: 810px;
      display: flex;
      gap: 8px;

      @media (max-width: 950px) {
        width: 100%;
      }

      > div {
        flex: 0 0 calc(20% - 8px);
        position: relative;
        margin: 8px;
      }

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  > .FooterDialog {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 72px;
    gap: 12px;
  }
}
