.PwdCodeForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .desc {
    text-align: center;
    width: 320px;
    line-height: 16px;
  }

  .FormGroup {
    margin-top: 30px;
    .tiny-text {
      font-weight: 500;
      text-align: center;
      margin-top: 17px;
      span {
        text-decoration: underline;
        font-weight: 700;
        margin-left: 4px;
        cursor: pointer;
      }
    }
  }

  .ButtonWrapper {
    margin-top: 50px;
    .ButtonInner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .icon {
        font-size: 18px;
        margin-top: 2px;
        margin-left: 10px;
      }
    }
  }
}
