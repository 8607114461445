@import "../../styles/fira-theme.scss";

.nameCell {
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .icons {
    font-size: 19px;
    display: flex;
    gap: 24px;
    .icon {
      &.click {
        cursor: pointer;
        :hover {
          color: $primary-color;
        }
      }
      &.inactive {
        color: $dark-gray-color;
      }
    }
  }
}
.topCells {
  display: flex;
  height: 95%;
  justify-content: center;
}
.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
}

.infiniteTableWrapper {
  height: 80%;
  margin-bottom: 50px;
}

.selectVisibility {
  display: flex;
  justify-content: center;
}
.selectIcon {
  width: 34px;
  display: flex;
  justify-content: center;
}

.emptyWrapper {
  width: 100%;
  height: stretch;
  background-color: $light-background;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $dark-gray-color;
  height: 100%;
  * {
    font-size: 400px;
  }
}
.videoState {
  width: 100%;
  border-radius: 4px;
  max-width: 104px;
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  background-color: #d5dde0;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  &.processed {
    background: var(--correcto, #66cb9f);
    color: #fff;
  }
  &.failed {
    background: $danger-color;
    color: #fff;
  }
  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
}

.fakeTable {
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  .fakeRow {
    border-top: 0.5px solid $dark-gray-color;
    border-bottom: 0.5px solid $dark-gray-color;
    box-sizing: border-box;

    .fakeCell {
      border-top: 0.5px solid #d0d0d0;
      border-bottom: 0.5px solid #d0d0d0;
      transition: 0.1s ease-in;
      box-sizing: border-box;
      &.first {
        border-left: 0.5px solid #d0d0d0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &.last {
        border-right: 0.5px solid #d0d0d0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .centered {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.LimitText {
  max-width: 500px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
