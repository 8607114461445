@import "../../../styles/fira-theme.scss";

.EventProductsWrapper {
  width: 100%;
  height: 95%;

  .TableWrapper {
    width: 100%;
    height: 90%;
    box-sizing: content-box;

  }
}

.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
}
