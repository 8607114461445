@import "../../../styles/fira-theme.scss";

.EventErrorWrapper {
  width: 523px;
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 16px;
  .Image {
    margin-top: 24px;
  }
  .Title {
    padding-top: 12px;
  }
  .Message {
    text-align: center;
    line-height: 24px;
    font-size: 14px;
    width: 320px;
    padding: 16px 0px 21px 0px;
  }

  .InnerButton {
    font-size: 14px;
    font-weight: 600;
  }
}
