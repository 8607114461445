@import "../../../styles/fira-theme.scss";

.EventBroadcastingResolutionWrapper {
  height: 100%;
  width: 100%;
  display: grid;
  grid-column: 1;
  grid-template-rows: 15px 65px 24px auto;
  gap: 40px;
  overflow-y: scroll;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 612px) {
    grid-template-rows: 15px 120px 12px auto;
    gap: 24px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light-gray-color;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .OptionContainer {
    display: flex;
    width: 256px;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: var(--blanco, #fff);
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.07);
    min-height: 100%;
    justify-content: space-between;
    cursor: pointer;
    &.selected {
      border-radius: 16px;
      border: 1px solid var(--botones-filled-yellow, #ffde07);
      background: var(--blanco, #fff);
      box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.07);
    }
    .titleCard {
      color: #000;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }

    .infoCard {
      color: #000;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
    .rectangleH {
      width: 148px;
      height: 74px;
      flex-shrink: 0;
      border-radius: 4px;
      background: var(--Borde, #d5dde0);
    }
    .rectangleV {
      width: 148px;
      height: 69px;
      transform: rotate(-90deg);
      flex-shrink: 0;
      border-radius: 4px;
      background: var(--Borde, #d5dde0);
    }
  }
  .SwitchContainer,
  .InputContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 612px) {
      flex-direction: column;
      align-items: center;
      height: auto;
    }
  }

  .InputContainer {
    align-items: flex-end;
  }

  .SwitchContainer {
    height: 270px;
    gap: 24px;
    padding-top: 40px;

    @media (max-width: 612px) {
      padding-top: 170px;
    }

    label {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .InputContainer {
    height: 65px;
    gap: 16px;

    .InputField {
      width: 360px;
    }

    label {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .PrevWrapper {
    .PrevTitle {
      height: 24px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 40px;
    }

    .PrevContainer {
      height: 304px;
      width: 100%;
    }
  }
}
