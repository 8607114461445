@import "../../../styles/fira-theme.scss";

.EventCreationPopUpWrapper {
  width: 429px;
  min-height: 326px;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 25px 35px rgba(10, 8, 36, 0.1);
  box-sizing: border-box;
  padding: 27px 24px 28px 24px;
  &__Top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 11px;
    span {
      font-size: 16px;
      font-weight: 500;
    }
    &__CloseIcon {
      cursor: pointer;
    }
  }
  &__EventCreationForm {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 0;
    margin: 0;
    &__InputContainer {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: center;

      &__Label {
        font-size: 14px;
        font-weight: 500;
      }

      &.SearchInput {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 40px;
      }

      &__SelectedOptions {
        width: 100%;
        max-height: 140px;
        overflow-y: scroll;
        overflow-x: hidden;

        flex-direction: column;
        padding-right: 8px;
        box-sizing: border-box;
        gap: 8px;

        &__SelectedItem {
          width: 100%;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #d5dde0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: 8px 12px 8px 8px;
          font-size: 14px;
          &__ItemCloseIcon {
            cursor: pointer;
          }
        }
      }

      &.Buttons {
        display: flex;
        gap: 6px;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border-top: 1px solid #d5dde0;
        padding-top: 8px;
        button {
          .InnerText {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
    &__InputContainerList {
      box-sizing: border-box;
      width: 100%;

      justify-content: center;

      &__Label {
        font-size: 14px;
        font-weight: 500;
      }

      &.SearchInput {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 40px;
      }

      &__SelectedOptions {
        width: 100%;
        max-height: 140px;
        overflow-y: scroll;
        overflow-x: hidden;

        flex-direction: column;
        padding-right: 8px;
        box-sizing: border-box;
        gap: 8px;

        &__SelectedItem {
          width: 100%;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #d5dde0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: 8px 12px 8px 8px;
          font-size: 14px;
          margin-bottom: 5px;
          &__ItemCloseIcon {
            cursor: pointer;
          }
        }
      }

      &.Buttons {
        display: flex;
        gap: 6px;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border-top: 1px solid #d5dde0;
        padding-top: 8px;
        button {
          .InnerText {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.SwitchContainer,
.InputContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 612px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
}

.InputContainer {
  align-items: flex-end;
}

.SwitchContainer {
  height: 47px;
  gap: 10px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: start;
  margin-top: 8px;
  margin-bottom: 8px;
  label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
  }
}
