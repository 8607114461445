@import "../../styles/fira-theme.scss";
.InputImageWrapper {
  background-color: $main-background;
  cursor: pointer;
  border-radius: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  > .Icon {
    font-size: 25px;
  }
  > .ImagePreview {
    z-index: 4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
