.Wrapper {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    :first-child {
      font-size: 20px;
      font-weight: 700;
    }
    :last-child {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .Content {
    display: flex;
    justify-content: center;
    gap: 24px;
  }
}

.Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 256px;
  height: 256px;
  box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s ease-in;
  border: 1px solid #fff;
  cursor: pointer;
  :first-child {
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
  }
  :last-child {
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
  }
  .RectContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Rect {
    border-radius: 4px;
    background-color: #d5dde0;
    &.Landscape {
      width: 148px;
      height: 74px;
      max-height: 74px;
    }
    &.Portrait {
      height: 148px;
      width: 74px;
      max-height: 148px;
    }
  }

  &.IsSelected {
    border-color: #ffde07;
  }
}
