@import "../../../styles/fira-theme.scss";

#SettingsSidebar {
  background-color: $light-background;
  border-radius: 8px;
  padding: 22px 0 22px 24px;
  transition: opacity 0.2s ease-in;

  #mainList {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: flex-start;
      cursor: default;
    }

    .sideBarElement {
      display: flex;
      gap: 11px;
      flex-direction: row;
      align-items: center;
      .sideBarElementIcon {
        position: relative;
        top: 2px;
      }
    }
    .sidebarTitle {
      font-size: 14px;
      font-weight: 400;
      color: $main-text;
      line-height: 24px;
    }

    .subList {
      list-style: none;
      padding-left: 31px;
      padding-right: 8px;
      display: grid;
      box-sizing: border-box;
      gap: 8px;

      .subListElement {
        display: flex;
        height: 24px;
        box-sizing: border-box;
        padding-left: 8px;

        &.subElementActive {
          background-color: $primary-color;
          border-radius: 4px;

          .sidebarSubTitle {
            padding-left: 4px;
          }
        }

        .sidebarSubTitle {
          font-size: 14px;
          font-weight: 400;
          color: $main-text;
          line-height: 24px;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }
}
