@import "../../../styles/fira-theme.scss";

.PermissionsWrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  min-height: 260px;
  &.isLoading {
    justify-content: center;
    align-items: center;
  }
  .PermissionsGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(237px, 1fr));
    grid-template-rows: auto;
    gap: 40px;
    @media (max-width: 878px) {
      grid-template-columns: repeat(2, minmax(237px, 1fr));
      grid-template-rows: auto;
    }
    @media (max-width: 610px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 16px;
    }
  }
}

.loaderContainer {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: block;
  font-size: 5px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
  margin-right: 15px;
  margin-left: 5px;
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4b4c4c,
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.5),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.7),
      1.8em -1.8em 0 0em #4b4c4c, 2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.5),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.7), 2.5em 0em 0 0em #4b4c4c,
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.5),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.7), 1.75em 1.75em 0 0em #4b4c4c,
      0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.5),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.7), 0em 2.5em 0 0em #4b4c4c,
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.5),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.7), -1.8em 1.8em 0 0em #4b4c4c,
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.5),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.7), -2.6em 0em 0 0em #4b4c4c,
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.5),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.7), -1.8em -1.8em 0 0em #4b4c4c;
  }
}
