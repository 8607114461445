@import "../../../styles/fira-theme.scss";

.SettingsStoreWrapper {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 1px 8px;
  box-sizing: border-box;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &.ItemContainer {
    width: 100%;
  }
}

.StoreProfileConfigWrapper {
  width: 100%;
  background-color: $light-background;
  min-height: 215px;
  border-radius: 8px;
  height: auto;
  &__Buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-bottom: 24px;
    padding-top: 16px;
  }

  &__TabsNavbar {
    display: flex;
    list-style: none;
    margin: 0;
    width: 100%;
    min-height: 52px;
    align-items: center;
    border-bottom: 1px solid $medium-gray-color;
    padding: 0 24px;
    gap: 20px;
    overflow-x: hidden;
    box-sizing: border-box;

    li {
      max-width: 30%;
      white-space: nowrap;
      overflow: hidden;
      height: 52px;
      text-overflow: ellipsis;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;

      span {
        color: #abafb1;
        font-size: 16px;
        font-weight: 700;
        line-height: 100%;
        padding-bottom: 4px;
        box-sizing: border-box;
        &:hover {
          color: $main-text;
        }
        &.active {
          color: $main-text;
          border-bottom: 2px solid $primary-color;
        }
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
    .divider:not(:last-child) {
      width: 1px;
      height: 24px;
      background-color: $medium-gray-color;
    }
    @media screen and (max-width: 656px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 530px) {
      li {
        white-space: nowrap;
      }
    }
  }
}

.ProfileSkeleton {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  padding: 24px;
  gap: 24px;
  box-sizing: border-box;
}
