@import "../../styles/fira-theme.scss";

.TextEditorInputWrapper {
  border: 1px solid $medium-gray-color;
  border-radius: 6px;
  min-height: 118px;
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
  overflow-x: hidden;
  &.IsDisabled {
    background-color: $main-background;
  }
  > .TextEditorControls {
    height: 30px;
    border-bottom: 1px solid $medium-gray-color;
    > .TextEditorControlButton {
      height: 100%;
      width: 30px;
      background-color: transparent;
      border: none;
      border-radius: 6px;
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
      cursor: pointer;
      &:hover {
        background-color: $light-gray-color;
      }
      &.IsActive {
        background-color: $primary-color;
      }
    }
    > .ColorPickerButtonWrapper {
      width: 30px;
      height: 100%;
      display: inline-block;
      position: relative;
      > .ColorPickerButton {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: none;
        border-radius: 6px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
      }
      > .ColorPickerWrapper {
        z-index: 5;
        position: absolute;
        top: 30px;
      }
    }
  }
  > .DraftEditor-root {
    box-sizing: border-box;
    display: block;
    box-sizing: border-box;
    max-width: 886px;
    border: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    resize: none;
    padding: 6px;
    .public-DraftEditor-content {
      min-height: 89.4px;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
    &:focus {
      outline: none;
      border: 1px solid $primary-color;
    }
  }
  > .TextCounter {
    position: absolute;
    bottom: 5.4px;
    right: 9px;
  }
}
