@import "../../styles/fira-theme.scss";

:root {
  --fc-border-color: #f7f8f9;
  --fc-daygrid-event-dot-width: 5px;
}

.fc .fc-timegrid-slot {
  height: 96px !important;
}
.fc .fc-timegrid-slot-label {
  width: 48px !important;
}
.fc-scrollgrid-sync-inner {
  height: 100%;
}

.fc-col-header-cell {
  height: 100%;
  width: 96%;
}
.fc-col-header-cell-cushion {
  height: 100%;
  width: 96%;
}
// table {
//   > colgroup {
//     width: 48px;
//     > col {
//       width: 48px;
//     }
//   }
// }
> col {
  min-width: 48px !important;
}
.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  width: 100%;
  text-align-last: center;
}
// .fc-day, .fc-day-wed, .fc-day-past,
.fc-daygrid-day {
  height: 120px;
}
.fc-daygrid-event,
.fc-timegrid-event {
  border: none;
  box-shadow: none !important;
  cursor: pointer;
}

.fc-daygrid-event {
  height: 15px;
  &:hover {
    background: rgba(0, 0, 0, 0.1) !important;
  }
}

.fc-timegrid-more-link-inner {
  height: 100%;
  min-height: 11.06px;
  padding: 0px;
}

.fc-timegrid-more-link {
  background: none;
  box-shadow: none;
}
.fc-daygrid-more-link {
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.fc .fc-scrollgrid-liquid {
  background-color: #ffff;
  font-family: Montserrat;
}
.fc-daygrid-day-top {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  margin-right: 8px;
}

.fc-day-today {
  background-color: #ffff !important;
  .fc-daygrid-day-number {
    background-color: $primary-color;
    border-radius: 20px;
    width: 25px;
    height: 25px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fc-scroller::-webkit-scrollbar-track {
  background-color: #ffff !important;
}

.fc .fc-daygrid-event-harness-abs {
  right: 0px !important; //esto evita que los eventos en mes se desborden de su casilla
}

.fc-popover {
  display: none;
}
// .fc-daygrid-event-harness {
//   &:hover {
//     background: rgba(0, 0, 0, 0.1);
//   }
// }
