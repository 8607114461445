.fira-comercial-banner-wrapper {
  width: 100%;
  .fira-comercial-banner-container {
    background-color: #067dc7;
    width: 100%;
    .fira-comercial-banner-img {
      cursor: pointer;
      width: 100%;
    }
  }
}
