@import "../../../styles/fira-theme.scss";
.LabelWrapper {
  background-color: $light-background;
  border-radius: 8px;
  padding: 20px;

  .ContainerLabel {
    display: flex;
    gap: 9px;
  }

  .ContainerButton {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    margin-top: 9px;
  }
  .AddImageButton {
    background: $light-background;
    cursor: pointer;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    .AddImageIcon {
      background-color: $primary-color;
      border-radius: 6px;
    }
  }

  .ContainerTitle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 9px;
  }
  .labelTitle .subTitle {
    color: var(--Texto-Principal-Titles-Content, #000);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .btnDelete {
    margin-bottom: 8px;
    border: 1px solid red;
    border-radius: 4px;
    width: 24px;
    display: flex;
    justify-content: center;
    height: 24px;
    align-items: center;
    cursor: pointer;
  }
}
