.fira-collections-widget-wrapper {
  width: 100%;
  display: flex;
  min-width: 213px;
  flex-direction: column;
  gap: 11px;
  position: relative;
  .fira-collections-widget-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .fira-collections-title {
      font-size: 36px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 0em;
    }
    .fira-collections-show-more {
      font-size: 18px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-decoration: underline;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #49a5df;
    }
  }
  .fira-collections-widget-mobile-group {
    width: 100%;
  }

  .fira-collections-widget-group {
    display: grid;
    gap: 24px;
    overflow: hidden;
    max-height: 209px;
    grid-template-columns: repeat(8, minmax(50px, 111px));
    align-self: center;
  }
}

.fira-collections-element {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  max-width: 116px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .fira-collections-element-img {
    aspect-ratio: 2 / 2;
    width: 100%;
    height: 100%;
    border-radius: 60%;
  }

  .fira-collections-element-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.fira-collections-custom-bullets-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 42;
  width: 100%;
  height: 40px;
  bottom: 37px;
  display: flex;
  .fira-collections-pagination {
    width: fit-content !important;
    background: rgba(255, 255, 255, 0);
    border-radius: 39px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-left: 18px;
    padding-right: 18px;
  }
  .fira-collections-bullet {
    border-radius: 50%;
    background-color: #90909072;
    width: 10px;
    height: 10px;

    &.swiper-pagination-bullet-active {
      top: 0px;
      background-color: var(--fira-bullet-color);
    }
    // background-color: var(--fira-bullet-color);
  }
}
