@import "../../styles/fira-theme.scss";

.LoginFormContainer {
  position: relative;
  max-width: 316px;
  align-self: flex-end;
  .FormGroup {
    position: relative;
    margin-top: 30px;
    .input-container {
      margin-top: 10px;
    }
  }

  .tiny-text {
    cursor: pointer;
    font-weight: 400;
    text-decoration: underline;
    position: relative;
    padding-top: 3px;
    padding-bottom: 12px;
    a {
      color: #010202;
    }
  }
}

.ButtonInner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  .icon {
    font-size: 18px;
    margin-top: 2px;
    margin-left: 10px;
  }
}

.transparent {
  button {
    background: transparent;
    border: none;
    float: right;
    margin-bottom: 12px;
    margin-top: 3px;
  }
  button > .tiny-text {
    padding: 0;
  }
  button:focus {
    outline: none;
    .tiny-text {
      color: $primary-color;
    }
  }
}

input:-webkit-autofill {
  box-shadow: inset 0 0 0px 9999px #fafafa;
  -webkit-box-shadow: inset 0 0 0px 9999px #fafafa;
}
