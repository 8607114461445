@import "../../../styles/fira-theme.scss";
.ContainerTable {
  background-color: $light-background;
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.TableWrapper {
  width: 97%;

  border-collapse: collapse;
  margin-left: 16px;
  margin-right: 16px;
}
.TableHeaderText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.TableHeaderTextLeft {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.ContainerTableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  padding-top: 0;
  margin-bottom: 12px;
  > .itemsHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-width: 275px;
    &.IsDisabled {
      grid-template-columns: 1fr;
      border-radius: 6px;
      border: 1px solid var(--Botones-Outlined, #d5dde0);
    }
    > .itemTableHeaderLeft {
      border: 1px solid var(--Botones-Outlined, #d5dde0);
      border-right: none;
      border-radius: 6px 0px 0px 6px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      &.IsDisabled {
        border: none;
        grid-template-columns: 1fr;
      }
    }
    > .itemTableHeaderRight {
      border-radius: 0px 6px 6px 0px;
      border: 1px solid var(--Botones-Outlined, #d5dde0);
      padding: 12px;
      justify-content: center;
      align-items: center;
      display: flex;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.AddButton {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
.flex-item {
  flex: 1;
}

.flex-item-3 {
  flex: 3;
}

.itemTr {
  border-bottom: 1px solid #d5dde0;
}
.optionsContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: baseline;
}
.tableOption {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 4px;
  cursor: pointer;
}
