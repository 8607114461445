.ViewHeaderWrapper {
  height: 70px;
  min-height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .LimitText {
    max-width: 500px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  > .LeftWrapper,
  > .RighWrapper {
    display: inline-flex;
    gap: 5px;
    align-items: center;
  }
  > .LeftWrapper {
    gap: 25px;
  }
}

.BackBtn {
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
}
