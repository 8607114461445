@import "../../../styles/fira-theme.scss";

.CreateStoreFormWrapper {
  min-height: 153px;
  width: 100%;
  background-color: $light-background;
  border-radius: 8px;
  height: auto;
  padding: 24px;
  box-sizing: border-box;
  gap: 16px;
  display: flex;
  flex-direction: column;
  min-width: 403px;
  .formTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
  .formWrapper {
    display: flex;
    gap: 24px;
    flex-direction: column;
    .rowGroup {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 24px;
    }
    .formGroup {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }
    .buttonsGroup {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 8px;
      justify-content: center;
      margin-top: 16px;
    }
  }
}
