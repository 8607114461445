.ContactInformationFormWrapper {
  width: 100%;
  height: 100%;
  &__Form {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    box-sizing: border-box;
    padding: 24px;
    gap: 24px;
    &__IconFormGroup {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: flex-end;

      &__Icon {
        width: 40px;
        height: 40px;
        background-color: #f7f8f9;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__FormGroup {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;
      label {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__phoneNumber {
        width: 100%;
        display: grid;
        grid-template-columns: 0.3fr 0.7fr;
        gap: 16px;
        &:first-child {
          flex: 0.6;
        }
      }
    }
  }
}
