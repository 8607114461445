@import "../../../styles/fira-theme.scss";

.WidgetImageInputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  &__Title {
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
  }
  &__Banner {
    width: 100%;
    height: 111px;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    &__Delete {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #d5dde0;
      background-color: $light-background;
      position: absolute;
      z-index: 9;
      top: 7px;
      right: 9px;
      border-radius: 4px;
    }
    .Preview {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
    .UploadHere {
      width: 100%;
      height: 100%;
      background-color: #f7f8f9;
      border-radius: 8px;
      border: 1px dashed #d5dde0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 11.33px;
      box-sizing: border-box;
      &__Icon {
        width: 46px;
        height: 46px;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
      }
      label {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.disabled {
  pointer-events: none;
}
