@import "../../../styles/fira-theme.scss";

.RolesWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  transition: ease all 0.5s;
  @media (max-width: 978px) {
    gap: 24px;
  }
  @media (max-width: 678px) {
    gap: 16px;
    flex-direction: column;
  }
}

.RoleButton {
  width: 100%;
  height: 40px;
  border: 1px solid #d5dde0;
  background-color: $light-background;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    border: 1px solid #000;
  }
  &.selected {
    border: 2px solid $primary-color;
  }
}
