@import "../../styles/fira-theme.scss";

.Button {
  font-family: inherit;
  padding: 3px 9px;
  white-space: nowrap;
  border-radius: 6px;
  box-sizing: border-box;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.Main {
    background-color: $primary-color;
  }
  &.MK {
    background-color: #ff265f;
    color: $light-text;
  }
  &.Border {
    background-color: transparent;
    border: 1px solid $medium-gray-color;
  }
  &:disabled {
    background-color: $dark-gray-color;
    opacity: 1;
    cursor: not-allowed;
  }
  &.Transparent {
    background-color: transparent;
    &:disabled {
      opacity: 1;
      cursor: not-allowed;
    }
  }
  &.Inactivate {
    background-color: $dark-gray-color;
  }
  &.Load {
    background-color: $light-background;
    border: 1px solid black;
  }
  &.Light {
    background-color: $light-background;
    border: 1px solid $medium-gray-color;
  }
  &.Danger {
    color: $light-text;
    background-color: $danger-color;
  }
  &.Delete {
    border: 1px solid $danger-color;
    color: $danger-color;
    background-color: transparent;
  }
  &.MainBorder {
    border: 1px solid $primary-color;
    background-color: transparent;
  }
  &.DangerLight {
    background-color: #f160634d;
    border-radius: 8px;
    border: 0.5px solid $danger-color;
    background: rgba(241, 96, 99, 0.3);
  }
  &.DisabledLink {
    pointer-events: none;
    opacity: 0.5;
    background-color: transparent;
  }
  &.Wrap {
    white-space: normal;
    padding: 4px;
  }
}
