@import "../../styles/fira-theme.scss";
.productWidgetContainer {
  background: $light-background;
  border-radius: 15px;
  padding: 16px 15px 15px 20px;
  gap: 8px;
  min-width: 279px;
  width: auto;
  display: flex;
  flex-direction: column;
  min-height: 347px;
  max-height: 370px;
  .header {
    padding-left: 9px;

    .title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .tableContainer {
    overflow: scroll;
    overflow-x: auto;
    overflow-y: auto;
    box-sizing: border-box;
    width: 100%;
    height: 91%;
    .nameRow {
      display: flex;
      gap: 14px;
    }
    .tableImg {
      display: flex;
      background-color: #d0d0d0;
      width: 34px;
      height: 34px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      img {
        height: 34px;
        border-radius: 8px;
        object-fit: cover;
        width: 100%;
      }
    }
    .tableText {
      margin-right: 30px;
    }
    .tableEllipsis {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      text-align: start;
    }
  }
}

.emptyProducts {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  img {
    width: 30%;
  }
}

.LoadingState {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.titleFormat {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.itemSeparator {
  width: 100%;
  height: 0px;
  border: 0.5px solid #e8e8e8;
  margin-bottom: 9px;
  margin-top: 9px;
}
