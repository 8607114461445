@import "../../styles/fira-theme.scss";

.row {
  display: flex;
  flex-direction: row;

  &.center {
    align-items: center;
  }
}

.formContainer {
  position: relative;
  max-width: 372px;
  align-self: flex-end;
  background-color: white;

  .formGroup {
    margin-top: 20px;
    position: relative;
    &.mr {
      margin-right: 9px;
    }
  }

  .errorText {
    color: $danger-color;

    &.none {
      display: none;
    }
  }

  .checkWrapper {
    margin-top: 31px;
    margin-bottom: 31px;
    .text {
      margin-left: 11px;
      max-width: 281px;
      a {
        color: black;
      }
    }
  }

  .ButtonInner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 21px;
      margin-left: 8px;
    }
  }
}
