@import "../../styles/fira-theme.scss";

.OwnerEventsViewWrapper {
  width: 100%;
  min-width: 715px;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  .CalendarWrapper {
    background-color: $light-background;
    height: calc(100% - 125px);
    border-radius: 8px 8px 0px 0px;
  }
}

.TextButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.InputSearchBar {
  width: 100%;
  position: relative;
  min-width: 228px;
  .ListContainer {
    position: absolute;
    box-sizing: border-box;
    z-index: 5;
    background-color: #f7f8f9;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 100%;
    border: 1px solid #d5dde0;
    top: 29px;
    padding: 8px 16px;
    overflow-y: auto;
    max-height: 258px;

    .ListElement {
      width: 100%;
      border-bottom: 1px solid #d5dde0;
      box-sizing: border-box;
      padding-top: 8px;
      padding-bottom: 8px;
      height: 100%;
      gap: 6px;
      display: flex;
      flex-direction: column;
      .InfoElement {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        .EventName {
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
        }
        .StoreName {
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
        }
        .DateTime {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          color: #abafb1;
          text-align: end;
        }
        .NormalStatus {
          font-size: 10px;
          font-weight: 700;
          line-height: 12px;
          padding: 2px 4px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          gap: 4px;

          &.Scheduled {
            background-color: #ffde07;
          }
          &.NotStarted {
            background-color: #d5dde0;
          }
          &.Started {
            color: #ffffff;
            background-color: #ff2c30;
          }
          &.Finished {
            color: #ffffff;
            background-color: #abafb1;
          }
          .Dot {
            border-radius: 20px;
            width: 6px;
            height: 6px;
            background-color: #ffffff;
          }
        }
        .TestStatus {
          font-size: 10px;
          font-weight: 700;
          line-height: 12px;
          padding: 2px 4px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          gap: 4px;
          &.Scheduled {
            border: 1px solid #ffde07;
          }
          &.NotStarted {
            border: 1px solid #d5dde0;
          }
          &.Started {
            color: #ff2c30;
            border: 1px solid #ff2c30;
          }
          &.Finished {
            border: 1px solid #abafb1;
          }
          .Dot {
            border-radius: 20px;
            width: 6px;
            height: 6px;
            background-color: #ff2c30;
          }
        }
      }
      &:first-child {
        padding-top: 0px;
      }
      &:last-child {
        border-bottom: 0px;
        padding-bottom: 0px;
      }
    }
  }
}

.CalendarHeader {
  display: flex;
  flex-direction: row;
  height: 48px;
  padding: 8px 16px;
  box-sizing: border-box;
  gap: 8px;
  .Controls {
    display: flex;
    flex-direction: row;
    width: 225px;
    align-items: center;
    .Arrows {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .Text {
      width: 140px;
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }
  }

  .ButtonGroup {
    display: flex;
    .Left {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
    .Middle {
      border-radius: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
    }
    .Right {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }
}
