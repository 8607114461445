@import "../../../styles/fira-theme.scss";

.invitationModalWrapper {
  max-width: 575px;
  max-height: 435px;
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 36px;
  position: relative;

  .row {
    display: flex;
    flex-direction: row;

    &.center {
      align-items: center;
    }
    .mr {
      margin-right: 8px;
    }
  }

  .invitationBackButton {
    position: absolute;
    font-size: 30px;
    z-index: 10;
    top: 30px;
    left: 38px;
    .icon {
      font-size: 30px;
    }
  }

  .header {
    width: 100%;
    margin-bottom: 29px;
    justify-content: center;
  }

  .noInvitationContent {
    width: 503px;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
  }

  .singleInvitationContent {
    width: 503px;
    text-align: center;
    display: grid;
    gap: 25px;
    > .buttonGroup {
      display: grid;
      grid-template-columns: 131px 131px;
      gap: 13px;
      justify-content: center;
    }
  }

  .invitationGroupContent {
    width: 503px;
    height: stretch;
    overflow-y: auto;
    .invitationsTable {
      min-width: 503px;
      border-collapse: inherit;
      border-spacing: 0px 3px;

      > thead {
        width: 100%;
        position: sticky;
        top: 0;
        background-color: $light-background;
        .left {
          width: 100px;
          padding-left: 26px;
          text-align: left;
        }
        .center {
          width: 140px;
          text-align: center;
        }
        .right {
          width: 140px;
          padding-right: 14px;
          text-align: center;
        }
      }
      tbody {
        .rowContent {
          height: 39px;
          align-items: center;
          background-color: $main-background;
          border-collapse: collapse;
          .LimitText {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }
          .left {
            width: 100px;
            padding-left: 26px;
            text-align: left;
            border-radius: 10px 0px 0px 10px;
          }
          .center {
            width: 120px;
            text-align: center;
          }
          .right {
            width: 140px;
            padding-right: 14px;
            text-align: center;
            border-radius: 0px 10px 10px 0px;
            > .buttonGroup {
              display: grid;
              grid-template-columns: 39px 39px;
              justify-content: center;
              gap: 5px;
            }
          }
        }
      }
    }
  }
}
