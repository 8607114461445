@import "../../styles/fira-theme.scss";

.wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 6px;
  background-color: $light-background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;

  img {
    width: 172.5px;
    height: 119px;
  }

  .message {
    margin-top: 13px;
    width: 313px;
    text-align: center;
  }

  .skip-wrapper {
    bottom: 19px;
    right: 15px;
    position: absolute;

    .buttonInner {
      display: flex;
      flex-direction: row;
      align-items: center;

      .mr {
        margin-right: 5px;
      }

      .icon {
        display: flex;
        flex-direction: row;
        font-size: 16px;
      }
    }
  }
}
