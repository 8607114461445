@import "../../styles/fira-theme.scss";
@import "react-big-calendar/lib/sass/styles";

.rbc-header {
  border-bottom: 1px solid $medium-gray-color;

  & + & {
    border-left: 1px solid $medium-gray-color;
  }

  .rbc-rtl & + & {
    border-right: 1px solid $medium-gray-color;
  }
}

.rbc-event {
  padding: 0;
  background-color: #ffffff00;
  color: $main-text;
  border-radius: 3px;
  border: none !important;
  &:focus {
    outline: none;
    background-color: $primary-color;
  }
  &.rbc-selected {
    background-color: $primary-color;
  }
  > .rbc-event-label {
    display: none;
  }
  > .rbc-event-content {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.rbc-show-more {
  background-color: transparent;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  height: 20px;
  color: $main-text;
  margin-left: 5px;
  &:hover {
    color: $primary-color;
  }
}

.rbc-off-range {
  color: $main-text;
}

.rbc-time-view,
.rbc-month-view {
  border-radius: 15px;
  border: 1px solid $medium-gray-color;
}

.rbc-time-header.rbc-overflowing,
.rbc-time-content {
  border: none;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.rbc-timeslot-group:last-child {
  border: none;
}

.rbc-month-header,
.rbc-view-header {
  background-color: $light-background;
  border-radius: 15px;
  .rbc-header {
    font-weight: 400;
    line-height: 15px;
  }
}

.rbc-today {
  background-color: transparent;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px;
  overflow: hidden;
}

.rbc-date-cell {
  background-color: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  .rbc-button-link {
    font-family: "Montserrat", sans-serif;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-top: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
  &.rbc-now {
    .rbc-button-link {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      background-color: $primary-color;
    }
  }
}

.rbc-header {
  padding: 18px 3px 18px 3px;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-header-gutter {
  border-bottom: 1px solid $medium-gray-color;
}
