@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.EllipseTimer {
  animation: rotation 2s linear infinite;
  transform-origin: center center;
}
