@import "../../styles/fira-theme.scss";

.DialogWrapper {
  padding: 22px 22px 20px 22px;
  box-sizing: border-box;
  width: 385px;
  background-color: $light-background;
  border-radius: 10px;
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 18px;
  justify-items: center;
  > .TextContent {
    max-width: 278px;
    text-align: center;
  }
  > .ButtonWrappers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 13px;
  }
}
