.ContainerViewsByDevices {
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  width: auto;
  border-radius: 10px;
  padding: 17px 17px 20px 17px;
  box-sizing: border-box;
  gap: 13px;

  .TitleWidget {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    margin-left: 8px;
  }

  .WrapperDevices {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    &.contentLoading {
      .itemContent {
        align-items: center;
        width: 30%;
        gap: 3px;
        padding: 4px 0px 4px 0px;
        height: -webkit-fit-content;

        .itemData {
          min-width: 40px;
          gap: 5px;
          width: 55%;
        }
      }
    }
    .itemContent {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      width: 28%;
      min-width: 100px;
      height: -webkit-fit-content;

      .itemPie {
        display: flex;
        min-width: 50px;
        width: 50%;
        height: 0;
        padding-bottom: 50%;
        position: relative;
        .inner {
          position: absolute;
          height: 100%;
          width: 100%;
        }
      }
      .itemData {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 50%;
        justify-content: center;
        .TitleData {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
        }
        .ValueData {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  .loadingPie {
    min-width: 40px;
    width: 40%;
    height: 0;
    padding-bottom: 40%;
    position: relative;
    .innerLoading {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 100%;
      background-color: #f7f8f9;
      display: flex;
      justify-content: center;
      align-items: center;
      > .child {
        position: absolute;
        top: 0;
        right: 100%;
        animation: 2s slide infinite ease-in-out;
        overflow: hidden;
        background: linear-gradient(
          90deg,
          #f7f8f9 0%,
          #d5dde0 50%,
          #f7f8f9 100%
        );
        width: 100%;
        height: 100%;
      }
      .one {
        animation-delay: 0.5s;
      }
      .two {
        animation-delay: 1.5s;
      }
      .innerCircle {
        position: absolute;
        top: 18%;
        background-color: #fff;
        border-radius: 100%;
        width: 65%;
        height: 65%;
        z-index: 2;
      }
    }
  }

  @keyframes slide {
    0% {
      right: 100%;
    }
    50% {
      right: -100%;
    }
    100% {
      right: -100%;
    }
  }
}
