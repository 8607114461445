.ChangePasswordForm {
  .desc {
    text-align: center;
    margin-top: 8px;
    width: 283px;
  }

  .FormGroup {
    margin-top: 20px;
    .input-container {
      margin-top: 10px;
    }
  }

  .ButtonWrapper {
    margin-top: 40px;
    button {
      padding: 0;
    }
    .ButtonInner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .icon {
        font-size: 18px;
        margin-top: 2px;
        margin-left: 10px;
      }
    }
  }
}
