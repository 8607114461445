.fira-video-range.rc-slider {
  .rc-slider-rail {
    height: 3px;
    background-color: white;
    opacity: 0.3;
    border-radius: 0;
  }
  .rc-slider-track {
    background-color: #ff0005 !important;
    height: 3px;
    border-radius: 0;
  }
  .rc-slider-handle {
    background-color: #ff0005;
    border: none;
    opacity: 1;
    width: 5px;
    height: 5px;
    margin-top: -1px;
  }
  .rc-slider-handle-dragging {
    box-shadow: 0 0 0 4px rgba($color: #b84848, $alpha: 0.5);
  }
}
