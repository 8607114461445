@import "../../styles/fira-theme.scss";

.IndicatorWrapper {
  width: 100%;
  border-radius: 8px;
  min-height: 8px;
  background-color: #f7f8f9;
  overflow: hidden;
  .IndicatorBar {
    height: 100%;
    max-width: 100%;
    border-radius: 8px;
  }
}

.Low {
  background-color: $danger-color;
  width: 25%;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.Medium {
  background-color: $primary-color;
  width: 50%;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.Max {
  background-color: $success-color;
  width: 100%;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}
