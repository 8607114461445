@import "../../styles/fira-theme.scss";
.SegmentedInputWrapper {
  // border: 1px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  position: relative;
  display: flex;
  > .Input {
    position: absolute;
    border: none;
    font-size: 20px;
    text-align: center;
    background-color: transparent;
    outline: none;
    z-index: 2;
  }
  > .Digit {
    box-sizing: border-box;
    border: 1px solid $medium-gray-color;
    background-color: $main-background;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    position: relative;
    margin-right: 6px;
    > .FocusOutline {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      box-shadow: 0 0 0 1.5px $primary-color;
      border-radius: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
