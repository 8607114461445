.FiraUserRoleWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  min-height: 143px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px;
  display: grid;
  grid-template-rows: 32px 1fr;
  gap: 19px;
  cursor: pointer;

  &.Selected {
    cursor: default;
  }

  > .ComponentBorder {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #d5dde0;
    border-radius: 8px;
    transition: 0.2s ease-in;
    &:hover {
      border: 1px solid #000000;
    }
    &.Selected {
      border: 3px solid #ffde07;
    }
  }

  > .TitleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    > .IconWrapper {
      height: 32px;
      font-size: 32px;
      color: #000000;
    }
    > .TextWrapper {
      font-weight: 700;
      font-size: 16px;
      line-height: 38px;
      color: #000000;
    }
  }
  > .DescriptionWrapper {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
  }
}
