@import "../../../styles/fira-theme.scss";

.FiraPermissions {
  width: 100%;
  min-height: 400px;
  background-color: $light-background;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  padding: 24px;
  .Title {
    font-size: 16px;
    font-weight: 600;
  }
  .Desc {
    font-size: 14px;
    font-weight: 400;
  }
  .PermissionsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 40px;
    @media screen and (max-width: 834px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .Buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    .Inner {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
