#videoControls {
	position: absolute;
	// display: none;
	background-color: rgba(255, 255, 255, 0.15);
	border-radius: 6px;
	right: auto;
	height: 185px;
	width: 32px;
	text-align: center;
	bottom: 9px;
	right: 9px;

	.pointer {
		cursor: pointer;
	}

	.video-control {
		opacity: 0.7;
		padding-top: 12px;
		padding-bottom:12px;
		&:hover {
			opacity: 1;
		}

		.d-none{
			display:none
		}
	}
}
