@import "../../styles/fira-theme.scss";

.instrutionsContainer{
  height: 417px;
  width: 100%;
  display: grid;
  justify-content: center;
  gap: 10px;
  grid-template-columns: 1fr 245px;
  .videoPlaceholder{
    position: relative;
    display: flex;
    background: $light-background;
    border-radius: 10px;
    height: 417px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .dateContainer{
      display: flex;
      background:black;
      height: 62px;
      width: 474px;
      border-radius: 11px;
      z-index: 2;
      color: $light-text;
      align-items: center;
      justify-content: space-around;
      .storeLogo{
        img{
          width: 45px;
          height: 45px;
          border-radius: 25px;
        }
      }

      .centerText{
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .rightlogo{
        font-size: 35px;
        display: flex;
      }
    }
    .messageContainer{
      display: flex;
      width: 429px;
      height: 149px;
      z-index: 1;
      margin-top: -58px;
      padding-top: 58px;
      margin-left: 10px;
      background: #b3b3b3;
      border-radius: 11px;
      color: $light-text;
      align-items: center;
      justify-content: center;
      .inside{
        width: 342px;
        text-align: center;
      }
    }
  }
  .chatPlaceholder{
    height: 417px;
    background: $light-background;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .message{
      width: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 100px;
      .bubble{
        font-size: 98px ;
      }
      .title{
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;
      }
      .sub{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
      }
    }

    .input{
      height: 40px;
      font-size: 180px;
      position: absolute;
      bottom: 140px;

    }
  }

}