@import "../../../styles/fira-theme.scss";

.Wrapper {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 1px 8px;
  box-sizing: border-box;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
}

.Form {
  background-color: $light-background;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
  .Title {
    padding: 10px 32px;
    color: $main-text;
    border-bottom: 1px solid $medium-gray-color;
    font-size: 16px;
    font-weight: 700;
  }
  .FormWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0px 32px;
    .Subtitle {
      color: $main-text;
      font-size: 16px;
      font-weight: 600;
    }
    .PermissionWrapper {
      display: flex;
      justify-content: space-between;
      span {
        color: $main-text;
        font-size: 14px;
        font-weight: 400;
        span {
          font-size: 14px;
          font-weight: 600;
          text-decoration-line: underline;
        }
      }
    }
  }
  .FormButtons {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
}
