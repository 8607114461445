@import '../../styles/fira-theme.scss';

.ImportProductsWrapper {
  width: 627px;
  height: 334px;
  background-color: $light-background;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .Title {
      width: 100%;
      height: 78px;
      display: flex;
      align-items: center;
      border-bottom: solid 0.5px $dark-gray-color;
      .items{
        width: 100%;
        display: flex;
        padding: 0px 16px;
        justify-content: space-between;
        align-items: center;
        .cross{
          cursor: pointer;
          font-size: 24px;
        }
      }

    }
    .Desc {
      width: 100%;
      height: 192px;
      display: flex;
      justify-content: center;
      border-bottom: solid 0.5px $dark-gray-color;
      .descContainer{
        display: flex;
        flex-direction: column;
        align-self: center;
        gap: 11px;
        a{
          color: black;
          font-weight: 600;
          text-decoration-line: underline;
          cursor: pointer;
        }
        .dropZone{
          cursor: pointer;
          width: 580px;
          height: 123px;
          background: #f7f8fa;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          border: 1px dashed $dark-gray-color;
        }
      }


    }
    .ButtonsWrapper {
      display: flex;
      flex-direction: row;
      height: 64px;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }

}

.disabled {
  cursor: default;
  pointer-events: none;
  svg {
    opacity: 0.5;
  }
}

//Load State
.LoadProductsWrapper {
  width: 436px;
  background-color: $light-background;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Title {
    display: flex;
    align-items: center;
    border-bottom: solid 0.5px $dark-gray-color;
    width: 100%;
    height: 78px;
    p{
      margin-left: 16px;
    }
  }
  .LoadProductsTable {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 217px;
    border-bottom: solid 0.5px $dark-gray-color;
    table {
      width: 402px;
      text-align: center;
      border-collapse: inherit;
      border-spacing: 0px 8px;

      td {
        border-top: 0.5px solid #d0d0d0;
        border-bottom: 0.5px solid #d0d0d0;
        height: 39px;
        .icon{
          font-size: 18px;
          &.green{
            color: $success-color;
          }    
          &.yellow{
            color: $primary-color;
          }      
          &.red{
            color: $danger-color;
          }
        }
        .info{
          padding-top: 2px;
        }
      }
      th:first-child,
      td:first-child {
        text-align: left;
        width: 40%;
        padding-left: 10px;
      }
      td:first-child {
        border-radius: 10px 0 0 10px;
        border-left: 0.5px solid #d0d0d0;
        .group{
          display: flex;
          align-items: center;
          gap: 4.4px;
        }
      }
      td:last-child {
        text-align: end;
        border-radius: 0 10px 10px 0;
        padding-right: 10px;
        border-right: 0.5px solid #d0d0d0;
        p{
          text-decoration: underline;
        }
      }
      .LoadProductsIcons {
        font-size: 25px;
        cursor: pointer;
      }
    }
  }
  .ButtonsWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 72px;
    gap: 20px;
  }
}



