@import "../../../styles/fira-theme.scss";

.InsertVideoWrapper {
  width: 627px;
  background-color: $light-background;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    height: 78px;
    align-items: center;
    padding-left: 16px;
    padding-right: 23px;
    .icon {
      cursor: pointer;
      font-size: 24px;
    }
  }
  .content {
    border-top: 1px solid $dark-gray-color;
    border-bottom: 1px solid $dark-gray-color;
    padding: 17px 23px 17px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .htmlContainer {
      height: 94px;
      width: 529px;
      border-radius: 8px;
      border: 1px dashed $dark-gray-color;
      background-color: $main-background;
      padding: 13px 29px 14px 21px;
      margin-bottom: 4px;
      color: $dark-gray-color;
      .ellipsis {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        word-break: break-word;
      }
    }
  }
  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding-left: 16px;
    padding-right: 23px;
    height: 64px;
  }
}

.insertVideoSuccess {
  background-color: $light-background;
  height: 136px;
  width: 430px;
  gap: 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 53px;
  padding-top: 52px;
  .icon {
    font-size: 120px;
    color: $primary-color;
  }
}
