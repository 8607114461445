@import "../../../styles/fira-theme.scss";

.addStreamProductsWrapper {
  width: 890px;
  height: 492px;
  background-color: $main-background;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 1px 17px 44px rgba(3, 2, 41, 0.07);
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 23px;

  .Ellipsis{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .header {
    display: flex;
    min-width: 244px;

    .title {
      margin-right: 13px;
    }
  }

  .content {
    width: 100%;
    height: 393px;
    background-color: $main-background;
    border-radius: 8px;
    box-shadow: 1px 17px 44px rgba(3, 2, 41, 0.07);
    border-radius: 8px;
    justify-self: center;
    > .inputContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }

    > .tableWrapper {
      height: stretch;
      overflow-y: auto;
      justify-content: center;
      height: 320px;
      text-align: -webkit-center;
      text-align: -moz-webkit-center;
      > table {
        min-width: 847px;
        border-collapse: inherit;
        border-spacing: 0px 10px;

        thead {
          > .headRow {
            border: 0.5px solid #d0d0d0;
            border-radius: 10px;
            position: sticky;
            top: 0;
            background-color: $main-background;
            .headCell {
              height: 60px;
              :first-child {
                width: 80px;
                border-radius: 10px 0px 0px 10px;
              }
              :last-child {
                width: 50px;
                border-radius: 0px 10px 10px 0px;
              }
              &.headCenter {
                text-align: -webkit-center;
                text-align: -moz-webkit-center;
              }
              &.nameCell {
                width: 100px;
              }
            }
          }
        }
        tbody {
          > .tableRow {
            height: 60px;
            background-color: $light-background;
            border: 0.5px solid #d0d0d0;
            border-radius: 10px;
            border-collapse: collapse;

            > .tableCell {
              height: 0px;
              border-top: 0.5px solid $dark-gray-color;
              border-bottom: 0.5px solid #d0d0d0;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              word-break: break-word;
              &.left {
                height: 60px;
                width: 80px;
                border-radius: 10px 0px 0px 10px;
                border-left: 0.5px solid $dark-gray-color;
                padding-left: 16px;
              }
              &.right {
                height: 60px;
                width: 50px;
                border-radius: 0px 10px 10px 0px;
                border-right: 0.5px solid $dark-gray-color;
              }
              &.center {
                height: 60px;
                text-align: -webkit-center;
                text-align: -moz-webkit-center;
              }
              .tableImage {
                height: 50px;
                width: 50px;
                background-color: $dark-gray-color;
                background-position: center;
                background-size: contain;
                border-radius: 6px;
                overflow: hidden;
                text-overflow: ellipsis;
                > img {
                  height: 50px;
                  width: 50px;
                }
              }
            }
          }
        }
      }
    }
  }

  .buttonGroup {
    display: flex;
    justify-self: right;
    width: 260px;
    place-content: space-between;
  }
}
