@import "../../../styles/fira-theme.scss";

.VideosListModalWrapper {
  width: 930px;
  height: 564px;
  background-color: $light-background;
  border-radius: 16px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__Top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px;
    &__Title {
      font-size: 16px;
      font-weight: 600;
    }
    &__Close {
      background-color: black;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__Table {
    width: 100%;
    height: 364px;
    overflow: hidden;
    box-sizing: border-box;
  }
  &__Buttons {
    height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
}
.separator {
  width: 100%;
  height: 1px;
  background-color: #d5dde0;
  margin: 8px 0;
  padding: 0;
  margin: 0;
}

.simpleLine {
  font-size: 32px;
  color: #d0d0d0;
}
