@import "../../styles/fira-theme.scss";

.ImageUploadWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  .UploadHere {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    &__icon {
      min-width: 46px;
      min-height: 46px;
      background-color: $dark-gray-color;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
    }
  }

  .Preview {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    top: 0;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.loadtext {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
}

.description {
  font-weight: 400px;
  font-size: 14px;
}

.label {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
}

.lightText {
  color: #8b8d97;
}
