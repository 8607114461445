@import "../../styles/fira-theme.scss";

.tableIcons {
  display: flex;
  font-size: 24px;
  justify-content: space-evenly;
  cursor: pointer;
  .disabled {
    color: $dark-gray-color;
  }
}
.statusContainer {
  justify-content: center;
  display: flex;
  > .statusInner {
    width: 98px;
    height: 29px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.green {
      background-color: $success-color;
      color: $light-text;
    }
    &.yellow {
      background-color: $primary-color;
    }
    &.red {
      background-color: $danger-color;
      color: $light-text;
    }
    &.gray {
      background-color: #abafb1;
      color: #010202;
    }
    &.transparent {
      background-color: transparent;
      border: solid 1px $light-gray-color;
    }
    &.test {
      background-color: transparent;
      border: solid 1px $primary-color;
    }
    &.noConfig {
      border: 0.5px solid var(--rojo, #f16063);
      background: var(--rojo-opacidad, rgba(241, 96, 99, 0.3));
    }
  }
}

.eventsHeader {
  display: flex;
  place-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  // min-width: 835px;
  > .eventList {
    min-width: 134px;
    background-color: $light-background;
    height: 44px;
    border-radius: 6px;
  }

  > .buttonGroup {
    display: flex;
    border-radius: 7px;
    min-width: 158px;

    > .CalendarViewButton {
      background-color: $light-background;
      border: solid 1px $light-gray-color;
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
      &.left {
        border-right: 0px;
        border-radius: 7px 0px 0px 7px;
      }
      &.right {
        border-left: 0px;
        border-radius: 0px 7px 7px 0px;
      }
      &.middle {
        border-radius: 0px;
      }
      &.IsActive {
        background-color: $primary-color;
        border: $primary-color;
        font-weight: 700;
      }
    }
  }
}
.TextButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}
.CalendarControllerWrapper {
  width: 58%;
  height: 44px;
  display: grid;
  grid-template-columns: 30px auto 30px;
  border: 1px solid $medium-gray-color;
  background-color: $light-background;
  border-radius: 7px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  .ControllerButtonIcon {
    font-size: 30px;
    cursor: pointer;
  }
  .ControllerLabel {
    text-align: center;
  }
}

.EmptyWrapper {
  width: 100%;
  height: stretch;
  background-color: $light-background;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  * {
    font-size: 400px;
  }
}

.EventName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.LimitText {
  max-width: 500px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
