@import '../../styles/fira-theme.scss';

.ProductListWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.SelectAll {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  width: 100%;
  font-size: 12px;
  box-sizing: border-box;
  padding: 8px 0;
  font-family: "Montserrat", sans-serif;
}

.SearchInput {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  padding: 8px;
  border-bottom: 1px solid $medium-gray-color;
  input {
    border: none;
    outline: none;
    width: 100%;
    font-family: "Montserrat", sans-serif;
  }
  input::placeholder {
    color: #ABAFB1
  }
}

.ProductItem {
  width: 100%;
  border-bottom: 1px solid $medium-gray-color;
  box-sizing: border-box;
  padding: 8px 8px 8px 0;
  display: grid;
  grid-template-columns: 16px 48px auto;
  gap: 8px;
  align-items: center;
  &__Image {
    width: 48px;
    height: 48px;
    background-color: $main-background;
    overflow: hidden;
    border-radius: 6px;
    img {
      object-fit: cover;
      width: 100%;
      object-position: bottom;
    }
  }
  &__ProductInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__Name {
      display: -webkit-box;
      font-size: 12px;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-height: 3.4em;
    }
    &__Price {
      font-size: 12px;
      font-weight: 600;
      color: $danger-color;
    }
  }
}