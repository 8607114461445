@import '../../../styles/fira-theme.scss';

.LandingTopBarWrapper {
  width: 100%;
  height: 56px;
  border-bottom: 1px solid $medium-gray-color;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  background-color: $light-background;

  &__BackBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 16px;
    cursor: pointer;
    span {
      font-size: 16px;
      font-weight: 600;
      @media (max-width: 700px) {
       display: none;
      }
    }


  }
  &__Edit {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    width: 50%;
    @media (max-width: 700px) {
     width: 65%;
    }
    &__FauxInput {
      width: 100%;
      max-width: 782px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid $medium-gray-color;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 8px;
      &__Url {
        flex-grow: 1;
      }
      input {
        border: none;
        outline: none;
        box-sizing: border-box;
        flex-grow: 1;
        text-decoration: underline;
        padding: 0;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 500;
        &:focus {
          border-bottom: 1px solid transparent;
        }
        &.invalid {
          color: $danger-color;
        }
        &.valid {
          color: $success-color;
        }
        &::placeholder {
          color: $dark-gray-color;
        }
      }

      &__InnerButton {
        font-size: 12px;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  &__LayoutButtons {
    display: flex;
    gap: 8px;
    &__Button {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      background-color: $light-background;
      &.activeLayout {
        border: 1px solid $medium-gray-color;
      }
    }
  }
}