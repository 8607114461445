@import '../../styles/fira-theme.scss';

.InputMediaWrapper {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #F7F8F9;
  box-sizing: border-box;
  cursor: pointer;

  .LoadedImage {
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    img,
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }

  .NoMedia {
    gap: 8px;
    .LoadIcon {
      background-color: rgba(0,0,0,0.4);
      border-radius: 12px;
      width: 46px;
      height: 46px;
    }
    .LoadLabel {
      font-weight: 500;
      font-size: 10px;
    }
    .Formats {
      font-size: 10px;
      font-weight: 400;
      :first-child {
        color: black;
      }
      :last-child {
        color: #8B8D97
      }
    }
  }


}

.InputMediaWrapper, .NoMedia, .LoadIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.normal {
  border: 1px solid $medium-gray-color
}

.dashed {
  border: 1px dashed $medium-gray-color
}

.InputMediaDisabled {
  opacity: 0.7;
  pointer-events: none;
}