@import "../../../styles/fira-theme.scss";

.startModalWrapper {
  min-width: 200px;
  max-width: 500px;
  min-height: 133px;
  background-color: $light-background;
  border-radius: 10px;
  padding: 19px 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  .messageText {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    span {
      font-weight: 600;
    }
  }
  .buttonGroup {
    display: flex;
  }
}
