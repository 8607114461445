@import "../../styles/fira-theme.scss";

.TeamDetailsWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto auto;
  grid-template-areas:
    "ProfileFormWrapper"
    "TeamTableWrapper"
    "ButtonWrapper";
  row-gap: 16px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 30px;
}

.ProfileFormWrapper {
  grid-area: ProfileFormWrapper;
  width: 100%;
  background-color: $light-background;
  border-radius: 8px;
}

.ButtonWrapper {
  grid-area: ButtonWrapper;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 16px;
  .DeleteButtonInner {
    font-size: 14px;
    font-weight: 500;
  }
}

.TeamTableWrapper {
  grid-area: TeamTableWrapper;
}

.TeamTable {
  min-height: 410px;
  max-height: 504px;
  width: 100%;
  background-color: $light-background;
  border-radius: 8px;
  &__Top {
    height: 52px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 23px 0 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &__Title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__TableLink {
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
  }

  &__TableLinkDisabled {
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    pointer-events: none;
    opacity: 0.5;
  }
}
