@import "../../../styles/fira-theme.scss";

.UserSecurityWrapper {
  width: 100%;
  min-height: 215px;
  background-color: $light-background;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__Title {
    width: 100%;
    box-sizing: border-box;
    height: 52px;
    padding: 14px 32px;
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__EmailFieldsetWrapper {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    box-sizing: border-box;
    fieldset {
      width: 352px;

      p {
        padding-top: 16px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.63px;
      }
    }
  }

  &__FormWrapper {
    width: 100%;
    flex-grow: 1;
    &__Form {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 0.4fr 1fr;
      grid-template-areas:
        "currentPwdField currentPwdField"
        "newPwdField confirmPwdField"
        "securityBar ."
        "buttons buttons";
      box-sizing: border-box;
      padding: 24px 32px;
      row-gap: 8px;
      column-gap: 16px;
    }
  }
}

#currentPwdField {
  grid-area: currentPwdField;
}

#newPwdField {
  grid-area: newPwdField;
  padding-top: 8px;
}

#confirmPwdField {
  grid-area: confirmPwdField;
  padding-top: 8px;
}

#securityBar {
  grid-area: securityBar;
  display: flex;
  flex-direction: column;
  gap: 8px;
  span {
    font-size: 12px;
    font-weight: 400;
  }
}

#buttons {
  grid-area: buttons;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  align-items: center;
  .CancelButton {
    font-size: 14px;
    font-weight: 400;
  }
  .SaveButton {
    font-size: 14px;
    font-weight: 500;
  }
}

.invalid {
  color: $danger-color;
  font-size: 10px;
  font-weight: 600;
}

.fieldsetContainer {
  label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    padding-bottom: 8px;
  }
  button {
    background: transparent;
    border: none;
    font-family: "Montserrat", sans-serif;
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
  }
}
