@import "../../styles/fira-theme.scss";

.statiticsChartWrapper {
  display: grid;
  grid-template-columns: 100%;
  width: auto;

  #generalStatiticsChart {
    background-color: $light-background;
    border-radius: 15px;

    .header {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr max-content;
      gap: 10px;

      .header_copy_wrapper,
      .header_copy_loading {
        padding-left: 20px;
      }

      .header_title {
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
      }

      .header_copy_loading {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .select_wrapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        padding-right: 23px;
      }
    }

    .small_text {
      font-size: 11px;
    }

    .chartWrapper {
      position: relative;
      width: 95%;
      height: 100%;
      //overflow-y: hidden;

      &::-webkit-scrollbar {
        height: 10px;
      }
    }

    .recharts-responsive-container {
      //min-width: 880px;
    }

    .recharts-yAxis tspan {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: #000000;
    }

    .recharts-xAxis tspan {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
    }

    g.recharts-cartesian-grid-vertical,
    g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis line,
    g.recharts-cartesian-axis-tick .recharts-cartesian-axis-tick-line {
      display: none;
    }

    .recharts-cartesian-grid-horizontal line,
    .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis > line {
      stroke: #f0f0f0;
    }
  }
}

.spin-container-chart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: transparent;
  animation: 1s linear infinite;
  z-index: 10;

  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }

  .spin::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #ffde07;
    border-radius: 50%;
    content: "";
    height: 40px;
    width: 40px;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
  }
}

.DateRangePicker {
  position: relative;
  .pickerButton {
    height: 30px;
    width: 213px;
    border-radius: 6px;
    background-color: $light-background;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 10px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    cursor: pointer;
    .icon {
      color: #000000;
      font-size: 30px;
    }
    &.opened {
      border: 1px solid $primary-color;
      .icon {
        color: $primary-color;
      }
    }
    .dateText {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 5px;
      width: 80%;
      justify-content: center;
    }
  }
  .calendarContainer {
    position: absolute;

    background: $light-background;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    right: 10px;
    z-index: 3;
    margin-top: 13px;
    //padding: 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    width: 412px;
    .selectFilter {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }
    .selectRange {
      margin-left: 16px;
      margin-top: 16px;
    }
    .rangeInputs {
      display: flex;
      flex-direction: row;
      gap: 39px;
      margin-left: 16px;
    }
    .calendarRange {
      width: 100px;
    }
  }
}

.css-b62m3t-container {
  width: 140px !important;
  height: 30px !important;
}
