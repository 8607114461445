.RecoveryForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .desc {
    text-align: center;
    width: 250px;
    line-height: 16px;
    font-weight: 500;
  }
  .FormGroup {
    margin-top: 30px;
    .form-label {
      font-weight: 400;
    }
  }

  .ButtonWrapper {
    margin-top: 45px;
    .ButtonInner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .icon {
        font-size: 18px;
        margin-top: 2px;
        margin-left: 10px;
      }
    }
  }
}
