.MockupsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;

  @media (max-width: 865px) {
    gap: 12px;

    .WebMockup,
    .WebMockup svg {
      width: 420px;
    }
  }

  .WebMockup svg {
    @media (max-width: 865px) {
      width: 390px;
    }
    @media (max-width: 785px) {
      width: 350px;
    }
  }

  @media (max-width: 737px) {
    flex-direction: column;
    align-items: center;

    .WebMockup svg {
      width: 100%;
    }
  }

  @media (max-width: 612px) {
    .WebMockup svg {
      width: 350px;
    }
  }

  .MockupShape {
    width: 248px;
    min-height: 304px;
    border: 1px solid #D5DDE0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;

    .ProductPlaceholder {
      width: 132px;
      height: 132px;
      background-color: #F7F8F9;
      border-radius: 4px;
    }

    .Dots {
      display: flex;
      gap: 8px;

      div {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #D9D9D9;
      }
    }

    .ProductName {
      font-size: 12px;
      font-weight: 500;
      color: #ABAFB1;
      padding: 16px 0;
    }

    .Prices {
      display: flex;
      justify-content: center;
      gap: 8px;
      font-size: 12px;
      color: #ABAFB1;
      padding-bottom: 8px;

      &:first-child {
        font-weight: 500;
      }

      &:last-child {
        font-weight: 300;
        text-decoration: line-through;
      }
    }
  }
}