@import '../../../styles/fira-theme.scss';

.TypeSelectWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .TypeSelect {
    display: block;
    border-radius: 4px;
    position: relative;
    width: 100%;
    &__Input {
      width: 100%;
      min-height: 32px;
      box-sizing: border-box;
      padding: 0 12px;
      border: 1px solid $medium-gray-color;
      display: flex;
      align-items: center;
      font-size: 10px;
      font-weight: 400;
      border-radius: 4px;
      justify-content: space-between;
      white-space: nowrap;
      position: relative;
      cursor: pointer;
      background-color: $light-background;
      overflow: hidden;
      &.isOpen {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &__Options {
      width: 100%;
      max-height: 320px;
      background-color: #F7F8F9;
      border: 1px solid $medium-gray-color;
      box-sizing: border-box;
      overflow: hidden;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 4;
      -webkit-box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.1));
      -moz-box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.1));
      box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.1));
      gap:6px;
      li {
        height: 42px;
        list-style: none;
        flex-grow: 1;
        display: flex;
        align-items: center;
        font-size: 12px;
        box-sizing: border-box;
        padding-left: 12px;
        gap: 12px;
        cursor: pointer;
        .Icon {
          font-size: 26px;
          position: relative;
          top: 2px;
        }
      }
      li:hover {
        background-color: $medium-gray-color;
      }
    }
  }
}

.openShadow {
  -webkit-box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.1));
  -moz-box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.1));
  box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.1));
}