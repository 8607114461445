@import "../../styles/fira-theme.scss";

.emptyWrapper {
  width: 100%;
  height: stretch;
  background-color: $light-background;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $dark-gray-color;
  height: 100%;
  * {
    font-size: 400px;
  }
}

.infiniteTableWrapper {
  height: 80%;
  margin-bottom: 50px;
}

.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
}
