@import "../../styles/fira-theme.scss";

.Events {
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 30px;
  overflow: hidden;
  .EventCreationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    .EventView {
      position: relative;
      width: 100%;
      height: 80%;
      margin-top: 10px;
      background-color: $light-background;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .EventTeamEncap{
        display: flex;
        flex-direction: column;
        height: 100%;
        .ViewInfo{
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 35px;
          left: 0;
          border-top: 1px solid $dark-gray-color;
          display: flex;
          align-items: center;
          padding-left: 16px;
          gap: 8px;
        }
      }
    }
    .EventButtons {
      background-color: $light-background;
      height: 85px;
      margin-top: 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px 0 30px;
    }
  }
}

.Inner {
  font-weight: 600;
  line-height: 17.07px;
}
