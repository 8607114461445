@import '../../styles/fira-theme.scss';

.InputWS {
  width: auto;
  height: 100%;
  border-radius: 4px;
  background-color: $light-background;
  border: 1px solid $medium-gray-color;
  display: grid;
  grid-template-columns: 24px 50px auto;
  align-items: center;
  gap: 4px;
  box-sizing: border-box;
  padding: 0 16px;
  input {
    width: 100%;
    border: none;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    outline: none;
    ::placeholder {
      font-family: 'Montserrat', sans-serif;
      color: #ABAFB1;
    }
  }
}