@import "../../styles/fira-theme.scss";

.CheckboxWrapper {
  margin: 0 10px 0 0;
  width: 17px;
  height: 17px;
  border: 1px solid $dark-gray-color;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in;
  cursor: pointer;
  > .Checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  > .CheckboxButton {
    width: 13px;
    height: 13px;
    background-color: transparent;
    border-radius: 4px;
    transition: 0.2s ease-in;
  }

  &:hover,
  &.IsFocus {
    border-color: $primary-color;
  }
  &.IsDisabled {
    &:hover {
      border-color: $dark-gray-color;
    }
    cursor: not-allowed;
    background-color: $main-background;
  }
  &.IsActive {
    border-color: $primary-color;
    > .CheckboxButton {
      background: $primary-color;
    }
    &.IsDisabled {
      border-color: $dark-gray-color;
      background-color: transparent;
      > .CheckboxButton {
        background: $dark-gray-color;
      }
    }
  }
}
