@import "../../styles/fira-theme.scss";

.events {
  background-color: $light-background;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 16px 24px 17px 24px;
  box-sizing: border-box;
  gap: 18px;
  .textNoTitle {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    flex-direction: column;
  }
  .eventsElementsWrapper {
    height: 90%;
  }
  .textLink {
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
  .nextEventsContainer {
    height: 100%;
  }
  .buttonNextEvents {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 9px;
    padding: 0px;
  }
}

.skeletonCell {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 8px;
}

.itemEventActive {
  background-color: $primary-color;
}
.itemEventLive {
  background-color: #ff0000;
}

.ContainerNextEvents {
  display: flex;
  flex-direction: row;
  height: 42px;
  gap: 7px;
  width: 100%;
  position: relative;
  padding: 2px 0px 16px 0px;
  .side {
    height: 100%;
    width: 3.93px;
    border-radius: 16px;
  }
  .ContainerTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .textTitleEvent {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      max-width: 300px;
    }
  }

  .textTime {
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: #d0d0d0;
    align-items: center;
  }
  .itemEventWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .itemLive {
    display: list-item; /* This has to be "list-item"                                               */
    list-style-type: square; /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
    list-style-position: inside;
  }
  .bullet {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    gap: 4px;
    height: 11px;
    background: #ff2c30;
    border-radius: 4px;
    color: white;
    font-size: 10px;
  }
  .bullet:before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 2px;
    display: inline-block;
    background-color: #ffffff;
    vertical-align: middle;
  }

  .itemSeparator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border: 0.5px solid #e8e8e8;
  }
}

.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
