@import '../../../styles/fira-theme.scss';

.EventBuyButtonWrapper {
  height: 100%;
  width: 100%;
  display: grid;
  grid-column: 1;
  grid-template-rows: 15px 65px 24px auto;
  gap: 40px;
  overflow-y: scroll;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 612px) {
    grid-template-rows: 15px 120px 12px auto;
    gap: 24px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light-gray-color;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }



  .SwitchContainer,
  .InputContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 612px) {
      flex-direction: column;
      align-items: center;
      height: auto;
    }
  }

  .InputContainer {
    align-items: flex-end;
  }

  .SwitchContainer {
    height: 15px;
    gap: 24px;

    label {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .InputContainer {
    height: 65px;
    gap: 16px;

    .InputField {
      width: 360px;
    }

    label {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .PrevWrapper {
    .PrevTitle {
      height: 24px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 40px;
    }

    .PrevContainer {
      height: 304px;
      width: 100%;
    }
  }
}