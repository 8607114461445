@import "../../../styles/fira-theme.scss";

.ContextChangeSelectWrapper {
  display: block;
  width: 90%;
  position: relative;
  z-index: 10;
  &__Input {
    width: 100%;
    min-width: 204px;
    height: 40px;
    background-color: #f7f8f9;
    border: 1px solid $medium-gray-color;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px 0px 12px;
    box-sizing: border-box;
    gap: 8px;
    cursor: pointer;
    .AccountTitle {
      font-size: 14px;
      font-weight: 400;
      max-width: 130px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__Icon {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      position: absolute;
      right: 8px;
    }
  }
  &__Options {
    width: 100%;
    position: absolute;
    min-height: 75px;
    background-color: #f7f8f9;
    box-sizing: border-box;
    padding: 8px 9px;
    z-index: 999;
    border-radius: 0px 0px 8px 8px;
    border: 0.5px solid $medium-gray-color;
    max-height: 288px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    overflow-y: hidden;

    .SearchInput {
      width: 100%;
      display: flex;
      height: 24px;
      flex-direction: row;
      position: relative;
      justify-content: flex-end;
      input[type="text"] {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        box-sizing: border-box;
        padding-left: 28px;
        padding-right: 10px;
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 400;
        &:focus {
          border: 0.5px solid $medium-gray-color;
          outline: none;
        }
        &::placeholder {
          color: #abafb1;
        }
      }

      .SearchIcon {
        position: absolute;
        left: 0;
      }
    }
    .AccountOwner {
      width: 100%;
      height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      padding: 0 8px;
      gap: 4px;
      cursor: pointer;
      &__Logo {
        background-color: $light-background;
        height: 32px;
        width: 32px;
        border-radius: 6px;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &__Name {
        height: 100%;
        flex-grow: 1;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__RadioButton {
        height: 100%;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .StoresList {
      width: 100%;
      max-height: 180px;
      overflow-y: scroll;
      overflow-x: hidden;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background-color: #ececec;
      }
      ul {
        list-style: none;
        width: 98%;
        height: 100%;
        cursor: pointer;
        box-sizing: border-box;
        display: grid;
        grid-auto-rows: 40px;
        grid-template-columns: 100%;
        gap: 8px;
        .ListItem {
          width: 100%;
          height: 40px;
          display: grid;
          grid-template-columns: auto 1fr auto;
          gap: 4px;
          box-sizing: border-box;
          padding: 0 8px;
          border-radius: 4px;
          span {
            // max-width: calc(100% - 40px);
            font-size: 12px;
            // box-sizing: border-box;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 24px;
          }
          &:hover {
            border: 0.5px solid $medium-gray-color;
          }
        }
      }
    }
  }
}

.LogoContainer {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: $medium-gray-color;
  overflow: hidden;
  border: 0.5px solid $medium-gray-color;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.isActiveBrand {
  background-color: $primary-color;
}

.disable {
  pointer-events: none;
  cursor: wait;
  opacity: 0.8;
}

.IsRegularUser {
  grid-template-rows: 25px 1px 1fr;
}
