.MainCarrouselEvents_mainContainer {
  width: 100%;
  scale: 0.9;
}
.MainCarrouselEvents_ContainerWidget {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-bottom: 50px;
  height: 257px;
  flex-direction: column;
  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  .MainCarrouselEvents_ImageBox {
    height: 100%;
    background-color: transparent;
    position: relative;

    @media only screen and (min-width: 600px) {
      width: 60%;
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
    .MainCarrouselEvents_ImageElement {
      width: 97%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: 25px;
      cursor: pointer;
      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3968181022) 100%
      );
    }

    .MainCarrouselEvents_boxElement {
      display: flex;
      flex-direction: column;
      color: white;

      .MainCarrouselEvents_itemEventDate {
        left: 16px;
        top: 16px;
        position: absolute;
        display: flex;
        flex-direction: column;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;

        line-height: 24px;

        .MainCarrouselEvents_textDate {
          margin-top: 1px;
        }
      }
      .MainCarrouselEvents_itemCategory {
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 29px;
        bottom: 32px;
        right: 42px;
        position: absolute;
      }
    }
    .MainCarrouselEvents_boxNotification {
      position: absolute;
      bottom: 80px;
      right: 18px;
    }
  }

  .MainCarrouselEvents_DescriptionBox {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    height: auto;
    margin-right: 20px;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
    @media only screen and (min-width: 600px) {
      width: 55%;
    }

    .MainCarrouselEvents_titleEvent {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      display: inline-block;
      align-items: center;
      color: #000000;
      margin-top: 3px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 600px;
      white-space: nowrap;
      @media only screen and (max-width: 600px) {
        font-size: 24px;
      }
      @media only screen and (min-width: 600px) {
        font-size: 40px;
      }
    }

    .MainCarrouselEvents_DescriptionText {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      color: #000000;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media only screen and (max-width: 600px) {
        font-size: 14px;
      }
      @media only screen and (min-width: 600px) {
        font-size: 16px;
      }
    }

    .MainCarrouselEvents_timerCount {
      display: flex;
      flex-direction: row;
      gap: 3px;
      @media only screen and (max-width: 600px) {
        display: none;
      }
      .MainCarrouselEvents_elementTime {
        display: flex;
        flex-direction: column;
        width: 62px;

        .MainCarrouselEvents_textItemTimeBold {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
        }

        .MainCarrouselEvents_textItemTime {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
    .MainCarrouselEvents_btnShowMore {
      width: 184px;
      height: 34px;
      color: white;
      background: #067dc7;
      border-radius: 8px;
      margin-top: 19px;
      font-family: inherit;
      padding: 3px 9px;
      white-space: nowrap;
      border-radius: 6px;
      box-sizing: border-box;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  );
}
.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 12px)
  ) !important;
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 12px)
  ) !important;
  cursor: pointer;
}
