@import "../../../styles/fira-theme.scss";

.HeaderDashboard {
  min-height: 78px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ItemText {
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
    }
    .info {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
.DateRangePicker {
  position: relative;
  .pickerButton {
    height: 30px;
    width: 167px;
    border-radius: 6px;
    background-color: $light-background;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 10px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    cursor: pointer;
    .icon {
      color: #dadada;
      font-size: 30px;
    }
    &.opened {
      border: 1px solid $primary-color;
      .icon {
        color: $primary-color;
      }
    }
    .dateText {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 5px;
    }
  }
  .calendarContainer {
    position: absolute;

    background: $light-background;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    right: 10px;
    z-index: 3;
    margin-top: 13px;
    padding: 28px 16px;
    display: flex;
    gap: 17px;
    flex-direction: column;

    .selectFilter {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }

    .rangeInputs {
      display: flex;
      flex-direction: row;
      gap: 56px;
    }
    .calendarRange {
      width: 100px;
    }
  }
}

.calendarContainer {
  position: absolute;

  background: $light-background;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  right: 10px;
  z-index: 3;
  margin-top: 13px;
  padding: 28px 16px;
  display: flex;
  gap: 17px;
  flex-direction: column;

  .selectFilter {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .rangeInputs {
    display: flex;
    flex-direction: row;
    gap: 56px;
  }
  .calendarRange {
    width: 100px;
  }
}

.dashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  scroll-behavior: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
  .countersWrapper {
    display: grid;
    gap: 7px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    &.mobileCounter {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .desktopContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: 1fr;
    gap: 16px;
    &__firstCol,
    &__secondCol {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  .mobileContainer {
    display: grid;
    max-width: 100%;
    grid-template-columns: minmax(0, 1fr);
    gap: 16px;
  }

  .tablebutton {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
    min-width: 91px;
    max-width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
