@import "../../../styles/fira-theme.scss";

.BreadCrumbNavBarWrapper {
  height: 85px;
  background-color: $light-background;
  border-radius: 10px;
  padding: 0px 98px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .LeftIconMarkUp {
    img {
      padding: 0;
      margin: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      height: auto;
    }
  }
  .RightIconMarkUp {
    img {
      padding: 0;
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
      height: auto;
    }
  }
  .BreadCrumbNavBarStepList {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    counter-reset: step;
    li {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      text-align: center;
      line-height: 17.07px;
      position: relative;
      .step {
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        z-index: 4;
      }
      .step::before {
        content: counter(step);
        counter-increment: step;
        width: 18px;
        height: 18px;
        display: block;
        margin: 10px auto 10px auto;
        border-radius: 50%;
        line-height: 18px;
        text-align: center;
        background-color: $medium-gray-color;
      }
      .step.active::before {
        background-color: $primary-color;
      }
      .step:after {
        width: 80%;
        height: 3px;
        content: "";
        position: absolute;
        background-color: $medium-gray-color;
        top: 18px;
        z-index: -1;
        left: -40%;
        border-radius: 3px;
      }
      .step.active:after {
        background-color: $primary-color;
      }
    }
    li:first-child {
      .step:after {
        content: none;
      }
    }
  }
}

// Mini steps list
.MiniStepsList {
  width: 80%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 23px;
  right: 50%;
  li {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    line-height: 17.07px;
    .MiniStep {
      width: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
      z-index: 4;
    }
    .MiniStep::before {
      content: "";
      width: 7px;
      height: 7px;
      display: block;
      margin: 10px 0 10px 0;
      border-radius: 50%;
      line-height: 18px;
      text-align: center;
      background-color: $light-background;
      border: 0.5px solid $medium-gray-color;
    }
    .MiniStep.active::before {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    .MiniStep:after {
      width: 100%;
      height: 3px;
      content: "";
      position: absolute;
      background-color: $medium-gray-color;
      top: 12.5px;
      z-index: -1;
      left: -40%;
    }
    .MiniStep.active:after {
      background-color: $primary-color;
    }
  }
  li:first-child {
    .MiniStep:after {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
  li:last-child {
    .MiniStep:after {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.ProgressBar {
  width: 80%;
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 70%;
  top: 40%;
  .ProgressBarStep {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 4;
  }
  .ProgressBarStep::before {
    width: 100%;
    height: 3px;
    content: "";
    position: absolute;
    background-color: $medium-gray-color;
    top: 12.5px;
    z-index: -1;
    left: 0;
  }
  .ProgressBarStep:after {
    content: "";
    width: 7px;
    height: 7px;
    display: block;
    margin: 10px 0 10px 0;
    border-radius: 50%;
    line-height: 18px;
    text-align: center;
    background-color: $light-background;
    border: 0.5px solid $medium-gray-color;
    position: absolute;
    right: 0;
  }
  li:first-child {
    .ProgressBarStep::before {
      width: 50%;
      left: 35%;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
  .ProgressBarStep.active:after {
    background-color: $primary-color;
    border-color: $primary-color;
  }
  .ProgressBarStep.active::before {
    background-color: $primary-color;
  }

  .LastLine {
    height: 3px;
    width: 25%;
    background-color: $medium-gray-color;
    position: absolute;
    top: 12.5px;
    right: -15%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
