@import "../../styles/fira-theme.scss";

.ContainerImageButton {
  min-width: 96px;
  min-height: 96px;
  background: $light-background;
  height: 100%;
  border: 2px dashed $dark-gray-color;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.IsDisabled {
    background-color: $main-background;
    cursor: not-allowed;
  }
  > .AddImageButton {
    min-width: 96px;
    min-height: 96px;
    background: $light-background;

    border: none;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.IsDisabled {
      background-color: $main-background;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  > .AddImageIcon {
    background-color: $primary-color;
    border-radius: 6px;
  }
  .LabelAddImageButton {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 85px;
    color: #abafb1;
  }
}
