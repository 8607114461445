@import "../../styles/fira-theme.scss";

.Select {
  display: flex;
  position: relative;
  select {
    -webkit-appearance: none;
    border: 1px solid $medium-gray-color;
    background-color: $light-background;
    text-overflow: ellipsis;
    cursor: pointer;
    padding-left: 6px;
    border-radius: 6px;
    width: 100%;
    &:focus {
      border: 1px solid $primary-color;
      outline: none;
    }
    &:disabled {
      border-color: $medium-gray-color;
      background-color: $main-background;
      color: black;
    }
    select option:hover {
      background-color: $dark-gray-color;
    }
  }
  .ArrowIcon {
    position: absolute;
    font-size: 20px;
    left: 270px;
    cursor: pointer;
    pointer-events: none;
    right: 5px;
    left: auto;
    top: 5px;
  }
}
