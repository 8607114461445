.Wrapper {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    :first-child {
      font-size: 20px;
      font-weight: 700;
    }
    :last-child {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .RadioWrapper {
    display: flex;
    justify-content: center;
    gap: 24px;
    font-size: 12px;
    font-weight: 400;
    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .CustomWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    > .Preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      > :first-child {
        font-size: 20px;
        font-weight: 600;
      }
      > :last-child {
        display: flex;
        gap: 24px;
      }
    }
  }
}

.MockupWrapper {
  border: 1px solid #d5dde0;
  border-radius: 8px;
  padding: 16px;
  width: 248px;
  height: 300px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  .IconMock {
    align-self: flex-end;
  }
  .Img {
    background-color: #f7f8f9;
    width: 132px;
    height: 132px;
    border-radius: 4px;
  }
  .Indicators {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 4px;
    .Dot {
      padding: 2px;
      background-color: #d9d9d9;
      border-radius: 50%;
    }
  }
  .TextSkeleton {
    background-color: #f7f8f9;
    height: 12px;
    min-width: 20px;
    border-radius: 4px;
  }
  .PreviewText {
    font-size: 14px !important;
    font-weight: 600;
  }
}
