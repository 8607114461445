@import "../../styles/fira-theme.scss";

.PermissionsViewWrapper {
  max-height: 100vh;
  width: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $light-gray-color;
    border-radius: 4px;
  }
  &::-moz-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
