@import '../../../../styles/fira-theme.scss';

.UserDataPopUpWrapper {
  width: 90%;
  min-height: 140px;
  height: auto;
  background-color: $light-background;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  .ActionsContainer {
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 7px 5px 2px;

    .UserActions {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .MessageContainer {
    background-color: #F7F8F9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 8px;
    height: auto;
    width: 100%;
    gap: 8px;
    .User {
      padding-bottom: 4px;
      display: flex;
      .name {
        width: 80%;
        font-size: 12px;
        font-weight: 600;
      }
      .time {
        font-size: 10px;
      }
    }

    .Message {
      width: 100%;
      font-size: 10px;
      font-weight: 400;
    }
  }

  .Divider {
    width: 100%;
    height: 1px;
    background-color: #D9D9D9;
    margin: 8px 0;
  }

  .InfoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    .Title {
      font-size: 12px;
      font-weight: 600;

    }
    .Item {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      gap: 16px;
      align-items: center;
      height: 16px;
      .value {
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .copy {
        font-size: 10px;
        font-weight: 600;
        color: $success-color;
        width: 30%;
      }

      svg {
        cursor: pointer;
      }
    }
  }

  .Closed {
    max-height: 0;
    transition: max-height ease-in-out 0.5s -0.3s;
    padding: 0 8px;
  }
  .Collapsed {
    padding: 8px;
    max-height: 210px;
    transition: max-height ease-in-out 0.5s;
  }
}

.NoData {
  width: 100%;
  height: 30px;
  font-size: 14px;
  color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
To do: move this to utils*/
.loaderContainer {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: block;
  font-size: 5px;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
  margin-right: 15px;
  margin-left: 5px;
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4b4c4c,
    1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
    2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
    1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
    0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
    -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
    -2.6em 0em 0 0em rgba(75, 76, 76, 0.5),
    -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.7),
    1.8em -1.8em 0 0em #4b4c4c, 2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
    1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
    0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
    -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
    -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
    -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.5),
    1.8em -1.8em 0 0em rgba(75, 76, 76, 0.7), 2.5em 0em 0 0em #4b4c4c,
    1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
    0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
    -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
    -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
    -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
    1.8em -1.8em 0 0em rgba(75, 76, 76, 0.5),
    2.5em 0em 0 0em rgba(75, 76, 76, 0.7), 1.75em 1.75em 0 0em #4b4c4c,
    0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
    -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
    -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
    -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
    1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
    2.5em 0em 0 0em rgba(75, 76, 76, 0.5),
    1.75em 1.75em 0 0em rgba(75, 76, 76, 0.7), 0em 2.5em 0 0em #4b4c4c,
    -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
    -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
    -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
    1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
    2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
    1.75em 1.75em 0 0em rgba(75, 76, 76, 0.5),
    0em 2.5em 0 0em rgba(75, 76, 76, 0.7), -1.8em 1.8em 0 0em #4b4c4c,
    -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
    -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
    1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
    2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
    1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
    0em 2.5em 0 0em rgba(75, 76, 76, 0.5),
    -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.7), -2.6em 0em 0 0em #4b4c4c,
    -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
    1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
    2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
    1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
    0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
    -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.5),
    -2.6em 0em 0 0em rgba(75, 76, 76, 0.7), -1.8em -1.8em 0 0em #4b4c4c;
  }
}
