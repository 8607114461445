@import "../../../styles/fira-theme.scss";

.FiraOperatorRole {
  width: 100%;
  min-height: 276px;
  background-color: $light-background;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .Title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .Desc {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  .Operators {
    display: flex;
    flex-direction: row;
    gap: 40px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }

  .Buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-top: 24px;
    .Inner {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
