@import "../../styles/fira-theme.scss";

.CustomFullCalendarWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.Weekformat {
  display: flex;
  flex-direction: column;
  .DayText {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }
  .DayNumber {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

.MonthFormat {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.TimeSlot {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}

.dayHeaderFormat {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FiraCalendarMonthEventWrapper {
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: 10px 1fr;
  gap: 5px;
  padding-left: 5px;
  width: 100%;
  .firaCalendarCircle {
    background-color: $primary-color;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    &.isFinished {
      background-color: $dark-gray-color;
    }
    &.eventTest {
      background-color: $light-background;
      border: 1px solid $primary-color;
    }
  }
  .text {
    color: #000;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}

.FiraOwnerCalendarEvent {
  height: 100%;
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000;

  .StoreLogo {
    width: 26px;
    aspect-ratio: 2 / 2;
    height: 26px;
    border-radius: 40px;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    background-color: #beef00;
    align-items: center;
  }
  .ElementGroup {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 10px;
      font-weight: 500;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    .eventState {
      display: flex;
      flex-direction: row;
      margin-top: 8px;
      margin-bottom: 4px;
      .state {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        word-break: break-all;
      }
      .icon {
        color: #fff;
        font-size: 15px;
      }
    }
    .time {
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }
  &.NormalEvent {
    background-color: $primary-color;
    border: 1px solid #fff;
    &.isFinished {
      background-color: $dark-gray-color;
      color: #fff;
    }
  }

  &.EventTest {
    border: 1px solid $primary-color;
    background-color: #fff;
    color: #000000;
    &.isFinished {
      border: 1px solid $dark-gray-color;
    }
    .ElementGroup {
      .eventState {
        .icon {
          color: #000000;
          font-size: 15px;
        }
      }
    }
  }
  &.SMALL {
    min-height: 11.06px;
    display: flex;
    padding: 0px 4px;
    gap: 6px;
    border-radius: 8px;
    justify-content: center;
    .StoreLogo {
      width: 8px;
      aspect-ratio: 2 / 2;
      height: 8px;
    }
    .ElementGroup {
      .title {
        font-size: 8px;
        font-weight: 500;
      }
    }
  }
  &.MEDIUM {
    min-height: 45px;
    gap: 5px;
    .ElementGroup {
      .title {
        display: -webkit-box;
        overflow: hidden;
      }
      .time {
        font-size: 10px;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
  &.BIG {
    flex-direction: column;
    height: 100%;
    min-height: 92px;
    justify-content: center;
    align-items: center;

    .ElementGroup {
      align-items: center;
      .title {
        text-align: center;
        margin-top: 4px;
      }
    }
  }
}

.CustomMoreEvents {
  min-width: 45px;
  max-width: 58px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  box-sizing: border-box;
  min-height: 11.06px;
  border-radius: 8px;
  border: 1px solid #000000;
  background-color: #f7f8f9;
  .MoreEventsText {
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
  }
}
