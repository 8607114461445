@import "../../../styles/fira-theme.scss";

.EventGeneralDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0px 40px 0 40px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .TitleContainer {
    padding-top: 24px;
    text-align: center;
  }
  .EventGeneralDetailsMessage {
    margin-top: 16px;
    line-height: 20px;
    padding-bottom: 14px;
  }
  .GeneralDetailsContainer {
    display: grid;
    grid-template-columns: 465px auto;
    grid-template-rows: 1fr;
    grid-column-gap: 42px;
    grid-row-gap: 0px;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 40px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    justify-content: center;
    .ContainerGridItem {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      .SquaredGridItem {
        width: 340px;
        height: 212px;
        background-color: $main-background;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .Icon {
          cursor: pointer;
          width: 46.67px;
          height: 46.67px;
          background-color: rgba(0, 0, 0, 0.4);
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 34px;
          svg {
            font-size: 24px;
          }
        }
        .Upload {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .load {
            font-weight: 500;
          }
        }
      }
      .SquaredGridDisplay {
        width: 340px;
        height: 212px;
        border-radius: 8px;
        position: absolute;
        top: 0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img,
        video {
          object-fit: cover;

          cursor: pointer;
        }
        img {
          width: 100%;
        }
        video {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .Delete {
          z-index: 9999;
          position: absolute;
          top: 9px;
          right: 8px;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          border: 1px solid $danger-color;
          border-radius: 4px;
          cursor: pointer;
          svg {
            color: $danger-color;
          }
        }
      }
    }
  }
  .GeneralDetailsContainer::-webkit-scrollbar {
    display: none;
  }
}

.FormGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  .DateInputsContainer {
    display: flex;
    flex-direction: row;
    gap: 29.5px;
  }
  label {
    font-weight: 500;
  }
}

.LightText {
  color: #8b8d97;
  font-weight: 400;
}

.CategoriesContainer {
  max-height: 130px;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.CategoriesContainer::-webkit-scrollbar {
  display: none;
}

.TextEditorInputWrapper {
  height: 113px;
}

.CategoryTag {
  cursor: pointer;
  padding: 0 6px;
  min-width: 76px;
  height: 24px;
  background-color: #f7f8f9;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: inline-block;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.SelectedCategory {
  background-color: $primary-color;
}

//Video Controls
#videoControls {
  display: flex;
  gap: 8px;
  height: 21px;
  width: 100%;
  z-index: 20;
  position: absolute;
  bottom: 8px;
  padding: 0 8px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .video-control {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 12px;
      path {
        opacity: 1;
      }
    }
  }
  .progress-bar {
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.4);
    height: 21px;
    border-radius: 6px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

//Video Controls

.rc-slider-track {
  background-color: #ff0005 !important;
}
