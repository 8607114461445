@import "../../styles/fira-theme.scss";

.MessageWrapperSuccess {
  width: 523px;
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 30px 0px;

  .ImagePreview {
    margin-top: 30px;
  }

  .Text-success {
    text-align: center;
    margin-top: 14px;
    margin-left: 35px;
    margin-right: 35px;
  }
  .Text-detail {
    width: 332px;
    text-align: center;
    margin-top: 14px;
    margin-left: 35px;
    margin-right: 35px;

    word-break: break-word;

  }


}
