.LandingWidgetsWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  padding-right: 8px;

  .LandingModule {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__Title {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__Radio {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        font-size: 10px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #000;
        span {
          color: #ABAFB1;
        }
        span.selected {
         color: #000
        }
      }
    }
  }
}

.FauxWidgetInput {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #d5dde0;
  pointer-events: none;
  opacity: 0.5;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 8px;
}

.BouncingLoaderContainer {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}