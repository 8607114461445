@import "../../styles/fira-theme.scss";

.TableLink {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
}

.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
}

.TableEmptyState {
  width: 100%;
  height: 100%;
  background-color: $light-background;
  display: flex;
  justify-content: center;
  align-items: center;
}
