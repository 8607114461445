@import "../../styles/fira-theme.scss";

.ContainerCard {
  height: 90px;
  width: 252px;
  left: 80.28%;
  right: 2.22%;
  top: 33%;
  bottom: 57%;
  display: flex;
  flex-direction: column;
  /* Blanco */
  background: $light-background;
  /* Borde */
  border: 2px solid #d5dde0;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  &.disabledCard {
    background-color: #b3b3b3;
    opacity: 1;
  }

  > .ContainerRow {
    display: flex;
    flex-direction: row;

    > .ContainerText {
      display: flex;
      > .ItemTextTitle {
        flex-grow: 1;
        justify-content: flex-start;
        padding-left: 12px;
        padding-right: 10px;
        padding-top: 7px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        /* or 157% */
        /* Negro */
        color: #010202;
      }
      > .ItemTextContent {
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        flex-grow: 2;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 22px;
        padding-top: 8px;
        /* Negro */
        color: #010202;
      }
    }

    > .ContainerStatus {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;

      > .ItemEllipse {
        height: 10px;
        width: 10px;
        margin-top: 7px;
        margin-right: 10px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;

        justify-content: flex-end;

        &.available {
          background: $success-color;
          opacity: 1;
        }
        &.inUse {
          background: $primary-color;
          opacity: 1;
        }
        &.error {
          background: $danger-color;
          opacity: 1;
        }
      }
    }
  }

  > .ContainerSecondaryText {
    display: flex;
    justify-content: flex-start;
    > .ItemTextTitle {
      justify-content: flex-start;
      padding-left: 12px;
      padding-right: 10px;
      padding-top: 4px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      /* or 157% */
      /* Negro */
      color: $main-text;
    }
    > .ItemTextContent {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 22px;
      padding-top: 5px;
      /* Negro */
      color: #010202;
    }
  }

  > .ItemButton {
    height: 26.29px;
    width: 100%;
    align-self: flex-end;
    top: 39.87%;
    bottom: 57.25%;
    background: $primary-color;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 9px 9px;
    border: none;
    margin-top: 9px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $main-text;

    &:disabled {
      background-color: #929292;
      opacity: 1;
    }
  }
} /*end card*/
