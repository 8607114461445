.Wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "";
  gap: 40px;
  padding: 40px 0px;
  .Header {
    grid-column-start: 1;
    grid-column-end: last-line;
    grid-row-start: 1;
    grid-row-end: span last-line;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    :first-child {
      font-size: 20px;
      font-weight: 700;
    }
    :last-child {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .Picker {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .Preview {
    width: 305px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    span {
      font-size: 16px;
      font-weight: 700;
    }
    .Divider {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;
    }
  }
}
