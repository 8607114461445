@import "../../styles/fira-theme.scss";
.moderationContainer {
  .moderationCounters {
    display: flex;
    flex-direction: row;
    min-width: 937px;
    gap: 8px;
    margin-bottom: 18px;
  }

  .productsContainer {
    height: 367px;
    border-radius: 10px;
    background-color: $light-background;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;

    .ellipsis {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-break: break-word;
    }
    .pricesEllipsis {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .productsHeader {
      height: 30px;
      display: flex;
      margin-top: 14px;
      gap: 14px;
      > .title {
        display: flex;
        align-items: center;
        .infoIcon {
          margin-left: 5px;
        }
      }
      .SearchIcon {
        font-size: 30px;
      }
    }
    .itemsContainer {
      height: 276px;
      overflow-x: auto;
      padding-top: 8px;
      margin-top: 10px;
      padding-right: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: min-content;
      justify-items: center;
      grid-row-gap: 16px;
      grid-column-gap: 26px;

      > .addProductContainer {
        min-width: 194px;
        width: 100%;
        height: 70px;
        border: 1px dashed #d5dde0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        > .contentGroup {
          display: flex;
          flex-direction: column;
          width: min-content;
          align-items: center;
          > .iconWrapper {
            width: 28px;
            height: 28px;
            background-color: $main-background;
            border-radius: 6px;
            > .addIcon {
              font-size: 28px;
            }
          }
        }
        &.disabled {
          color: grey;
          background: #dedede;
          cursor: auto;
          .contentGroup {
            .iconWrapper {
              background-color: $dark-gray-color;
            }
          }
        }
      }
      > .cardWrapper {
        position: relative;
        height: 70px;
        justify-self: center;
        width: 100%;
        > .closeIcon {
          width: 19px;
          height: 19px;
          position: absolute;
          top: -8px;
          right: -14px;
          font-size: 19px;
          cursor: pointer;
          > .icon {
            color: black;
          }
        }
        > .highlighted {
          position: absolute;
          background-color: #00000030;
          width: 100%;
          border-radius: 6px;
          height: 72px;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: white;
        }
        > .productsCard {
          width: 100%;
          height: 70px;
          background-color: #ffffff;
          border: solid 1px #d5dde0;
          border-radius: 6px;
          display: flex;
          gap: 12px;
          padding-right: 7px;
          scroll-behavior: auto;
          cursor: pointer;
          z-index: 1;
          &.Blur {
            -webkit-filter: blur(5px);
            filter: blur(1px);
          }

          &.selected {
            border-color: $primary-color;
          }

          > .imgContainer {
            height: 70px;
            width: 70px;
            max-height: 70px;
            min-width: 70px;
            background-color: #d0d0d0;
            background-position: center;
            background-size: cover;
            border-radius: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            > img {
              height: 100%;
              object-fit: cover;
              object-position: center center;
            }
          }

          > .textContainer {
            margin-top: 3px;
            align-self: center;
            .title {
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0em;
              margin-bottom: 7px;
              word-break: break-word;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
            .showButton {
              .inner {
                font-size: 10px;
                font-weight: 600;
                display: flex;
                align-items: center;
                .windowIcon {
                  margin-right: 2px;
                }
              }
            }
            .pricesWrapper {
              display: flex;

              .pricesGroup {
                font-size: 14px;
                font-weight: 300;
                line-height: 17.7px;
                letter-spacing: 0em;
                text-align: left;
                max-height: 40px;
                > .boldText {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 19.5px;
                }
                > .lineText {
                  text-decoration: line-through;
                }
              }
            }
          }
        }
      }
    }
  }
}
