.ActivateForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .InfoText {
    text-align: center;
    margin-bottom: 28px;
  }
  .ResendText {
    text-align: center;
    font-size: 10px;
    line-height: 14px;
    margin-top: 17px;
    margin-bottom: 49px;
    .ResendLink {
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
