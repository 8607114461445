@import "../../../styles/fira-theme.scss";

.CollectionSelectWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  &__Title {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
  }
}

.CollectionSelect {
  display: flex;
  flex-direction: column;
  width: 100%;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  &__Input {
    width: 100%;
    height: 40px;
    background-color: $light-background;
    border: 1px solid $medium-gray-color;
    border-radius: 8px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    .CollectionName {
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }
    .Icon {
      position: absolute;
      right: 14px;
      width: 20px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
    }
  }

  &__Options {
    width: 100%;
    background-color: #f7f8f9;
    border: 1px solid $medium-gray-color;
    border-radius: 0 0 8px 8px;
    box-sizing: border-box;
    max-height: 221px;
    overflow-y: scroll;
    position: absolute;
    top: 40px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    z-index: 9999;
    li {
      list-style: none;
      height: 45px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 16px;
      font-size: 12px;
    }
    li:hover {
      background-color: $medium-gray-color;
    }
  }
  &__Options::-webkit-scrollbar {
    display: none;
  }
}

.isOpen {
  border-radius: 8px 8px 0px 0px;
  border-bottom: 0.5px;
}

.openShadow {
  -webkit-box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.1));
  -moz-box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.1));
  box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.1));
}

.loaderContainer {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: block;
  font-size: 5px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
  margin-right: 15px;
  margin-left: 5px;
}

.SelectedOptionsList {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  &__Item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 12px 0 8px;
    border-radius: 8px;
    border: 1px solid $medium-gray-color;
    height: 40px;
    align-items: center;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    &__Icon {
      cursor: pointer;
      font-size: 12px;
    }
  }
}

.disabled {
  pointer-events: none;
  background-color: #f7f8f9;
  color: #abafb1;
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4b4c4c,
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.5),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.7),
      1.8em -1.8em 0 0em #4b4c4c, 2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.5),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.7), 2.5em 0em 0 0em #4b4c4c,
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.5),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.7), 1.75em 1.75em 0 0em #4b4c4c,
      0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.5),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.7), 0em 2.5em 0 0em #4b4c4c,
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.5),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.7), -1.8em 1.8em 0 0em #4b4c4c,
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.5),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.7), -2.6em 0em 0 0em #4b4c4c,
      -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
      1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
      2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
      1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
      0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
      -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.5),
      -2.6em 0em 0 0em rgba(75, 76, 76, 0.7), -1.8em -1.8em 0 0em #4b4c4c;
  }
}
