@import "../../../styles/fira-theme.scss";

.LoginParamsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  .TitleContainer {
    text-align: center;
    margin-top: 24px;
    .LoginParamMessage {
      margin-top: 16px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
  .container-box {
    width: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    height: 100%;
    margin-bottom: 30px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $light-gray-color;
      border-radius: 4px;
    }
    &::-moz-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .LoginParamsContainer {
      display: flex;
      flex-direction: column;
      width: 100%;

      .SwitchContainer {
        width: 97%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 22px;
        .LoginEnabled {
          line-height: 24px;
        }
      }
      .FieldsContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-height: 320px;

        .Fields {
          margin-bottom: 8px;
        }
        .Field {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 18px;
          gap: 17px;
          button {
            &:disabled {
              background-color: #f7f8f9;
              border: 1px solid #d5dde0;
            }
          }
          button.SelectIcon {
            font-size: 30px;
            &:disabled {
              background-color: #f7f8f9;
              border: 1px solid #d5dde0;
            }
          }
          .Delete {
            border-color: $danger-color;
          }
        }
        .UserField {
          pointer-events: none;
        }

        .AddNew {
          display: block;

          button:disabled {
            background-color: #f7f8f9;
            border: 1px solid #d5dde0;
          }
        }
      }
      .ContainerElements {
        display: flex;
        height: 100%;
        margin-bottom: 36px;
        flex-direction: column;
        justify-content: end;
        .URLContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-self: flex-end;
          margin-top: 18px;

          .TitleContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.InactiveButton {
  opacity: 0.5;
  pointer-events: none;
}

.NonDeletable {
  pointer-events: none;
}

.DisabledForm {
  pointer-events: none;
  opacity: 0.5;
}

.LabelIcon {
  cursor: pointer;
  svg {
    padding-bottom: 2px;
    padding-left: 12px;
  }
}

.URLNonVisible {
  visibility: hidden;
}
.LoginSelect {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  select {
    -webkit-appearance: none;
    width: 100%;
    height: 48px;
    padding-left: 6px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $medium-gray-color;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    margin-left: 17px;
    background-color: #fff;
    cursor: pointer;

    &:disabled {
      background-color: #f7f8f9;
      border: 1px solid #d5dde0;
    }
  }

  .ArrowIcon {
    position: absolute;
    font-size: 20px;
    right: 14px;
    top: 15px;
    cursor: pointer;
    pointer-events: none;
  }
}
