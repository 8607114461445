@import "../../../styles/fira-theme.scss";

.FormSelect {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: block;
  .Input {
    padding: 0 8px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    &.default {
      border: 1px solid $medium-gray-color;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 400;
    }
    &.invalid {
      border: 1px solid $danger-color;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }
    span {
      width: 90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.placeholder {
        font-size: 14px;
        color: $dark-gray-color;
      }
    }
    &:focus {
      border: 1px solid $primary-color;
      outline: none;
    }

    .DropIcon {
      position: absolute;
      right: 16px;
      font-size: 8px;
    }
    &.isOptionsOpen {
      border-radius: 4px 4px 0 0;
      border-bottom: none;
    }
  }

  .FormSelectOptions {
    position: absolute;
    padding: 8px;
    box-sizing: border-box;
    top: 100%;
    left: 0;
    background-color: #f7f8f9;
    max-height: 200px;
    overflow-y: scroll;
    z-index: 9999;
    width: 100%;
    border: 1px solid $medium-gray-color;
    border-radius: 0 0 4px 4px;
    div {
      padding: 10px 5px;
      cursor: pointer;
      box-sizing: border-box;
      font-size: 12px;
      background-color: #f7f8f9;
    }
    div:hover {
      background-color: #efefef;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
  }
}
