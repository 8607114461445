.FiraUserPermissionWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 260px;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px;
  cursor: pointer;

  > .CheckboxWrapper {
    z-index: 3;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  > .TitleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
    > .IconWrapper {
      height: 32px;
      font-size: 32px;
      color: #000000;
    }
    > .TextWrapper {
      font-weight: 700;
      font-size: 16px;
      line-height: 38px;
      color: #000000;
    }
  }

  > .DescriptionWrapper {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  > .PermissionButtonWrapper {
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 3;
    > button {
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      text-decoration-line: underline;
      color: #000000;
      background: none;
      border: none;
      &.IsActive {
        font-weight: 600;
      }
    }
  }

  > .ComponentBorder {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #d5dde0;
    border-radius: 8px;
    transition: 0.2s ease-in;
    &:hover {
      border: 1px solid #000000;
    }
    &.Selected {
      border: 3px solid #ffde07;
    }
  }
}

.FiraSimplePermissionListPropsWrapper {
  padding-top: 15px;
  > .TitleWrapper {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  > .PermissionListWrapper {
    padding-top: 15px;
    padding-left: 10px;
    list-style-position: inside;
    list-style-type: "· ";
    > li {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }
  }
}

.DisabledModule {
  opacity: 0.6;
  pointer-events: none;
  background-color: #f7f8f9;
}

.FiraEditableList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  margin-top: 16px;
  > .EditableItem {
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-size: 12px;
    font-weight: 400;
    align-items: center;
    cursor: pointer;
    z-index: 8;
  }
}
