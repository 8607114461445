@import "../../styles/fira-theme.scss";

.FiraStepperWrapper {
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .LeftIconMarkUp,
  .RighIconMarkUp {
    img {
      padding: 0;
      margin: 0;
      position: absolute;
      height: auto;
    }
  }

  .LeftIconMarkUp {
    img {
      left: 0;
      bottom: 0;
    }
  }

  .RighIconMarkUp {
    img {
      right: 0;
      top: 0;
    }
  }
}

.FiraStepperListTitles {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 80%;
  li {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 8px;
    text-align: center;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
}

.FiraStepperList {
  width: 80%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  counter-reset: step;
  li {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    line-height: 17.07px;
    position: relative;
    .step {
      width: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
      z-index: 4;
      box-sizing: border-box;
    }
    .step::before {
      content: counter(step);
      counter-increment: step;
      width: 18px;
      height: 18px;
      display: block;
      margin: 10px auto 10px auto;
      border-radius: 50%;
      line-height: 18px;
      text-align: center;
      background-color: $medium-gray-color;
    }
    .step.active::before {
      background-color: $primary-color;
    }
    .step::after {
      width: 80%;
      height: 3px;
      content: "";
      position: absolute;
      background-color: $medium-gray-color;
      top: 18px;
      left: -40%;
      border-radius: 3px;
    }
    .step.active:after {
      background-color: $primary-color;
    }
    .step.hasMiniSteps:after {
      display: none;
      @media screen and (max-width: 576px) {
        display: block;
      }
    }
  }
  li:first-child {
    .step:after {
      content: none;
    }
  }
}

.ProgressBarSimple {
  width: 80%;
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 60%;
  top: 48%;
  background-color: $medium-gray-color;
  height: 3px;
  border-radius: 10px;
}


.ProgressBar {
  width: 80%;
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 70%;
  top: 17%;
  .ProgressBarStep {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 4;
  }
  .ProgressBarStep::before {
    width: 100%;
    height: 3px;
    content: "";
    position: absolute;
    background-color: $medium-gray-color;
    top: 12.5px;
    z-index: -1;
    left: 0;
  }
  .ProgressBarStep:after {
    content: "";
    width: 7px;
    height: 7px;
    display: block;
    margin: 10px 0 10px 0;
    border-radius: 50%;
    line-height: 18px;
    text-align: center;
    background-color: $light-background;
    border: 0.5px solid $medium-gray-color;
    position: absolute;
    right: 0;
  }
  li:first-child {
    .ProgressBarStep::before {
      width: 50%;
      left: 35%;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
  .ProgressBarStep.active:after {
    background-color: $primary-color;
    border-color: $primary-color;
  }
  .ProgressBarStep.active::before {
    background-color: $primary-color;
  }

  .LastLine {
    height: 3px;
    width: 25%;
    background-color: $medium-gray-color;
    position: absolute;
    top: 12.5px;
    right: -15%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
}
