//Carousel Widget
.CarouselWidgetWrapper {
  height: 370px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: 1fr 3fr;
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  position: relative;
  // scale: 0.8;
  &__Top {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__Title {
      font-size: 40px;
      margin-bottom: 24px;
      font-weight: 700;
      max-width: 420px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__Underline {
      background-color: #067dc7; //TEMP
      border-radius: 8px;
      height: 8px;
      width: 100%;
    }
  }
  &__PrevButton {
    grid-area: 2 / 1 / 3 / 2;
  }
  &__Swiper {
    grid-area: 2 / 2 / 3 / 3;
    overflow: hidden;
    &__Container {
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin-bottom: 40px;
      .swiper {
        height: 100%;
        position: relative;
      }
    }
  }
  &__NextButton {
    grid-area: 2 / 3 / 3 / 4;
  }

  &__PrevButton,
  &__NextButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      position: absolute;
      margin-top: -65px;
      width: 50px;
      height: 50px;
    }
  }
}

//Carousel Items
.CarouselItemWrapper {
  height: 200px;
  background-color: lightgray; //TEMP
  border-radius: 8px;
  position: relative;
  color: white; //TEMP
  cursor: pointer;
  &__Overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: rgb(0, 0, 0);
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(26, 22, 22, 0) 30%
      ),
      linear-gradient(324deg, rgba(17, 17, 17, 0.2) 100%, rgba(8, 8, 8, 1) 100%),
      linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(26, 22, 22, 0) 30%);
    opacity: 0.7;
    z-index: 2;
  }
  &__Top {
    z-index: 3;
    position: absolute;
    top: 10px;
    left: 19px;
    &__Date {
      text-transform: capitalize;
    }
  }
  &__Center {
    bottom: 0;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }
  &__Bottom {
    display: flex;
    z-index: 3;
    position: absolute;
    bottom: 25px;
    right: 14px;
    &__Category {
      font-size: 28px;
    }
    &__Title {
      word-break: break-all;
      text-align: right;
      font-size: 32px;
      font-weight: 700;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 220px;
    }
  }
}

.fira-carrousel-right-navigation-container {
  position: absolute;
  right: 0;
  z-index: 99;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: 50%;
  cursor: pointer;
  .fira-carrousel-navigation-button {
    font-size: 3em;
    display: flex;
  }
}

.fira-carrousel-left-navigation-container {
  position: absolute;
  left: 0;
  z-index: 99;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  top: 50%;
  cursor: pointer;
  .fira-carrousel-navigation-button {
    font-size: 3em;
    display: flex;
  }
}

@media (max-width: 790px) {
  .CarouselWidgetWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    &__Top {
      width: 100%;
      text-align: left;
      padding-left: 24px;
      padding-top: 24px;
      &__Title {
        font-size: 24px;
      }
    }

    &__Swiper {
      overflow: hidden;
      width: 200%;
      height: 210px;
    }
  }
  .CarouselItemWrapper {
    height: 165px;
    max-width: 295px;
    &__Overlay {
      svg {
        height: 32px;
        width: 32px;
        font-size: 32px;
      }
    }
    &__Top {
      top: 8px;
      left: 8px;
      &__Time {
        font-size: 16px;
        font-weight: 700 !important;
      }
    }
    &__Bottom {
      bottom: 10px;
      right: 8px;
      &__Category {
        font-size: 14px;
      }
      &__Title {
        font-size: 16px;
      }
    }
  }

  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 8px)
    );
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 8px)
    );
  }

  .swiper-pagination-bullet-active {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 12px)
    ) !important;
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 12px)
    ) !important;
    position: relative;
    top: 2px;
    border: none;
  }
}

.FiraSwiper {
  height: 95% !important;
}

.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 12px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 12px)
  );
}
