@import '../../../styles/fira-theme.scss';

.LandingCanvasWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 32px 32px 32px;
  overflow-x: hidden;
  background-color: #F7F8F9;
  .iframeWrapperMobile {
    width: 414px;
    height: 100%;

  }
  .iframeWrapperDesktop {
    width: 100%;
    height: 100%;
  }

  .iframeWrapperMobile, .iframeWrapperDesktop {
    display: flex;
    justify-content: center;
    align-items: center;
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}