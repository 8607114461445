@import "../../styles/fira-theme.scss";

.EditProductsWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .centerInput {
    display: flex;
    justify-content: center;
  }
  .headerOption {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inactiveText {
    color: #abafb1;
  }
  .ellipsis {
    max-width: 200px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  .originStore {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .inside {
      width: 73px;
      height: 21px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      color: #ffff;
      &.fira {
        background-color: #d5dde0;
      }
      &.other {
        background-color: #067dc7;
      }
    }
  }
}

.EmptyWrapper {
  width: 100%;
  height: stretch;
  background-color: $light-background;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $dark-gray-color;
  height: 100%;
  * {
    font-size: 400px;
  }
}
.rowOption {
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.popUpOption {
  z-index: 99;
  border-radius: 6px;
  width: max-content;
  background: #ffffff;
  padding: 8px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 10px 20px 0px #0000001a;
  .option {
    display: flex;
    align-items: center;
    gap: 11px;
    height: 41px;
    cursor: pointer;
    .icon {
      font-size: 27px;
    }
    .text {
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
    }
    &.hide {
      cursor: default;
      color: #d5dde0;
    }
  }
  .optionLine {
    border: 1px solid #d5dde0;
    width: 132px;
    align-self: center;
  }
}
