@import "../../styles/fira-theme.scss";
.CreateEventWrapper {
  width: 351px;
  background: #ffffff;
  /* Opacidad */
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  box-shadow: 0px 25px 35px rgba(10, 8, 36, 0.1);
  border-radius: 10px;

  .row {
    display: flex;
    flex-direction: row;

    &.space {
      justify-content: space-evenly;
      width: 100%;
    }

    &.center {
      align-items: center;
    }
    .mr {
      margin-right: 8px;
    }
  }

  .header {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    position: relative;
    justify-content: center;
    .icon {
      font-size: 28px;
      margin-right: 57px;
      cursor: pointer;
      //float: left;
      position: absolute;
      left: 36px;
    }
  }

  .formCreateEventContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    overflow: hidden;
    .scroll-view {
      height: 100%;
      overflow: scroll;
    }

    .formGroupTop {
      margin-top: 2px;
      position: relative;

      &.mr {
        margin-right: 9px;
      }
    }

    .formGroup {
      margin-top: 15px;
      position: relative;

      &.mr {
        margin-right: 9px;
      }
    }

    .buttonGroup {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      width: 275px;
      justify-content: center;
    }
    .text-button {
      cursor: pointer;
    }
    .imageContainer {
      margin-bottom: 14px;
      cursor: pointer;
    }
  }
}

.CreateEventWrapperTop {
  width: 424px;
  background: #ffffff;
  /* Opacidad */
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  box-shadow: 0px 25px 35px rgba(10, 8, 36, 0.1);
  border-radius: 10px;

  .ContainerImage {
    display: flex;
    justify-content: flex-end;

    .image-close {
      width: auto;
      margin-top: 8px;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .ContainerTitle {
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    .title-field {
      font-weight: 400;
      margin-left: 16px;
    }
    .image-close-modal {
      width: auto;
      margin-right: 23px;
      cursor: pointer;
    }
  }
  .Container-input-top {
    height: 60px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 10px;
  }
  .Container-input-central {
    height: 70px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 16px;
    padding-top: 10px;

    .row-container-items {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .colum-item {
        display: flex;
        flex-direction: column;

        .text-custom {
          display: flex;
          align-items: center;
        }
        .mb {
          display: flex;
          align-items: center;
        }
      }
      .space-left {
        margin-left: 5px;
      }
      .space-right {
        margin-right: 5px;
      }
    }
  }
  .Container-button {
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    .text-button {
      cursor: pointer;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
    .event-test-text {
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      &.test-disabled{
        cursor: default;
      }
    }
  }
}

.title-field {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.row:first-child {
  margin: 0;
}

.arrow {
  width: 7px;
  height: 7px;
}

.arrow:before,
.arrow:after {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  pointer-events: none;
}

.arrow:before {
  border: 7px solid transparent;
}

.arrow:after {
  border: 6px solid transparent;
  left: 1px;
}

.arrow.top {
  top: -7px;
}

.arrow.top:before {
  left: -7px;
  border-bottom-color: black;
}

.arrow.top:after {
  left: -6px;
  top: 1px;
  border-bottom-color: #d03a3a;
}
