.fira-filter-container {
  display: flex;
  flex-direction: column;
  gap: 17px;
  font-size: 16px;
  width: 25%;
  .fira-filter-header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .fira-filter-header-icon {
      margin-right: 16px;
      cursor: pointer;
    }
  }
  .fira-filter-group-header {
    font-weight: 700;
  }
  .fira-filter-group {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    .fira-filter-element {
      display: flex;
      gap: 7.56px;
      .fira-filter-text {
        margin-block-start: 0px;
        margin-block-end: 0px;
        padding-bottom: 2px;
        display: flex;
        align-items: self-end;
        min-width: 71px;
      }
      .fira-filter-count {
        color: #989898;
        margin-left: auto;
        display: flex;
        align-items: center;
      }
    }
    .fira-filter-show-more {
      margin-left: auto;
      margin-right: 2px;
      color: #49a5df;
      cursor: pointer;
    }
  }
}

.fira-radio-button {
  width: 28px;
  height: 24px;
  border: 2px solid var(--fira-radio-color);
  border-radius: 6px;
  box-sizing: border-box;
  transition: 0.2s ease-in;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .fira-inside {
    border-radius: 4px;
    width: 17px;
    height: 17px;
    &:hover {
      background-color: var(--fira-radio-color);
    }
    &.fira-is-active {
      background-color: var(--fira-radio-color);
    }
  }
}
