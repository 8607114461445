@import "../../../../assets/fonts/fonts.scss";
@import "../../../../styles/fira-theme.scss";

@mixin column-display {
  display: flex;
  flex-direction: column;
}
@mixin row-display {
  display: flex;
  flex-direction: row;
}

.BrandIdentityFormWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  padding: 32px;
  gap: 10px;
  @media (max-width: 885px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  &__Images {
    @include row-display;
    justify-content: space-evenly;
    gap: 10px;
    &__Item {
      @include column-display;
      gap: 8px;
      label {
        font-size: 14px;
        font-weight: 500;
      }
      &__ImageContainer {
        width: 180px;
        height: 180px;
        background-color: #f7f8f9;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .SelectsWrapper {
    @include column-display;
    gap: 8px;
    span {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__Selects {
    @include column-display;
    gap: 40px;
    &__Typography {
      @include column-display;
      gap: 8px;
      position: relative;
      label {
        font-size: 14px;
        font-weight: 500;
      }
    }
    &__ColorSelectors {
      @include row-display;
      gap: 24px;
      &__Selector {
        @include row-display;
        justify-content: center;
        align-items: center;
        gap: 1.5em;
       @media (max-width: 1121px){
         justify-content: space-between;
         gap: 0.4em;
       }
        label {
          font-size: 12px;
          font-weight: 500;
          line-height: 85%;
        }
        &__Button {
          width: 52px;
          height: 52px;
          background-color: #f7f8f9;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}
