@import "../../../styles/fira-theme.scss";

.EmptyWrapper {
  width: 100%;
  height: 100%;
  background-color: $light-background;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    font-size: 400px;
  }
}
