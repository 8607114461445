@import "../../styles/fira-theme.scss";

.FiraTvWidgetSelectionViewWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-bottom: 20px;
  overflow: hidden;
  .WidgetCategoriesCard {
    width: 209px;
    height: 100%;
    background-color: $light-background;
    border-radius: 8px;
    max-height: 645px;
    box-sizing: border-box;
    padding: 16px;
    &__Searchbar {
      padding-bottom: 16px;
    }
    &__CategoriesList {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      li {
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 177px;
        padding-left: 16px;
        height: 40px;
        gap: 10px;
        border-radius: 8px;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 12px;
          position: relative;
          bottom: 2px;
        }
        .icon {
          font-size: 20px;
        }
      }
      li:hover {
        background-color: #f7f8f9;
      }
    }
  }

  .WidgetListContainer {
    flex-grow: 1;
    height: 100%;
    background-color: $light-background;
    border-radius: 8px;
    max-height: 645px;
    padding: 20px;
    box-sizing: border-box;
    &__List {
      height: 100%;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(244px, 1fr));
      column-gap: 20px;
      row-gap: 12px;
      box-sizing: border-box;
      padding: 0 50px;

      span {
        font-size: 14px;
        font-weight: 400;
        position: relative;
        top: 4px;
      }
      &__Item {
        height: 173px;
        width: 244px;
        border: 1px solid #d5dde0;
        border-radius: 8px;
        cursor: pointer;
        &__Thumbnail {
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          svg {
            font-size: 12em;
          }
        }
      }
      &__Item:hover {
        border: 1px solid $primary-color;
      }
      &__Item.available {
        border: 1px solid #000;
      }
    }
  }
}

.disabled {
  opacity: 0.3;
}

.selectedCategory {
  background-color: #f7f8f9;
}

.HeaderTitle {
  height: 70px;
  min-height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
