.Container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 1px 8px;
  box-sizing: border-box;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
}

.Tab {
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-right: 1px solid #d5dde0;
  padding: 0px 40px;
  background: none;
  line-height: 24px;
  color: #abafb1;
  cursor: pointer;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
    border-right: none;
  }
  &.Selected {
    color: #000;
    > span {
      border-bottom: 2px solid #ffde07;
    }
  }
}
