.StepWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  &.Off {
    flex: 0 0;
  }
}

.Step {
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #abafb1;
  transition: background-color 0.3s;
  font-size: 10px;
  font-weight: 600;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  span {
    position: absolute;
    top: -30px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    min-width: 123px;
    text-align: center;
  }
  &.Active {
    border-color: #ffde07;
    background-color: #ffde07;
  }
}

.Line {
  flex-grow: 1;
  margin: 0 8px;
  height: 3px;
  background-color: #abafb1;
  transition: background-color 0.3s;
  &.Filled {
    background-color: #ffde07;
  }
}
