.fira-slider-banner-wrapper {
  width: 100%;
  max-height: 559px;
  min-width: 350px;
}

.fira-slider-banner-element {
  width: 100%;
  position: relative;
  z-index: 95;
  cursor: pointer;
  height: 100%;
  aspect-ratio: 3.5/2;
  max-height: 559px;
  .fira-slider-banner-inner-element {
    background-color: #067dc7;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 96;
    .fira-slider-banner-image {
      width: 100%;
      height: 100%;
    }
    .fira-slider-banner-text {
      position: absolute;
      color: white;
      left: 15%;
      .fira-slider-banner-category {
        font-size: 23px;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0.1em;
      }
      .fira-slider-banner-name {
        font-size: 38px;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: 0em;
      }
      .fira-slider-banner-button {
        background-color: #067dc7;
        width: 95.38px;
        height: 38.15px;
        margin-top: 17px;
        border: 0px;
        cursor: pointer;
        color: white;
        .fira-slider-banner-button-mobile {
          height: 24px;
          border-radius: 14px;
          margin-top: 5px;
        }
      }
    }
  }
}
.fira-slider-banner-pagination-container {
  position: absolute;
  z-index: 42;
  width: 100%;
  height: 40px;
  bottom: 37px;
  display: flex;
  justify-content: center;
  &.fira-slider-banner-pagination-mobile {
    position: relative;
    bottom: 0px;
    margin-top: 17px;
  }
  .fira-slider-banner-pagination {
    width: fit-content !important;
    background: white;
    border-radius: 39px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-left: 18px;
    padding-right: 18px;
  }
}
.fira-slider-banner-bullet {
  height: 9px !important;
  width: 36px !important;
  border-radius: 39px;
  background-color: var(--fira-bullet-color);
}
.fira-slider-right-navigation-container {
  position: absolute;
  right: 5%;
  z-index: 99;
  height: fit-content;
  width: fit-content;
  top: 45%;
}

.fira-slider-left-navigation-container {
  position: absolute;
  left: 5%;
  z-index: 99;
  height: fit-content;
  width: fit-content;
  top: 45%;
}

.fira-slider-navigation-button {
  height: 47px;
  width: 47px;
  border-radius: 25px;
  background-color: #e4e4e4;
  color: #067dc7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
