@import "../../styles/fira-theme.scss";

.ProductInfoForm {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  overflow-y: auto;

  .ProductInfoColumn {
    gap: 12px;
    display: flex;
    flex-direction: column;
    > .NewProductWrapper {
      background-color: $light-background;
      border-radius: 8px;
      padding: 20px;

      &.ProductInputsGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px 37.32px;
        &.ProductInputsGridVariation {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          gap: 20px 20px;
        }
      }

      box-sizing: border-box;
      > .InputWrapper {
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 8px;

        .LabelIcon {
          svg {
            padding-bottom: 2px;
            padding-left: 12px;
          }
        }
        .LabelIconURL {
          position: relative;
          svg {
            padding-left: 12px;
            position: absolute;
            padding-top: 3px;
          }
        }
        .info {
          margin-left: 6px;
        }
      }

      > .LabelWrapper {
        margin-bottom: 20px;
      }
    }
    > .NewProductDivider {
      background-color: $light-background;
      border-radius: 8px;
      padding-top: 20px;
      padding-bottom: 10px;
      > .LabelWrapper {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $medium-gray-color;
        display: flex;
        justify-content: space-between;
      }
      > .ProductImages {
        display: flex;
        gap: 8px;
        padding-right: 20px;
        align-items: center;
        border-bottom: 1px solid #d5dde0;

        &.columnMode {
          flex-direction: column;
        }
        @media (max-width: 950px) {
          flex-direction: column;
        }
        > .ProductImageList {
          width: auto;
          max-width: 810px;
          display: flex;
          justify-content: flex-start;
          flex-shrink: 0;
          gap: 8px;
          overflow-x: auto;
          &.columnMode {
            width: 100%;
          }
          @media (max-width: 950px) {
            width: 100%;
          }
          > ul {
            padding: 0 1.15rem;
            flex-flow: row;
            display: flex;
            //flex-wrap: wrap;
          }
        }
      }
      > .LabelInfoImages {
        height: 35px;
        margin-left: 16px;
        display: flex;
        margin-top: 8px;
        gap: 6px;
        > .label-description-images {
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
        }
      }
      > .ProductsVariations {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;

        > .OptionList {
          align-self: stretch;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 10px;
          margin-bottom: 20px;
          max-height: 210px;
          overflow-y: auto;
          > .OptionItem {
            border-bottom: 0.5px solid $medium-gray-color;
            padding: 11px 14px 11px 23px;
            display: grid;
            grid-template-columns: minmax(auto, 10%) 1fr 70px;
            > .OptionItemName {
              margin-right: 24px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            > .VariationList {
              display: flex;
              gap: 10px;
              > .VariationItemName {
                padding: 7px 15px;
                border: 1px solid $medium-gray-color;
                border-radius: 4px;
              }
            }
            > .VariationItemButtons {
              display: grid;
              grid-template-columns: 30px 30px;
              gap: 10px;
              align-items: center;
            }
          }
        }
        > .VariationForm {
          width: 100%;
          display: grid;
          grid-template-rows: auto auto;
          justify-content: stretch;
          gap: 23px;
          > .VariationInputWrapper {
            display: grid;
            grid-template-rows: auto auto;
            gap: 14px;
          }
          > .VariationButtonWrapper {
            display: grid;
            grid-template-columns: 128px 128px;
            gap: 16px;
            justify-content: center;
          }
        }
      }
    }
  }

  .ImagesListContainer {
    display: flex;
  }

  .OpenLink {
    margin-left: 8px;
    color: #000;
  }
}

.LimitTextDetail {
  max-width: 500px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.listItem {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(100px, 1fr)
  ); // Puedes ajustar según tus necesidades
  gap: 10px; // Puedes ajustar según tus necesidades
}

.selected {
  grid-column: span 2;
  grid-row: span 2;
}
