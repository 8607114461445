@import "../../styles/fira-theme.scss";

.CredentialsModalWrapper {
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 29px 29px 41px 29px;
  text-align: center;
  display: grid;
  grid-template-columns: 384px;
  grid-template-rows: auto 36px auto;
  gap: 27px;

  .title {
    align-self: start;
    &.grid {
      display: grid;
      grid-template-columns: 30px 340px;
      grid-template-rows: 30px;
    }
    .icon {
      font-size: 30px;
    }
  }

  .content {
    place-content: center;
    &.grid {
      display: grid;
      grid-template-rows: 93px;
      gap: 17px;
    }
  }
}
