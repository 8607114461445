@import "../../styles/fira-theme.scss";

.ContainerEditVideo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  scroll-behavior: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 28px;
}
