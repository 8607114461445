.LandingTypographyModulesWrapper {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  padding-right: 8px;
}

.LandingTypographyModule {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &__Title {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}