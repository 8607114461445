@import '../../styles/fira-theme.scss';

.LandingConfigurationComponent {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  z-index: 999;
  box-sizing: border-box;
  padding-right: 8px;
  display: grid;
  grid-template-columns: 70px 272px auto;
  grid-template-rows: 56px auto;
  grid-template-areas: "navbar navbar navbar"
"sidebar modules viewer"
;

  @media (max-width: 835px){
    grid-template-columns: 64px 0 auto;
  }

  #navbar {
    grid-area: navbar;
    z-index: 3;
  }

  #sidebar {
    grid-area: sidebar;
    z-index: 3;
  }

  #modules {
    grid-area: modules;
    z-index: 3;
  }

  #viewer {
    grid-area: viewer;
  }

  .sidebarIsMobile {
    width: 0;
    padding: 0;
    border-right: 0;
    transition: width ease-in-out 300ms;
  }

  .showSideBar {
    width: 272px;
    border-right: 1px solid $medium-gray-color;
    transition: width ease-in-out 300ms; /* Add transition property here as well */
  }

  .viewerOverlay {
    background-color: rgba(0,0,0,0.32);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}