.LandingCatalogWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  padding-right: 8px;
  .LandingModule {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 10px;
    font-weight: 400;
    &__Title {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .ShoppingSwitch {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      label {
        font-size: 10px;
        font-weight: 400;
      }
    }
  }

}