@import "../../styles/fira-theme.scss";

.VideoSettings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 40px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .video_settings_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 38px 0 74px 0;
    border-radius: 10px;
    background-color: $light-background;

    .info_container {
      display: flex;
      flex-direction: column;
      gap: 14px;
      align-items: center;

      .info_title {
        font-size: 24px;
        line-height: 32px;
        color: $main-text;
      }

      .info_text {
        font-size: 16px;
        line-height: 22px;
        color: $main-text;
      }
    }
    .video_wrapper {
      height: 389px;
      background-color: $dark-background;
      border-radius: 10px;
      width: 675px;
      position: relative;
      display: grid;
      place-items: center;

      .setting_video {
        position: absolute;
        max-height: 100%;
        max-width: 100%;
        min-width: 100%;
        border-radius: 10px;
      }
    }

    .noAvailableVideo {
      position: absolute;
      color: #fff;
    }

    .buttons_wrapper {
      display: flex;
      gap: 24px;
      align-items: center;
      flex-direction: row;

      .button {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      .buttonEdit {
        gap: 16px;
      }

      .buttonReplace {
        gap: 10px;
      }
    }
  }

  .settings_loading_modal {
    background-color: $light-background;
    width: 500px;
    height: 400px;
    border-radius: 8px;
    display: grid;
    grid-template-rows: 68px 1fr;

    .settings_loading_header {
      border-bottom: 1px solid $medium-gray-color;
    }

    .settings_loading_header,
    .settings_loading_body {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      flex-direction: column;
    }

    .settings_loading_body {
      .fira_logo_wrapper {
        height: 80px;
        width: 80px;
        border: 1px solid $primary-color;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0;
        position: relative;
        transition: all 0.3s ease-in-out;

        .text-up {
          position: absolute;
          left: 14px;
          font-size: 14px;
          font-weight: 500;
          color: $light-text;
        }

        .text-down {
          position: absolute;
          bottom: 15px;
          left: 14px;
          font-size: 14px;
          color: $light-text;

          .text-right {
            font-weight: 700;
          }
        }
      }

      .settings_loading_progress h6 {
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
}
