@import "../../styles/fira-theme.scss";

.Wrapper {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 80px 1fr;
  background-color: $light-background;
  > .InnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    box-sizing: border-box;
  }
}

.Header {
  height: 80px;
  background-color: $light-background;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  .Border {
    width: 100%;
    height: 1px;
    background-color: #ff265f;
  }
}

.LabelWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  .Label {
    font-size: 16px;
    color: $main-text;
    font-weight: 500;
  }
}

.SelectButton {
  border: none;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  padding: 8px;
  border-radius: 4px;
  position: relative;
  .Icon {
    position: absolute;
    right: 8px;
    font-size: 8px;
  }
}

.SelectList {
  position: absolute;
  z-index: 100;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  background-color: #f7f8f9;
  bottom: 100%;
}

.ListItem {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.Pulsing {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.SuccessWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  > span:first-child {
    align-self: flex-start;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 32px;
  }
  > span {
    align-self: flex-start;
    font-size: 16px;
    font-weight: 400;
  }
  > .URL {
    align-self: center;
    margin: 52px;
    text-align: center;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    > span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.FailedWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 16px;
  > span {
    font-size: 14px;
    font-weight: 400;
  }
  .InnerButton {
    display: flex;
    align-items: center;
    color: #fff;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
  }
}

.SignUpDisabled {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 600px;
  > .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
  }
  > .message {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
