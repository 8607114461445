@import "../../styles/fira-theme.scss";

.CollectionsDetailViewWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  overflow-y: scroll;
  padding-bottom: 20px;
}

.Card {
  min-height: 254px;
  background-color: $light-background;
  border-radius: 8px;
  .InputGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

#form {
  box-sizing: border-box;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  flex: 1; /* make the form div take up the remaining space */
  max-width: calc(
    100% - 349px
  ); /* subtract the width of the image div from the container's width */
  min-width: 50%;
}

#image {
  width: 349px;
  .UploadCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    cursor: pointer;
    &__icon {
      width: 46px;
      height: 46px;
      background-color: $dark-gray-color;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      svg {
        font-size: 24px;
      }
    }
  }
}

#list {
  flex-grow: 2;
  width: 100%;
  height: 451px;
  box-sizing: border-box;
  padding: 24px 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
  .TableBottom {
    width: 100%;
    height: 72px;
    border-top: 1px solid #d5dde0;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.EmptyStateWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .PlusIcon {
    cursor: pointer;
    display: flex;
    width: 32px;
    height: 32px;
    background-color: $primary-color;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 22px;
    }
  }
  .emptyDescription {
    width: 300px;
    word-wrap: break-word;
    text-align: center;
    line-height: 24px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #d5dde0;
  margin: 8px 0;
}

.DeleteItem {
  display: flex !important;
  flex-direction: row;
  justify-content: end;
  svg {
    cursor: pointer;
    font-size: 24px;
  }
}

.Draggable {
  cursor: grab;
}

.PseudoTable {
  box-sizing: border-box;
  padding: 0 10px;
}

.RowRender {
  cursor: grab;
  width: 100%;
  height: 60px;
  border: 1px solid #d5dde0;
  background-color: #fff;
  border-radius: 8px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 10px;
  .Cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    span {
      font-size: 32px;
      color: #d0d0d0;
    }
    svg {
      font-size: 8px;
    }
    .DragItem {
      display: flex;
      gap: 18px;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
    }
    .ImageContainer {
      height: 40px;
      width: 40px;
      background-color: $dark-gray-color;
      border-radius: 6px;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .DeleteCell {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    button {
      height: 100%;
      background: transparent;
      border: none;
      font-size: 24px;
      cursor: pointer;
      svg {
        pointer-events: none;
      }
    }
  }
}

.Cell:nth-child(3),
.Head:nth-child(3) {
  text-align: left;
  justify-content: flex-start;
}

.HeaderRender {
  width: 100%;
  height: 48px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
}

.Head {
  text-align: center;
  font-size: 14px;
}

.CardTitle {
  height: 48px;
  padding-left: 24px;
}

.EmptyStateOnSearch {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.TopContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}
