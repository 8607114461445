@import "../../styles/fira-theme.scss";

.StoresDetailsWrapper {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr 271px;
  grid-template-rows: auto 1fr 1fr 1fr;
  gap: 11px 11px;
  grid-template-areas:
    "cards cards cards"
    "table table info"
    "table table info"
    "table table info";
  padding-bottom: 21px;
  &__CardsWrapper {
    grid-area: cards;
    width: 100%;
    display: grid;
    gap: 11px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: 119px;
    &.mobileCounter {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 119px);
    }
  }
  &__TableWrapper {
    grid-area: table;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__InfoWrapper {
    grid-area: info;
  }
}

.StoresDetailsWrapper {
  overflow-y: auto;
  &.mobileWrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    grid-template-columns: 1fr;
    gap: 11px;
    grid-template-rows: 256px auto 208px;
    grid-template-areas:
      "cards"
      "table"
      "info";
  }
}

.StoreInfoComponent {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &.mobileInfoComponent {
    align-items: center;
    width: 100%;
    height: 208px;
    .mobileButtons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 16px;
      .button {
        width: 100%;
      }
    }
  }
  &.desktopInfoComponent {
    display: flex;
    height: 100%;

    .InfoGrid {
      grid-template-columns: 1fr;
      grid-auto-rows: 1fr;
      align-content: flex-start;
      grid-template-areas:
        "storename"
        "contact"
        "country"
        "currency"
        "city"
        "website";
      overflow-y: auto;
      padding-bottom: 20px;
      gap: 16px;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .desktopButtons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      padding-top: 5px;
      .button {
        width: 100%;
      }
    }
  }

  &__InnerButton {
    font-size: 14px;
    font-weight: 500;
  }
}

.InfoGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    "storename country city contact"
    "website currency . contact";
  grid-row-gap: 24px;
  &__Item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    p {
      font-size: 12px;
      font-weight: 400;
    }
    &#storename {
      grid-area: storename;
    }
    &#website {
      grid-area: website;
    }
    &#country {
      grid-area: country;
    }
    &#currency {
      grid-area: currency;
    }
    &#city {
      grid-area: city;
    }
    &#contact {
      grid-area: contact;
    }
  }
}

.StoreTableComponent {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  background-color: #ffffff;
  padding-left: 10px;
}

.tableIsMobile {
  height: 436px !important;
}
.TableBottom {
  width: 100%;
  border-top: 1px solid #d5dde0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  background-color: #ffffff;
  z-index: 9;
  padding: 10px 0;
}

.AvatarContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .UserAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $medium-gray-color;
    position: relative;
    margin: auto;
    overflow: hidden;
    img,
    svg {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
}

.StoreTableLink {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
}

.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
}
