@import "../../styles/fira-theme.scss";

.eventButtonsContainer {
  display: flex;
  min-width: 258px;
  justify-content: space-between;
  gap: 6px;
  align-items: center;

  .rightButtonsWrapper {
    display: flex;
    gap: 8px;
    align-items: center;

    .idWrapper {
      margin-right: 10px;
      display: flex;
      gap: 5px;
      align-items: center;
      .infoIcon {
        font-size: 11px;
      }
      .idText {
        color: #a1a4a4;
        max-width: 120px;
      }
      .copyIcon {
        cursor: pointer;
        font-size: 20px;
      }
      .ellipsis {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        word-break: break-word;
      }
    }
  }
  .loader {
    font-size: 5px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.1s infinite ease;
    transform: translateZ(0);
    margin-right: 15px;
    margin-left: 5px;
  }
  .exportButton, .previewButton {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .previewButton {
    text-decoration: underline;
  }

}
.contentWrapper {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 16px;
  scroll-behavior: auto;
  overflow-x: hidden;
  .countersRow {
    display: flex;
    flex-direction: row;
    min-width: 937px;
    gap: 8px;
  }

  .iframeContainer {
    height: 417px;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1.5fr 0.5fr;
    gap: 10px;
    &.HideChat{
      grid-template-columns: 1fr;
    }
    .SkeletonVideo {
      background-color: $dark-background;
      border-radius: 10px;
      color: $light-text;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .SkeletonWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .SkeletonHeader {
        background-color: $light-background;
        border-radius: 11px;
        height: 62px;
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        box-sizing: border-box;
        .breakName {
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          word-break: break-word;
        }
        > .StoreAvatar {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
        > p {
          color: $main-text;
        }
        > .SignIcon {
          font-size: 35px;
        }
      }
      .SkeletonBody {
        width: 70%;
        padding: 25px 22px 16px 22px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        gap: 25px;
        background-color: $darker-gray-color;
        border-bottom-left-radius: 11px;
        border-bottom-right-radius: 11px;
        > .RefreshButton {
          border: none;
          border-radius: 10px;
          width: 334px;
          height: 42px;
          cursor: pointer;
        }
      }
    }
  }
  .scheduledVideo {
    grid-template-columns: 1fr;
  }
}

.chatPlaceholder {
  min-width: 210px;
  height: 417px;
  background: $light-background;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .message {
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 100px;
    .bubble {
      font-size: 98px;
    }
    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
    }
    .sub {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0;
    }
  }

  .input {
    height: 40px;
    font-size: 180px;
    position: absolute;
    bottom: 140px;
  }

  @keyframes mulShdSpin {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #4b4c4c,
        1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
        2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
        1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
        0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
        -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
        -2.6em 0em 0 0em rgba(75, 76, 76, 0.5),
        -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.7),
        1.8em -1.8em 0 0em #4b4c4c, 2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
        1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
        0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
        -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
        -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
        -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.5),
        1.8em -1.8em 0 0em rgba(75, 76, 76, 0.7), 2.5em 0em 0 0em #4b4c4c,
        1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
        0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
        -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
        -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
        -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
        1.8em -1.8em 0 0em rgba(75, 76, 76, 0.5),
        2.5em 0em 0 0em rgba(75, 76, 76, 0.7), 1.75em 1.75em 0 0em #4b4c4c,
        0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
        -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
        -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
        -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
        1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
        2.5em 0em 0 0em rgba(75, 76, 76, 0.5),
        1.75em 1.75em 0 0em rgba(75, 76, 76, 0.7), 0em 2.5em 0 0em #4b4c4c,
        -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.2),
        -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
        -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
        1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
        2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
        1.75em 1.75em 0 0em rgba(75, 76, 76, 0.5),
        0em 2.5em 0 0em rgba(75, 76, 76, 0.7), -1.8em 1.8em 0 0em #4b4c4c,
        -2.6em 0em 0 0em rgba(75, 76, 76, 0.2),
        -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
        1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
        2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
        1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
        0em 2.5em 0 0em rgba(75, 76, 76, 0.5),
        -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.7), -2.6em 0em 0 0em #4b4c4c,
        -1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(75, 76, 76, 0.2),
        1.8em -1.8em 0 0em rgba(75, 76, 76, 0.2),
        2.5em 0em 0 0em rgba(75, 76, 76, 0.2),
        1.75em 1.75em 0 0em rgba(75, 76, 76, 0.2),
        0em 2.5em 0 0em rgba(75, 76, 76, 0.2),
        -1.8em 1.8em 0 0em rgba(75, 76, 76, 0.5),
        -2.6em 0em 0 0em rgba(75, 76, 76, 0.7), -1.8em -1.8em 0 0em #4b4c4c;
    }
  }
}
