@import "../../../styles/fira-theme.scss";

.EventTeamWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 90%;
  @media (max-width: "740px") {
    width: 100%;
  }

  .SearchContainer {
    width: calc(100% - 10px);
    height: 44px;
    padding: 0px 8px 29px 0;
    display: flex;
    gap: 31px;
    .SearchIcon {
      font-size: 20px;
    }
    .inputContainer {
      width: 100%;
      input::placeholder {
        color: $dark-gray-color;
      }
    }

    .counter {
      width: 168px;
      display: flex;
      align-items: center;
      gap: 11px;
      .text {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
      }
      .square {
        border: 1px solid $medium-gray-color;
        width: 71px;
        height: 28px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .TeamMembersWrapper {
    height: 90%;
    overflow-y: scroll;
    margin-bottom: 20px;
    padding: 0 15px 15px 15px;
    width: 100%;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $light-gray-color;
      border-radius: 4px;
    }
    &::-moz-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .TeamMembers {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 20px;
      @media (max-width: "970px") {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: "740px") {
        grid-template-columns: repeat(2, 1fr);
      }
      .Member {
        width: 180px;
        height: 183px;
        border: solid 2px $gray-text;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .CheckTypes {
          position: relative;
          top: 7px;
          right: 8px;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          align-self: flex-end;
          z-index: 3;
          .hidden {
            display: none;
          }
        }
        .MemberEllipse {
          position: relative;
          top: 8px;
          right: 11px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          align-self: flex-end;
          z-index: 3;
          &.active {
            background: $success-color;
          }
          &.inactive {
            background-color: $danger-color;
          }
        }
        .Selection {
          position: relative;
          top: 6px;
          right: 6px;
          width: 17px;
          height: 17px;
          align-self: flex-end;
          z-index: 3;
        }
        .Overlay {
          width: 180px;
          height: 183px;
          position: absolute;
          border-radius: 10px;
          z-index: 2;
          cursor: pointer;
          &.inactive {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
            background-color: $dark-gray-color;
          }
        }
        .Avatar {
          width: 93px;
          height: 97px;
          background-color: $medium-gray-color;
          border-radius: 10px;
          overflow: hidden;
          img {
            height: 100%;
            object-fit: contain;
          }
        }
        .MemberInfo {
          display: flex;
          flex-direction: column;
          text-align: center;
          position: relative;
          bottom: 7px;
        }
        &.inactive {
          pointer-events: none;
        }
        &.selected {
          border-color: $primary-color;
        }
      }
      .Member:hover {
        border-color: $primary-color;
      }
      .Member.Creator {
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

.EmptyTeamView {
  width: 100%;
  height: stretch;
  background-color: $light-background;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    font-size: 400px;
  }
}

.selectedRed {
  background-color: red;
}

.MemberName {
  width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
