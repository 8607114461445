.Auth {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: url(../../assets/img/background.jpg) rgba(0, 0, 0, 0.2) no-repeat
    center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-blend-mode: multiply;

  .square {
    width: 372px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
    margin-left: 40px;
    padding: 0px 28px 32px 28px;
    position: relative;
  }
}
