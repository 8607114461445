.MassificationForm {
  width: 100%;
  height: 100%;

  .SwitchContainer {
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    gap: 12px;
    align-items: center;
    label {
      font-weight: 500;
      font-size: 14px;
    }
  }
}