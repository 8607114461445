.fira-next-events-container {
  display: flex;
  justify-content: center;
  .fira-next-events-wrapper {
    width: 1043px;
    .fira-next-events-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 24px;

      .fira-next-events-title {
        font-size: 40px;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: left;
        align-items: flex-end;
        max-width: 420px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .fira-next-events-see-more {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: right;
        align-items: flex-end;
        display: flex;
        cursor: pointer;
        margin-block-start: 0px;
        margin-block-end: 0px;
        text-decoration-line: underline;
      }
    }

    .fira-next-events-divisor {
      height: 8px;
      width: 100%;
      border-radius: 8px;
      margin-bottom: 25px;
    }

    .fira-next-event-swipper {
      display: none;
      width: 200%;
      margin-left: -50%;
      height: 404px;
    }

    .fira-next-events-elements {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 0px;
      column-gap: 17px;
      justify-content: center;
      grid-template-rows: minmax(auto, 1fr) minmax(0px, 0);
      overflow: hidden;
    }

    .more-elements {
      row-gap: 17px;
      grid-template-rows: minmax(auto, 1fr) minmax(auto, 1fr);
    }

    .fira-next-event-wrapper {
      height: 446px;
      min-width: 189px;
      border-radius: 8px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .fira-next-event-image {
        cursor: pointer;
        width: 100%;
        height: 176px;
        border-radius: 8px;
        margin-bottom: 24px;
        background-color: #f7f8f9;
      }
      .fira-next-event-image-error {
        cursor: pointer;
        width: 100%;
        height: 176px;
        border-radius: 8px;
        margin-bottom: 24px;
        font-size: 176px;
        background-color: #f7f8f9;
        display: flex;
        justify-content: center;
      }

      .fira-next-event-date {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 8px;
      }

      .fira-next-event-title {
        cursor: pointer;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .fira-next-event-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: justify;
        max-height: 101px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      .fira-next-event-button {
        cursor: pointer;
        height: 40px;
        width: 100%;
        border-radius: 4px;
        border: 0px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
      }
    }
  }

  @media (max-width: 719px) {
    .fira-next-events-wrapper {
      min-width: 266px;
      // max-width: 347px;

      .fira-next-events-title {
        font-size: 24px !important;
        line-height: 29px !important;
      }

      .fira-next-event-swipper {
        display: block;
      }
      .fira-next-events-elements {
        display: none;
      }

      .fira-next-events-see-more {
        display: none !important;
      }

      .fira-next-event-wrapper {
        height: 339px;
        min-width: 143px;

        .fira-next-event-image {
          height: 121px;
        }

        .fira-next-event-image-error {
          font-size: 121px;
          height: 121px;
        }

        .fira-next-event-date {
          font-size: 12px;
          line-height: 14px;
        }

        .fira-next-event-title {
          font-size: 16px;
          line-height: 19px;
        }

        .fira-next-event-description {
          font-size: 12px;
          line-height: 14px;
        }

        .fira-next-event-button {
          height: 28px;
          width: 100%;
          border-radius: 4px;
        }
      }
    }
  }
}
