@import "../../styles/fira-theme.scss";

.ProductsView {
  width: 100%;
  height: 100%;
  .ShadowWrapper {
    border-radius: 10px;
    box-shadow: 1px 17px 44px rgba(3, 2, 41, 0.07);
  }
}

.LinkToNewProduct {
  color: $main-text;
  text-decoration: none;
}

.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
}

.EmptyWrapper {
  width: 100%;
  height: stretch;
  background-color: $light-background;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $dark-gray-color;
  height: 100%;
  * {
    font-size: 400px;
  }
}

.centerSkeleton {
  display: flex;
  justify-content: center;
}
.LimitText {
  max-width: 500px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ownerLabel {
  background: #e0f0ff;
  color: #387a9d;
  border-radius: 5px;
  padding: 2px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  height: 22px;
  width: 85px;
}

.partialLabel {
  background: #d5dde0;
  color: #616161;
  border-radius: 5px;
  padding: 2px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  height: 22px;
  width: 85px;
}

.totalLabel {
  background: #f7f8f9;
  color: #616161;
  border-radius: 5px;
  padding: 2px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  height: 22px;
  width: 85px;
}
