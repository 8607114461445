.fira-vod-gallery-wrapper {
  display: flex;
  gap: 123px;

  .fira-videos-container {
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 74%;
    &.fira-videos-mobile-container {
      width: 100%;
    }
    .fira-videos-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .fira-mobile-filter {
        display: flex;
        gap: 6px;
        cursor: pointer;
        .fira-filter-icon {
          font-size: 20px;
        }
        .fira-filter-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
        }
      }
      .fira-sort-type-group {
        width: 124px;
        height: 36px;
        display: flex;
        flex-direction: row;
        background-color: #ffff;
        border: 1px solid #e7e6e6;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .fira-sort-element {
          color: var(--fira-sort-color);
          font-size: 20px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          &.sort-selected {
            color: var(--fira-sort-active);
          }
        }
        &.fira-sort-mobile {
          border: none;
          background: none;
          width: fit-content;
          color: #000000;
          height: 30px;
          .sort-selected {
            color: var(--fira-sort-active);
            border-bottom: 1px solid var(--fira-sort-active);
            height: 24px;
            align-self: self-end;
          }
        }
      }
    }

    .fira-videos-group {
      width: 100%;
      .fira-video-grid-element {
        position: relative;
        width: 100%;
        padding-top: 83%;
        background-color: #067dc7;
        border-radius: 8px;
        z-index: 98;
        cursor: pointer;
      }
      .fira-video-list-element {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 26px;
        .fira-video-image-container {
          position: relative;
          width: 33%;
          min-width: 200px;
          padding-top: 27%;
          background-color: #067dc7;
          border-radius: 8px;
          z-index: 98;
          cursor: pointer;
        }
        .fira-video-name {
          display: flex;
          height: 100%;
          width: 64%;
          flex-direction: column;
          justify-content: center;
          min-width: 228px;
          .fira-video-title {
            font-size: 20px;
            font-weight: 500;
            line-height: 9px;
            letter-spacing: 0em;
            margin-bottom: 20px;
          }
          .fira-video-description {
            font-size: 20px;
            font-weight: 400;
            line-height: 9px;
            letter-spacing: 0em;
          }
          .fira-video-time {
            font-size: 20px;
            font-weight: 500;
            line-height: 9px;
            letter-spacing: 0em;
          }
        }
      }

      .fira-video-image {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 99;
        border-radius: 8px;
      }
      .fira-video-play {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color: white;
        font-size: 24px;
        z-index: 100;
      }
      &.fira-video-grid-1 {
        display: grid;
        grid-template-columns: 1fr;
        grid-row: auto;
        gap: 10px;
      }
      &.fira-video-grid-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row: auto;
        gap: 10px;
      }
      &.fira-video-grid-3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row: auto;
        gap: 10px;
      }
      &.fira-video-grid-4 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        gap: 10px;
      }
      &.fira-video-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}

.fira-filter-mobile-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000078;
  z-index: 100;
  .fira-filter-mobile-container {
    background: white;
    position: relative;
    z-index: 101;
    width: 80%;
    height: 100%;
    .fira-filter-container {
      width: 100%;
      .fira-filter-header {
        border-bottom: 1px solid #d5dde0;
        padding-bottom: 12px;
        padding-top: 12px;
        margin-bottom: 12px;
      }
      .fira-filter-group {
        width: 80%;
        gap: 10px;
      }
    }
  }
}
