@import "../../../styles/fira-theme.scss";

.DialogWrapper {
  width: 700px;
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .HeaderDialog {
    display: flex;
    width: 100%;
    height: 72px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
    border-bottom: 1px solid #d5dde0;
  }
  .TableWrapper {
    width: 97%;
    border-collapse: collapse;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
  }
  .itemTr {
    border-bottom: 1px solid #d5dde0;
  }
  > .FooterDialog {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 72px;
    gap: 12px;
  }
}

.labelEmpty {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
}
