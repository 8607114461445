@import "../../styles/fira-theme.scss";

.WidgetConfigurationLayout {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding: 32px 24px;
  max-height: 72vh;
  padding-bottom: 60px;
}
