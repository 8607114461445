@import "../../styles/fira-theme.scss";

.InfoCardWrapper {
  width: 100%;
  min-height: 64px;
  background-color: $light-background;
  border: 1px solid $medium-gray-color;
  border-radius: 8px;
  display: block;
  .InfoCardHeader {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 30px;
    .InfoIcon {
      position: absolute;
      top: 18px;
      left: 20px;
    }

    .InfoCardTitle {
      position: relative;
      width: 918px;
      height: 24px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding-left: 56px;
      top: 18px;
    }

    .MinimizeIcon {
      position: absolute;
      top: 4px;
      right: 20px;
      cursor: pointer;
    }
    .CloseIcon {
      position: absolute;
      top: 18px;
      right: 30px;
      cursor: pointer;
    }
  }

  .InfoCardBody {
    width: 90%;
    padding: 20px 56px;
    height: auto;
    opacity: 0;
    p {
      font-size: 16px;
      font-weight: 400;
    }
    &.open {
      opacity: 1;
      transition: opacity 0.2s ease-in 0.1s;
      height: auto;
    }
  }
  &.open {
    max-height: 210px;
    transition: max-height 0.2s ease-in;
  }

  &.closed {
    max-height: 0px;
    transition: max-height 0.2s ease-out;
  }
}
