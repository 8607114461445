@import "../../styles/fira-theme.scss";

.ViewportButtons {
  display: flex;
  flex-direction: row;
  button {
    height: 39px;
    width: 50px;
    background-color: $light-background;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $medium-gray-color;
    cursor: pointer;
  }
  button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  button:nth-child(2) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  > :hover {
    border-color: $primary-color;
  }
  .active {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}

.FiraTvWidgetsViewWrapper {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "infocard"
    "widgetboard";
  gap: 16px;
  align-items: flex-start;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

#infocard {
  grid-area: infocard;
}

#configcard {
  grid-area: configcard;
}

#widgetviewer {
  grid-area: widgetviewer;
  display: flex;
  flex-direction: column;
  gap: 18px;
  overflow-x: hidden;
}

#widgetboard {
  height: 100%;
  grid-area: widgetboard;
  display: grid;
  grid-template-columns: 0.4fr 1.6fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "configcard widgetviewer"
    "configcard .";
  gap: 24px;
}
