.MessageWrapper {
  width: 100%;
  padding: 4px 14px 5px 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #fff;
  .UserInfoWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    position: relative;
    align-items: flex-start;
    padding-right: 65px;
    .From {
      font-feature-settings: "clig" off, "liga" off;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .Time {
      font-feature-settings: "clig" off, "liga" off;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }

    &.IsAdmin {
      grid-template-columns: auto 1fr 1fr;
    }
  }
  .TextWrapper {
    white-space: pre-line;
    margin: 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: break-word;
  }

  &.AdminWrapper {
    background-color: #f7f8f9;
  }

  .AdminBadge {
    padding: 1px 4px;
    border-radius: 2px;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Montserrat;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
  }

  &:hover {
    background-color: #f7f8f9;
    border: 1px solid #f7f8f9;
  }
}

.OptionsButtonWrapper {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 6px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    background: none;
    border: none;
  }
}

@media (max-width: 981px) {
  .MessageWrapper {
    background: #00000033;
    backdrop-filter: blur(4px);
    border: none;

    .Time {
      color: #fff !important;
    }

    .TextWrapper {
      color: #fff !important;
    }

    &:hover {
      background-color: #f7f8f92f;
    }
  }
}
