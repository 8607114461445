@import "../../styles/fira-theme.scss";

.WidgetConfigurationCard {
  width: 100%;
  height: 100%;
  background-color: $light-background;
  border-radius: 8px;
  box-sizing: border-box;
  padding-top: 32px;
  padding-right: 16px;
  min-width: 390px;
  &__Title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    padding-left: 24px;
  }
}

hr {
  border: 0;
  height: 1px;
  background: #d5dde0;
  margin: 0;
}

.sectionTitle {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
}
