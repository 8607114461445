@import "../../styles/fira-theme.scss";

.Radio {
  width: 11px;
  height: 11px;
  border: 1px solid $dark-gray-color;
  border-radius: 50%;
  box-sizing: border-box;
  transition: 0.2s ease-in;
  cursor: pointer;

  &:hover {
    border-color: $primary-color;
  }
  &.IsDisabled {
    &:hover {
      border-color: $dark-gray-color;
    }
    cursor: not-allowed;
    background-color: $main-background;
  }
  &.IsActive {
    border: 3px solid $primary-color;
    &.IsDisabled {
      border-color: $dark-gray-color;
      background-color: transparent;
    }
  }
}
