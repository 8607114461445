@import "../../styles/fira-theme.scss";
@import "react-big-calendar/lib/sass/styles";

.FiraCalendarWrapper {
  width: 100%;
  height: 100%;
  max-height: 700px;
  background-color: $light-background;
  border-radius: 15px;
}

.FiraCalendarEventWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;
  &.Active {
    background-color: $primary-color !important;
  }
  .text {
    text-align: center;
  }
  &.isFinished {
    background-color: $dark-gray-color;
  }
  &.eventTest {
    background-color: $light-background;
    border: 1px solid $primary-color;
  }
}

.FiraCalendarMonthEventWrapper {
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: 10px 1fr;
  gap: 5px;
  padding-left: 5px;
  width: 100%;
  .firaCalendarCircle {
    background-color: $primary-color;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    &.isFinished {
      background-color: $dark-gray-color;
    }
    &.eventTest {
      background-color: $light-background;
      border: 1px solid $primary-color;
    }
  }
  .text {
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}

.FiraCalendarWeekHeader {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.FiraCalendarGutterWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .Icon {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 30px;
  }
}

.FiraCalendarTimeSlotGroup {
  justify-content: center;
  align-items: center;
}
