@import "../../styles/fira-theme.scss";

.WidgetsViewer {
  width: 100%;
  background-color: $light-background;
  border-radius: 8px;
  box-sizing: border-box;
  .WidgetsViewerTitle {
    font-size: 16px;
    line-height: 19.5px;
    font-weight: 600;
    padding: 24px;
  }
  .Display {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .MobileDisplay {
      height: 472.5px;
      position: relative;
      .MobileMockup {
        height: 430px;
        width: 210px !important;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .WidgetView {
          transform: scale(0.6);
          transform-origin: center;
          width: 100%;
        }
      }
      .previewMobileContainer {
        font-size: 450px;
        position: absolute;
        display: flex;
        align-items: center;
        top: -10px;
        z-index: 99;
        pointer-events: none;
      }
    }
  }
}

.gridEventsContainer {
  width: 100vw;
  transform: scale(0.5);
  position: relative;
  top: -30px;
}

.mainCarouselContainer {
  width: 60vw;
  transform: scale(0.8);
}

.carouselEventsContainer {
  position: relative;
}

.carouselEventsContainerMobile {
  position: relative;
  transform: scale(0.5);
  right: 222px;
  width: 647px;
}

.gridEventsContainerMobile {
  position: relative;
  transform: scale((0.29));
}

.mainCarouselContainerMobile {
  width: 650px;
  position: relative;
  transform: scale((0.5));
  right: 230px;
}

.vodGalleryContainer {
  width: 100%;
  transform: scale((0.7));
  padding-bottom: 80px;
}

.vodGalleryContainerMobile {
  width: 290px;
  height: 420px;
  right: 37px;
  position: relative;
}

.bannerCommercialContainer {
  max-width: 630px;
  justify-self: center;
  align-self: center;
  width: 100%;
}

.bannerCommercialContainerMobile {
  width: 320px;
  right: 55px;
  position: relative;
}

.bannerSliderContainer {
  width: 100%;
  transform: scale((0.5));
  position: relative;
  align-self: center;
  bottom: 30px;
}

.bannerSliderContainerMobile {
  height: 100%;
  position: relative;
  overflow: hidden;
  transform: scale(0.59);
  width: 530px;
  right: 160px;
}

.categoriesContainer {
  width: 100%;
  transform: scale((0.8));
  position: relative;
  bottom: 30px;
}
.categoriesContainerMobile {
  width: 406px;
  transform: scale(0.8);
  position: relative;
  right: 98px;
}

.NoImageBanner {
  justify-self: center;
  width: 100%;
  background-color: $medium-gray-color;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}
