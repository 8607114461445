@import "../../../styles/fira-theme.scss";
.VariationForm {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  justify-content: stretch;
  gap: 23px;
  > .VariationInputWrapper {
    display: grid;
    grid-template-rows: auto auto;
    gap: 14px;
  }
  > .VariationButtonWrapper {
    display: grid;
    grid-template-columns: 128px 128px;
    gap: 16px;
    justify-content: center;
  }
}
.VariationItemList {
  border: 1px solid #d5dde0;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-content: end;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;

  width: 95%;
}
