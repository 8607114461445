@import "../../styles/fira-theme.scss";

.DateTimeInputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  .Icon {
    position: absolute;
    left: 18px;
    top: 10px;
    svg {
      font-size: 20px;
    }
  }

  .Input {
    border-radius: 8px;
    border: none;
    background-color: $light-background;
    padding-left: 60px;
    cursor: pointer;
    font-family: "Montserrat";
    border: 1px solid $medium-gray-color;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &:disabled {
      border-color: $medium-gray-color;
      background-color: $main-background;
      cursor: default;
    }
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    z-index: 10;
    margin-left: -28%;
    opacity: 0;
  }
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
}

// input[type="time"]::-webkit-calendar-picker-indicator,
// input[type="date"]::-webkit-calendar-picker-indicator {
//   background: none;
//   display: none;
// }
