.PopupContainer{
  position: relative;
  width: 80px;
  height: 80px;
  > .closeIcon {
    width: 19px;
    height: 19px;
    position: absolute;
    top: -8px;
    right: -5px;
    font-size: 19px;
    cursor: pointer;
    z-index: 2;
    > .icon {
      color: black;
    }
  }
  .timer{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    background: rgb(123 122 122 / 25%);
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: absolute;
  }
  .inside{
    -webkit-filter: blur(5px);
    filter: blur(1px);
    position: absolute;
    z-index: 1;
    padding: 6.15px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    text-align: center;
    border-radius: 6px;
    img{
      width: 67px;
      height: 67px;
      border-radius: 6px;
    }

  }
}