.BouncingLoader {
  display: flex;
  justify-content: center;
}

.BouncingLoader > div {
  width: 8px;
  height: 8px;
  margin: 3px;
  border-radius: 2px;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    transform: translateY(-8px);
  }
}

.BouncingLoader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.BouncingLoader > div:nth-child(3) {
  animation-delay: 0.4s;
}