@import '../../../../styles/fira-theme.scss';

.LandingPageModulesWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  padding-right: 8px;
}

.LandingModule {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &__Title {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__counter {
      background-color: #F7F8F9;
      padding: 4px 20px;
      border-radius: 4px;
      color: #ABAFB1;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.ShoppingSwitch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  label {
    font-size: 10px;
    font-weight: 400;
  }
}

.LogoAlignmentSelector {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  border: 1px solid $medium-gray-color;
  display: flex;
  flex-direction: row;
  gap: 42px;
  box-sizing: border-box;
  padding-left: 16px;
  align-items: center;
  &__Option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &__Circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $medium-gray-color;
      &.active {
      background-color: $primary-color;
      }
    }
    label {
      font-size: 8px;
      font-weight: 400;
    }
  }
}