.InputColorWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  label {
    font-size: 10px;
    font-weight: 400;
  }
  .ColorContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    .color {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      background-color: black;
    }
    .fauxinput {
      border-bottom: 1px solid #D0D0D0;
      width: 80px;
      font-size: 12px;
      display: flex;
      input {
        border: 0;
        font-size: 12px;
        width: 100%;
        border: none;
        outline: none;
        font-family: "Montserrat", sans-serif;
        &:focus {
          border-bottom: 1px solid transparent;
          box-sizing: border-box;
        }
      }
    }

  }

}