@import "../../styles/fira-theme.scss";

.CopyButtonContainer {
  width: 100%;
  height: 124px;
  background-color: $light-background;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 15px 23px 39px 23px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  label {
    font-size: 14px;
    font-weight: 400px;
    line-height: 17px;
  }
  .ButtonInner {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .Icon {
      position: relative;
      top: 2px;
      svg {
        height: 16px;
      }
    }
  }
  .ButtonInnerSuccess {
    font-size: 11px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    .Icon {
      top: 2px;
      position: relative;
      svg {
        font-size: 20px;
        height: 20px;
        color: white;
      }
    }
  }
}
