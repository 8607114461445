@import "../../../styles/fira-theme.scss";

.AttachmentsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  .TitleContainer {
    text-align: center;
    margin-top: 24px;
    .AttachmentsMessage {
      margin-top: 16px;
    }
  }

  .AttachmentsSections {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
    .AttachmentsColumns {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      width: 55%; //TEMP
      justify-content: space-between;
      .AttachmentsColumn {
        display: flex;
        flex-direction: column;
        width: 123px;
        .ColumnTitle {
          padding-bottom: 33px;
        }
        .Row {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          height: 40px;
          .Icon {
            font-size: 22px;
          }
          .RowRadio {
            margin-top: 2.5px;
          }
        }
        .UploadInputArea {
          cursor: pointer;
          width: 123px;
          height: 22px;
          border-radius: 5px;
          border: solid 1px $dark-gray-color;
          display: flex;
          justify-content: center;
          align-items: center;
          .UploadInput {
            height: 18px;
            width: 117px;
            border-radius: 5px;
            border: dashed 1px $dark-gray-color;
            color: $dark-gray-color;
            font-weight: 400;
            font-size: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .UploadInput.dragged {
            border: none;
            background-color: $medium-gray-color;
          }
          input {
            display: none;
          }
        }
      }
    }
    .ProfanityWordsContainer {
      margin-top: 8%;
      width: 100%;
      padding-bottom: 30px;
      .WordsContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        border: 1px solid $main-background;
        border-radius: 5px;
        padding: 10px 14px;
        margin-top: 14px;
        max-height: 160px;
        overflow-y: scroll;
        .InputWord {
          margin-right: 12px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.hidden {
  visibility: hidden;
}

.Word {
  border-radius: 5px;
  height: 22px;
  min-width: 56px;
  width: auto;
  background-color: $main-background;
  padding: 0 4px 0 6px;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 10px;
  .WordFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    .WordIcon {
      margin-top: 4px;
      font-size: 14px;
      color: $dark-gray-color;
      cursor: pointer;
    }
  }
}

.FileName {
  height: 22px;
  width: 113px;
  background-color: $main-background;
  padding: 0 4px 0 6px;
  border-radius: 5px;
  .WordFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    .WordIcon {
      margin-top: 4px;
      font-size: 14px;
      color: $dark-gray-color;
      cursor: pointer;
    }
  }
}

.FileWarning {
  font-weight: 400px;
  font-size: 8px;
  color: $danger-color;
  position: relative;
  top: 5px;
}
