@import "../../styles/fira-theme.scss";

.Wrapper {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  > .Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    > :first-child {
      font-size: 20px;
      font-weight: 700;
    }
    > :last-child {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .InputsWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    > .Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > :first-child {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }
    }
    > .InputList {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      max-height: 210px;
      gap: 16px;
      scroll-padding-left: 16px;
    }
    > :last-child {
      align-self: flex-start;
    }
    button:disabled {
      background-color: #f7f8f9;
      border: 1px solid #d5dde0;
    }
  }

  .UrlWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    > :first-child {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      display: flex;
      gap: 6px;
      :last-child {
        color: #000;
      }
    }
  }
}

.InputItemWrapper {
  display: flex;
  gap: 16px;
  > .InputText,
  > .SelectWrapper {
    flex: 1;
  }
  > .SelectWrapper {
    position: relative;
    .IconWrapper {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .InputSelect {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #d5dde0;
    font-size: 16px;
    padding: 0px 6px;
    -webkit-appearance: none;
    &:disabled {
      background: #f7f8f9;
    }
  }
  .Delete {
    border-color: $danger-color;
  }
}
