@import "../../styles/fira-theme.scss";

.NavButton {
  height: 42px;
  width: 169px;
  box-sizing: border-box;
  padding: 6px 12px;
  border: none;
  color: $main-text;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  position: relative;

  &:hover {
    border: 0.5px $dark-background solid;
  }

  > .NavButtonIcon {
    width: 30px;
    height: 30px;
    font-size: 30px;
    margin-right: 6px;
    display: flex;
    justify-content: center;
  }

  > .TextContent {
    opacity: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  &.IsActive {
    color: $primary-color;
    background-color: $dark-background;
    cursor: default;
    &:hover {
      border: none;
    }
  }

  &.IsClose {
    box-sizing: border-box;
    height: 43px;
    width: 43px;
    padding: 6px 1px 7px 6px;
  }
}

.navSubButton {
  padding: 6px 12px;
  height: 17px;
  align-self: Center;
  text-decoration-line: none;
  align-items: center;
  display: grid;
  grid-template-columns: 16px 1fr;
  padding-left: 33px;
  .subButtonName {
    color: #6e7175;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    &.isSubActive {
      font-weight: 600;
    }
  }
  .subIcon {
    font-size: 15px;
    color: #6e7175;
    line-height: 10px;
  }
}

.subMenu {
  position: absolute;
  left: 43px;
  top: 12px;
  width: 121px;
  display: flex;
  z-index: 20;
  justify-content: flex-end;
  .subMenuContainer {
    width: 111px;
    background-color: $light-background;
    z-index: 20;
    padding-bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 25px 35px rgba(10, 8, 36, 0.1);
    .subMenuTitle {
      color: $main-text;
      padding: 6px 20px 5px 20px;
      border-bottom: solid 0.5px #d9d9d9;
      font-size: 12px;
      font-weight: 400;
    }
    .subMenuContent {
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .subMenuButton {
        text-decoration-line: none;
        align-items: center;
        display: grid;
        grid-template-columns: 16px 1fr;
        padding-left: 8px;
        .subMenuName {
          color: $main-text;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          &.isSubActive {
            font-weight: 600;
          }
        }
        .subMenuIcon {
          font-size: 15px;
          color: $main-text;
          line-height: 10px;
        }
      }
    }
  }
}
