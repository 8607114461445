@import '../../../styles/fira-theme.scss';

.LandingModulesBarWrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: $light-background;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  flex-direction: column;
  padding: 16px 8px 16px 16px;
  box-sizing: border-box;

  .LandingScrollView {
    width: 100%;
    max-height: 85vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-bottom: 40px;
  }
}