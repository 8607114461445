@import "../../styles/fira-theme.scss";

.container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid $dark-gray-color;
  margin: 5px;
  border-radius: 8px;
}
.container-selected {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border-radius: 8px;
  border: 2px solid $primary-color;
}
.box {
  width: 110px;
  height: 110px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.delete-mode {
  background: #d0d0d0;
  opacity: 0.8;
}
.active-mode {
  background: #d0d0d0;
  background: transparent;
}
.box-item {
  cursor: grab;
  width: 100%;
  height: 100%;
  position: absolute;

  .item-draggable {
    cursor: grab;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
  .item-recovery {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .item-select {
    cursor: pointer;
    position: absolute;
    left: 8px;
    top: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .item-star {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
.box img {
  width: 100%;
  height: auto;
  position: absolute;
}
.delete-select {
  background: #d0d0d0;
  opacity: 0.8;
}
@supports (object-fit: cover) {
  .box img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.recovery-button {
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  .label-recovery {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
  }
}
.delete-button {
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;

  /* Blanco */
  background: #ffffff;

  /* Borde */
  border: 1px solid #d5dde0;
  border-radius: 4px;

  > svg {
    pointer-events: none;
  }
}
