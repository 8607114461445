@import "../../../styles/fira-theme.scss";

.EventListPopUpWrapper {
  margin-right: 32px;
  max-height: 90vh;
  width: 272px;
  min-height: 72px;
  overflow: hidden;
  background-color: $light-background;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  top: 0;

  &__Top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__Title {
      font-size: 16px;
      font-weight: 700;
    }
    &__Icon {
      cursor: pointer;
    }
  }

  &__ItemsList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-grow: 1;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    &__Divider {
      width: 100%;
      height: 1px;
      background-color: #d5dde0;
    }
  }
}

.EventItem {
  height: 63px;
  max-height: 63px;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 8px;
  &__StatusIndicator {
    height: 100%;
    width: 4px;
    border-radius: 16px;
  }
  &__EventInfo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    cursor: pointer;
    &__Top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      h4 {
        font-size: 12px;
        line-height: normal;
        max-width: 200px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-weight: 600;
      }
      &__LiveIcon {
        width: 40px;
        height: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        background-color: #ff2c30;
        color: #fff;
        border-radius: 4px;
        padding: 4px;
        gap: 4px;
        &__Circle {
          width: 6px;
          height: 6px;
          background-color: $light-background;
          border-radius: 50%;
        }
        span {
          font-size: 10px;
          font-weight: 700;
        }
      }
    }
    &__StoreName {
      height: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        font-size: 10px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.dividerLine {
  border-bottom: 1px solid #d5dde0;
}

.eventSmall {
  font-size: 10px;
  color: #abafb1;
}
