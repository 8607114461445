@import "../../styles/fira-theme.scss";

.counterContainer {
  width: 100%;
  height: 118px;
  border-radius: 10px;
  background-color: $light-background;
  place-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  .textElements {
    text-align: center;
    margin-top: 10px;
    .title {
      height: 22px;
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
    }
    .numbers {
      height: 24px;
    }
  }
  .wrapperOption {
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: space-between;
    .textData {
      margin-left: 3px;
    }
  }
  .iconContainer {
    width: 42px;
    height: 42px;
    background-color: $main-background;
    border-radius: 10px;
    font-size: 20px;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info {
    display: flex;
    justify-content: flex-end;
    align-self: end;
    margin-right: 6px;
    &:hover {
      color: $primary-color;
    }
  }
}
