@import "../../../../styles/fira-theme.scss";

.UserPermissionsDetailWrapper {
  min-height: 153px;
  width: 100%;
  background-color: $light-background;
  border-radius: 8px;
  height: auto;
  .header {
    padding-left: 32px;
    border-bottom: solid 1px #d5dde0;
    > .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .permissionsDetail {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .subtTitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
    .permissionsGroup {
      display: grid;
      gap: 40px;
      grid-template-columns: repeat(2, 1fr);

      &.mobile {
        grid-template-columns: auto;
      }
    }
  }
}
