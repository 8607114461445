@import "../../styles/fira-theme.scss";
.usersWidgetContainer {
  background: $light-background;
  border-radius: 15px;
  padding: 16px 20px 15px 20px;
  gap: 17px;
  min-width: 279px;
  width: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  .header {
    padding-left: 9px;

    h6 {
      font-weight: 800;
      line-height: 19.5px;
    }
    p {
      font-size: 13px;
      line-height: 15.85px;
    }
  }
  .tableContainer {
    overflow: scroll;
    overflow-x: auto;
    overflow-y: auto;
    box-sizing: border-box;
    width: 100%;
    height: 90%;
    .centered {
      text-align: -webkit-center;
      text-align: -moz-center;
    }
    .scrollWrapper {
      min-width: 400px;
      height: 100%;
    }
    .nameCell {
      // max-width: 130px;
      // min-width: 130px;
    }
    .emailCell {
      max-width: 150px;
      padding-left: 5px;
      padding-right: 10px;
    }
    .phoneCell {
      max-width: 100px;
    }
    .centerSkelleton {
      display: flex;
      place-content: center;
    }
  }

  .tableEllipsis {
    display: -webkit-box;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}

.emptyProducts {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  img {
    width: 30%;
  }
}

.tableHeader,
.tableHeaderFirst {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  height: 100%;
  display: flex;
  align-items: center;
}

.tableHeader {
  justify-content: center;
  padding: 0 5px;
  p {
    width: 65%;
    text-align: center;
  }
}

.tableHeaderFirst {
  justify-content: flex-start;
}
