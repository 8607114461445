@import "../../styles/fira-theme.scss";

.EventConfigurationWizardWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 85px auto 85px;
  gap: 16px;
  padding-top: 30px;
  overflow-x: hidden;
  .EventConfigurationTop {
    width: 100%;
    height: 100%;
    position: relative;
    .LeftIconMarkUp {
      img {
        position: absolute;
        bottom: 0;
      }
    }
    .RightIconMarkup {
      img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .EventConfigurationComponentsContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 14px;
    overflow: hidden;
    position: relative;
    &__Info {
      text-align: center;
      gap: 16px;
      box-sizing: border-box;
      padding: 40px 0 14px 0;
      .Title {
        font-size: 20px;
        font-weight: 700;
      }
      .Desc {
        font-weight: 400;
        font-size: 14px;
      }
    }
    &__Component {
      flex-grow: 1;
      overflow: hidden;
      padding-right: 6px;
      box-sizing: border-box;
      scrollbar-width: thin;
      padding: 16px 40px;
      padding-top: 0;
      display: flex;
      justify-content: center;
    }

    &__BottomMessage {
      width: 100%;
      border-top: 1px solid $dark-gray-color;
      box-sizing: border-box;
      height: 35px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      bottom: 0;
      background-color: $light-background;
      span {
        font-size: 12px;
        @media (max-width: 620px) {
          font-size: 9px;
        }
      }
    }
  }
  .EventConfigurationBottom {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    &__Button {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .EventConfigurationTop,
  .EventConfigurationComponentsContainer,
  .EventConfigurationBottom {
    background-color: $light-background;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

.NoPadding {
  padding: 0 !important;
}
