@import "../../styles/fira-theme.scss";

.HeaderDashboard {
  min-height: 80px;
  width: 100%;
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(min(100%, 14rem), 1fr));
  align-content: center;

  .ItemText {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    .title {
      font-weight: 400;
      font-size: 28px;
      line-height: 38px;
    }
    .info {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .itemsRight {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    .exportButton {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    .previewButton {
      text-decoration: underline;
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}

.OptionsBar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.videoContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  scroll-behavior: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;

  .ContainerGroup {
    display: flex;
    grid-template-rows: repeat(auto-fit, minmax(1fr, 1fr));
    flex-direction: column;
    gap: 1rem;
  }
  .itemJumbo {
    border-radius: 15px;
    background: white;
    width: 100%;
  }
  .itemBox {
    border-radius: 15px;
    background: white;
    width: 100%;
  }
  .countersWrapper {
    display: grid;
    gap: 7px;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 8rem), 1fr));
  }
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 25rem), 1fr));
    gap: 1rem;
    height: 75%;
    box-sizing: border-box;
    .bigContainers {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 100%;

      .table {
        display: flex;
        background-color: $light-background;
        height: 100%;
        width: 100%;
        padding: 12px 18px;
        border-radius: 10px;
        box-sizing: border-box;
        .tableHeader {
          padding: 0 9px;

          .tableSubtitle {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
          }
        }
        .tableContainer {
          height: 100%;
        }
      }
      .graph {
        display: flex;
        flex-direction: column;
        background-color: $light-background;
        height: 276px;
        width: 652px;
        border-radius: 10px;
        gap: 30px;
        .grapHeader {
          display: flex;
          place-content: space-between;
          padding: 10px 17px 0 24px;
          .selectGroup {
            display: flex;
            gap: 10px;
          }
        }
      }
    }
    .sideContainers {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .events {
        background-color: $light-background;
        display: flex;
        flex-direction: column;
        height: 300px;
        width: 100%;
        border-radius: 10px;
        padding: 16px 24px 17px 24px;
        box-sizing: border-box;
        gap: 18px;
        .textNoTitle {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 90%;
          flex-direction: column;
        }
        .eventsElementsWrapper {
          height: 90%;
        }
        .textLink {
          color: $primary-color;
          text-align: center;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
        }
        .nextEventsContainer {
          height: 100%;
        }
        .buttonNextEvents {
          background: transparent;
          border: none;
          cursor: pointer;
          font-size: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          margin-top: 9px;
          padding: 0;
        }
      }
      .activity {
        background-color: $light-background;
        display: flex;
        height: 317px;
        width: 226px;
        border-radius: 10px;
      }
    }
  }
}

.itemEventActive {
  background-color: $primary-color;
}
.itemEventInactive {
  background-color: #e8e8e8;
}

.itemSeparator {
  width: 100%;
  height: 0;
  border: 0.5px solid #e8e8e8;
  margin-bottom: 9px;
  margin-top: 9px;
}
.ContainerNextEvents {
  display: flex;
  flex-direction: row;
  height: 36px;
  gap: 7px;

  .side {
    height: 36px;
    width: 3.93px;
    border-radius: 16px;
  }
  .textTitleEvent {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    max-width: 300px;
  }

  .textTime {
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: #d0d0d0;
    align-items: center;
  }
}

.DateRangePicker {
  position: relative;
  .pickerButton {
    height: 30px;
    width: 213px;
    border-radius: 6px;
    background-color: $light-background;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.1);

    cursor: pointer;
    .icon {
      color: #dadada;
      font-size: 30px;
    }
    &.opened {
      border: 1px solid $primary-color;
      .icon {
        color: $primary-color;
      }
    }
    .dateText {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 5px;
      width: 80%;
      justify-content: center;
    }
  }
  .calendarContainer {
    position: absolute;

    background: $light-background;
    box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    right: 10px;
    z-index: 3;
    margin-top: 13px;
    padding: 28px 16px;
    display: flex;
    gap: 17px;
    flex-direction: column;

    .selectFilter {
      gap: 10px;
      display: flex;
      flex-direction: column;
    }

    .rangeInputs {
      display: flex;
      flex-direction: row;
      gap: 56px;
    }
    .calendarRange {
      width: 100px;
    }
  }
}

.skeletonCard {
  width: 120px;
  height: 118px;
  border-radius: 10px;
  background-color: $light-background;
  place-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 10px;
  margin: 3px;
}
.skeletonCell {
  gap: 10px;
  margin: 3px;
}

.EventName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.BackBtnStats {
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  margin-right: 12px;
}
