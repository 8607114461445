@import "../../../../styles/fira-theme.scss";

.UserPermissionsTableWrapper {
  min-height: 153px;
  width: 100%;
  background-color: $light-background;
  border-radius: 8px;
  height: auto;
  .header {
    padding: 14px 32px;
    border-bottom: solid 1px #d5dde0;
    > .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .tableWrapper {
    padding-bottom: 10px;
    .showPermissions {
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-decoration: underline;
    }
  }
}
