@import "../../../styles/fira-theme.scss";

.FiraTopContextMenuWrapper {
  min-width: 100%;
  min-height: 48px;
  background-color: $light-background;
  border-radius: 0 0 6px 6px;
  box-shadow: 0px 16px 40px rgba(3, 2, 41, 0.07);
  position: absolute;
  top: 44px;
  right: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
  padding: 12px 0;
}

.FiraTopContextMenuItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  box-sizing: border-box;
  padding: 0 15px;
  height: 30px;
  align-items: center;
  cursor: pointer;
  .Icon {
    font-size: 28px;
    top: 3px;
    position: relative;
  }
  .Label {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
  }
}

.disabledOption {
  opacity: 0.3;
  pointer-events: none;
}
