@import "../../styles/fira-theme.scss";

.Logo {
  margin-top: 16px;
  font-size: 68px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 45px;
  margin-bottom: 14px;
  .InvitationLogo {
    width: 68px;
    height: 68px;
    border-radius: 10px;
    background-color: $dark-gray-color;
  }
}

.TitleMessage {
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Title {
  .h4 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 19px;
  }
}

.BackButton {
  font-size: 34px;
  position: absolute;
  margin-top: 20px;
  margin-left: -10px;
  a {
    text-decoration: none;
    color: $main-text;
  }
}

.alert-container {
  max-width: 371px;
  min-height: 29px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 0px #f160631a;
  margin-bottom: 20px;
  padding: 4px 11px;

  .alert-message {
    justify-self: center;
    align-self: center;
    text-align: center;
    padding: 7px;
    .Link {
      color: $main-text;
      text-decoration: none;
    }
  }

  &.danger {
    background-color: $light-background;
    color: $danger-color;
  }

  &.info {
    background-color: $light-background;
    color: $danger-color;
  }

  .alert-text-bold {
    &.danger {
      color: $danger-color;
    }
  }
  .button-alert {
    display: flex;
    font-size: 16px;
    right: 6px;
    background: none;
    border: none;
    margin: 0px;
    padding: 0px;
    color: $light-gray-color;
    flex-direction: column;
    place-content: center;
    cursor: pointer;
  }

  .icon-bubble {
    display: flex;
    flex-direction: column;
    place-content: center;
  }
}
