@import "../../styles/fira-theme.scss";

.RolesAndPermissionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__ComponetContainer {
    width: 100%;
    min-height: 471px;
    background-color: $light-background;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    padding: 16px 24px;
    &__InfoItem {
      display: flex;
      flex-direction: column;
      gap: 16px;
      p {
        font-size: 16px;
        font-weight: 600;
      }
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &__ButtonWrapper {
    display: flex;
    justify-content: flex-end;
  }
}
