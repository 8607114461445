@import "../../../styles/fira-theme.scss";

.EventColorWrapper {
  width: 90%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 4px;
  }
  &::-moz-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &__SelectorContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 15px;
  }
  &__Mockups {
    display: grid;
    grid-template-rows: 24px 200px 200px;
    box-sizing: border-box;
    position: relative;
    gap: 8px;
    &__Title {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
    }
    &__Web {
      display: flex;
      padding: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__Chat {
      display: flex;
      flex-direction: row;
      gap: 16px;
      justify-content: center;
      align-items: center;
      padding: 0;
      box-sizing: border-box;
      position: relative;
    }
  }
}
