@import "../../styles/fira-theme.scss";

.ContainerAudienceResume {
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 124px;
  width: 100%;
  border-radius: 10px;
  padding: 16px 24px 9px 24px;
  box-sizing: border-box;

  .boxContainer {
    display: flex;
    justify-content: stretch;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 20px;
    .textContent {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
  }
  .SubtitleValue {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
  }

  .TextValue {
    color: $primary-color;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 67px;
    justify-self: center;
  }
}

.CustomTooltipWrapper {
  background: #ffffff;
  border-radius: 8px;
  padding: 8px;
  .TooltipLabel {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000;
  }
  .SubLabel {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #000;
  }
}
