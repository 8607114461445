@import "../../../styles/fira-theme.scss";
.dashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  scroll-behavior: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .countersWrapper {
    display: grid;
    grid-column-gap: 7px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: 1fr;
  }

  .desktopContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "tableWidget tableWidget"
      "interactions .";
    gap: 16px;
    padding-bottom: 30px;
  }

  #tableWidget {
    grid-area: tableWidget;
    height: 322px;
    max-height: 322px;
  }

  #interactions {
    grid-area: interactions;
  }

  .mobileCounter {
    width: 100%;
  }

  .mobileContainer {
    display: grid;
    max-width: 100%;
    grid-template-columns: 1fr;
    padding-bottom: 30px;
    height: 100%;
    padding-bottom: 10px;
    .subGrid {
      display: grid;
      gap: 16px;
      height: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 322px auto;
      grid-template-areas:
        "table"
        "interactions";
      &__interactions {
        grid-area: interactions;
      }
      &__table {
        grid-area: table;
      }
    }
  }
}
