.custom-calendar.rmdp-wrapper {
  background-color: #fff;
  border-radius: 5px;
  direction: ltr;
  text-align: center;
  width: max-content;
  border-style: none;
  color: #000;
  width: 380px;
  margin-bottom: 10px;

  .rmdp-shadow {
    box-shadow: 0 0 5px #fff;
  }
  .rmdp-border {
    border: 1px solid #cfd8e2;
  }
  .rmdp-calendar {
    height: max-content;
    padding: 0px;
    transform: scale(0.94);
    margin-left: 2px;
    //margin-left: 12px;
  }
  .rmdp-border-top {
    border-top: 1px solid #cfd8e2;
  }
  .rmdp-border-bottom {
    border-bottom: 1px solid #cfd8e2;
  }
  .rmdp-border-left {
    border-left: 1px solid #cfd8e2;
  }
  .rmdp-border-right {
    border-right: 1px solid #cfd8e2;
  }
  .rmdp-week,
  .rmdp-ym {
    display: flex;
    justify-content: space-between;
  }
  .rmdp-ym {
    height: 25%;
  }
  .rmdp-day,
  .rmdp-week-day {
    color: #000;
    cursor: pointer;
    height: 29px;
    position: relative;
    width: 29px;
  }
  .rmdp-week-day {
    color: #000;
    cursor: default;
    font-size: 12px;
    font-weight: 500;
  }
  .rmdp-day span,
  .rmdp-week-day {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .rmdp-day span {
    border-radius: 50%;
    bottom: 3px;
    font-size: 14px;
    left: 3px;
    position: absolute;
    right: 3px;
    top: 3px;
  }
  .rmdp-day.rmdp-today span {
    background-color: #ffde07;
    color: #000;
  }
  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #ffde07;
    box-shadow: 0 0 3px #8798ad;
    color: #000;
  }

  .rmdp-ym .rmdp-day {
    flex: 1;
    margin: auto;
  }
  .rmdp-ym .rmdp-day span {
    border-radius: 12px;
    padding: 2px 0;
  }
  .rmdp-range {
    background-color: #fff5b2;
    box-shadow: 0 0 3px #fff5b2;
    color: #000;
  }
  .rmdp-range.start {
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    background-color: #ffde07;
  }
  .rmdp-range.end {
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    background-color: #ffde07;
  }
  .rmdp-ym .rmdp-range.start {
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }
  .rmdp-ym .rmdp-range.end {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }
  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: #ffde07;
    color: #000;
  }
  .rmdp-day-picker {
    padding: 0px;
  }
  .rmdp-header {
    font-size: 12px;
    height: 38px;
    line-height: 24px;
    margin-top: 5px;
    font-weight: 500;
  }

  .rmdp-arrow {
    border: solid #000;
    border-width: 0 2px 2px 0;
    display: inline-block;
    height: 3px;
    margin-top: 5px;
    padding: 2px;
    width: 3px;
  }

  .rmdp-left,
  .rmdp-right {
    position: absolute;
    top: 54%;
    transform: translateY(-50%);
  }
  .rmdp-left {
    left: 0;
  }
  .rmdp-right {
    right: 0;
  }
  .rmdp-arrow-container {
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: center;
    margin: 0 5px;
    width: 20px;
  }
  .rmdp-arrow-container:hover {
    background-color: #ffde07;
    box-shadow: 0 0 3px #8798ad;
  }
  .rmdp-arrow-container:hover .rmdp-arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;
  }
  .rmdp-arrow-container.disabled {
    cursor: default;
  }

  .rmdp-rtl .rmdp-right {
    left: 0;
    right: auto;
  }
  .rmdp-rtl .rmdp-left {
    left: auto;
    right: 0;
  }
  .rmdp-rtl .rmdp-range.start {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-color: #ffde07;
  }
  .rmdp-rtl .rmdp-range.end {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-color: #ffde07;
  }
  .rmdp-rtl .rmdp-range.start.end {
    border-radius: 50%;
  }
  .rmdp-rtl .rmdp-ym .rmdp-range.start {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }
  .rmdp-rtl .rmdp-ym .rmdp-range.end {
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }
  .rmdp-day-hidden,
  .rmdp-day.rmdp-disabled {
    cursor: default;
  }
  .rmdp-selected .highlight {
    box-shadow: 0 0 3px #8798ad;
  }
}
