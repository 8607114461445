@import "../../../styles/fira-theme.scss";

.credentialsWrapper {
  padding: 23px 18px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 23px;
  }

  .DivisorGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .CodeGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 14px 12px 21px 12px;
    > p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
    .CodeInner {
      text-align: start;
      font-family: monospace;
      font-size: 10px;
      padding: 6px 0px;
      display: flex;
      align-items: center;
      > .Icon {
        margin: 0px 19px 0px 10px;
        min-width: 30px;
        font-size: 30px;
      }
      > p {
        display: block;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: underline;
      }
    }
  }

  .CloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    padding: 0px;
  }

  .buttonsGroup {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 14px 0px 21px 0px;
    gap: 18px;
    .copyButton {
      background-color: $light-background;
      transition: 300ms ease-in;
      &:active {
        border-color: $primary-color;
        background-color: $primary-color;
        color: $light-background;
        .icon {
          color: $primary-color;
        }
      }
      .buttonInner {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        .text {
          font-size: 12px;
          font-weight: 600;
          line-height: 24px;
          width: 100%;
        }
      }
    }
  }
}

.DivisorWrapper {
  width: 464px;
  transition: 300ms ease-in;
  background-color: $main-background;
  border-radius: 10px;
  .DivisorHeader {
    cursor: pointer;
    width: 100%;
    height: 42px;
    border-radius: 10px;
    background-color: $light-background;
    border: 1px solid $primary-color;
    box-sizing: border-box;
    position: relative;
    transition: 300ms ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    .ArrowIcon {
      transition: 300ms ease-in;
      position: absolute;
      top: 6px;
      bottom: 6px;
      right: 9px;
      font-size: 30px;
    }
  }

  .DivisorBody {
    transition: 300ms ease-in;
  }

  &.CloseDivisor {
    height: 42px;
    .DivisorHeader {
      background-color: $main-background;
      border: $main-background;
      .ArrowIcon {
        transform: rotateZ(180deg);
      }
    }
    .DivisorBody {
      display: none;
    }
  }
}

.CopyButtonWrapper {
  min-height: 37px;
  box-sizing: border-box;
  background-color: $light-background;
  border-radius: 10px;
  padding: 0px 8px;
  border: none;
  font-family: "Montserrat";
  cursor: pointer;
  &.CopyActive {
    background-color: $primary-color;
  }
  > .SuccessCopy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .Icon {
      font-size: 22.5px;
      color: $light-text;
    }
    background-color: $primary-color;
    color: $main-text;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
  }
}
