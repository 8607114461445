@import "../../styles/fira-theme.scss";

.FiraSideBarWrapper {
  width: 240px;
  background-color: #ffffff;
  height: calc(100vh - 56px);
  box-shadow: inset -1px 0px 0px #e4e5e7;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  left: 0;
  padding-top: 16px;
  position: relative;
  -webkit-transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  overflow: hidden;
  gap: 16px;

  .NavigationButtonsWrapper {
    width: 90%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: scroll;
    height: 75vh;
    padding-bottom: 64px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .BottomNavigationWrapper {
    display: flex;
    width: 224px;
    position: absolute;
    bottom: 0;
    min-height: 48px;
    box-sizing: border-box;
    background-color: $light-background;
  }
}

.close {
  -webkit-transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  width: 0;
  left: -240px;
}

.FiraSideBarWrapper.isMobile {
  position: absolute;
  z-index: 5;
}
