@import "../../styles/fira-theme.scss";

#reproductor,
.subscriberContainer {
  position: relative;
  background-color: black;
  border-radius: 10px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 417px;
  width: 100%;
}

.countdownTimer {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;
}

.timerStart {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2.5px);
  border-radius: 4px;
  color: white;
  text-align: center;
  width: 68px;
  height: 34px;
  align-content: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.timerEnd {
  background-color: white;
  border-radius: 4px;
  color: red;
  text-align: center;
  width: 123px;
  height: 60px;
  align-content: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.timerText {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  transition: all 0.3s ease;
}

.timerTextEnd {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  transition: all 0.3s ease;
}

#red5pro-subscriber {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.red5pro-media-time-field {
  visibility: hidden;
}

.noVideoText {
  color: #fff;
  text-align: center;
}

.videoMuted {
  position: absolute;

  &.d-none {
    display: none;
  }

  img {
    width: 9rem;
  }
}

.VideoPopupContainer {
  color: white;
  position: absolute;
  top: 32px;
  left: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reconnectingMessage {
  &.overVideo {
    position: absolute;
  }
  z-index: 3;
  width: 424px;
  border-radius: 11px;
  height: 62px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0px 25px;
  .storeAvatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
  .signIcon {
    font-size: 35px;
  }
}

.reload {
  position: absolute;
  z-index: 4;
  width: 429px;
  padding: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 25px;
  background-color: $darker-gray-color;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  > .RefreshButton {
    border: none;
    border-radius: 10px;
    width: 334px;
    height: 42px;
    cursor: pointer;
  }

  .reloadText {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    > .bold {
      font-weight: 700;
    }
  }
  .breakName{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}
