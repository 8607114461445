@import "../../../styles/fira-theme.scss";

.TemplateParamsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .TitleContainer {
    text-align: center;
    padding-bottom: 5px;
    z-index: 4;
  }

  .ContentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 280px;
    justify-content: center;
    position: relative;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .TemplatesWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .TemplateItems {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      gap: 20px;
      height: 100%;
      align-items: center;
      justify-content: center;
      @media (max-width: "940px") {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: "727px") {
        grid-template-columns: repeat(1, 1fr);
      }
      .TemplateContainer {
        display: flex;
        flex-direction: column;
        gap: 7px;
        height: 100%;
        align-items: center;
        justify-content: center;
        .TemplateItem {
          display: flex;
          width: 186px;
          background-color: $light-background;
          border-radius: 10px;
          border: 1px solid $medium-gray-color;
          position: relative;
          height: 100%;
          flex-direction: column;
          min-height: 180px;
          max-height: 274px;

          cursor: pointer;
          .TemplateTitle {
            display: flex;
            width: 100%;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 10px;
            text-align: center;
            margin-top: 7px;

            span {
              width: 170px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .TemplateParams {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            .ParamsItem {
              display: flex;
              flex-direction: column;
              padding-bottom: 13px;
              .ListTitle {
                display: flex;
                align-items: center;
                .ListTitleIcon {
                  font-size: 10px;
                  margin-right: 15px;
                  margin-top: 3px;
                }
                width: 100%;
              }
              ul {
                list-style: none;
                margin-left: 26px;
                li {
                  .ListItemIcon {
                    font-size: 6px;
                    margin-right: 6px;
                    margin-top: 2px;
                  }
                  display: flex;
                  align-items: center;
                  font-weight: 300;
                  font-size: 10px;
                  padding: 4px 0;
                }
              }
            }
          }
        }
        .TemplateItem:hover,
        .TemplateItem.TemplateItem.Selected {
          border: 1.5px solid $primary-color;
        }
      }
    }
  }

  .Center {
    justify-content: center;
  }
}

.AddMore {
  justify-self: center;
  align-self: center;
  position: relative;
  width: 70%;
  max-width: 668px;
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $main-background;
  border: 1px dashed $medium-gray-color;
  border-radius: 8px;
  margin-top: 15px;
  font-size: small;
  cursor: pointer;
  .AddButton {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    background-color: $medium-gray-color;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 25px;
    margin-bottom: 7px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.ColorCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 10px;
}

.PaginationBtn {
  width: 30px;
  height: 60px;
  background-color: $medium-gray-color;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: absolute;
  font-size: 30px;
  cursor: pointer;
}

.BtnLeft {
  top: calc(52% - 60px);
  left: 70px;
}

.BtnRight {
  top: calc(52% - 60px);
  right: 70px;
}

ul {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0px;
}
