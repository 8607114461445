@import "../../../styles/fira-theme.scss";

#SettingsModalWrapper {
  z-index: 100;
  background-color: #0102024d;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  transition: 0.1s ease-in;
  flex-direction: column;
}

#SettingsModal {
  max-width: 100%;
  max-height: 92%;
  height: 92%;
  box-sizing: content-box;
  background-color: $main-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-rows: 53px 1fr;
  gap: 17px;
  position: relative;
  transition: top 0.4s ease-in;

  @media screen and (max-width: 981px) {
    max-height: 100%;
    height: 100%;
  }
}

#SettingsModalBody {
  display: grid;
  grid-template-columns: 240px 1fr;
  padding: 0 32px 24px 23px;
  gap: 24px;

  @media screen and (max-width: 981px) {
    grid-template-columns: 1fr;
    padding: 0 23px;
  }

  #returnToSidebar {
    position: absolute;
    top: 12px;
    left: 12px;
    cursor: pointer;
    z-index: 1;
  }
}

#SettingsModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 45px;
  z-index: 1;
  background: #ffffff;
  border-radius: 10px;

  #SettingsModalClose {
    height: 23px;
    width: 23px;
    background-color: $dark-background;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    display: flex;
    justify-content: center;
    span {
      color: $light-text;
      font-size: 20px;
      align-self: flex-end;
    }
  }

  .SettingsModalHeaderBody {
    display: grid;
    gap: 10px;
    grid-template-columns: max-content max-content;
    align-items: center;
    .SettingsModalTitleIcon {
      font-size: 20px;
    }
  }

  #SettingsModalTitle {
    font-size: 16px;
    font-weight: 700;
    color: $main-text;
    line-height: 24px;
    display: inline;
  }
}

@media screen and (max-width: 981px) {
  #SettingsModalComponent {
    display: none;
  }
}

.CloseButtonIcon {
  font-size: 14px;
  font-family: "Montserrat";
  padding: 0 32px;

  cursor: pointer;
}
