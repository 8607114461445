@import "../../../styles/fira-theme.scss";

.ProfileFormWrapper {
  width: 100%;
  background-color: $light-background;
  border-radius: 8px;
  height: auto;
  &__Top {
    height: 52px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 23px 0 43px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &__Title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__FormContainer {
    width: 100%;

    &__OpenMenu {
      height: 100%;
      width: 100%;

      &__GridContainer {
        box-sizing: border-box;
        padding: 24px 40px;
        width: 100%;
        height: 563px;
        display: grid;
        grid-template-columns: 0.6fr 1.4fr;
        grid-template-rows: repeat(5, 1fr) 5fr;
        grid-template-areas:
          "avatar firstname"
          "avatar lastname"
          "avatar birthdate"
          "gender email"
          "phonenumber username"
          "bottom-btns bottom-btns";
        grid-row-gap: 24px;
        grid-column-gap: 5%;
        margin: auto;

        &__FormItem {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 8px;

          .InputImageContainer {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          label {
            font-size: 16px;
            font-weight: 500;
            padding: 0;
          }
          &__Select {
            position: relative;
            display: flex;
            align-items: center;
            select {
              cursor: pointer;
              width: 100%;
              background-color: #f7f8f9;
              height: 40px;
              border-radius: 8px;
              border: none;
              -webkit-appearance: none;
              box-sizing: border-box;
              padding-left: 16px;
              font-family: "Montserrat", sans-serif;
              font-size: 16px;
              font-weight: 400;
            }
            select:focus {
              border: 1px solid $primary-color;
              outline: none;
            }
            &__ArrowIcon {
              position: absolute;
              font-size: 20px;
              right: 4%;
              top: 30%;
              cursor: pointer;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

// **** GRID *****

#avatar {
  grid-area: avatar;
}
#gender {
  grid-area: gender;
}
#username {
  grid-area: username;
}
#firstname {
  grid-area: firstname;
}
#lastname {
  grid-area: lastname;
}
#birthdate {
  grid-area: birthdate;
}
#phonenumber {
  grid-area: phonenumber;
}
#email {
  grid-area: email;
}

#bottom-btns {
  grid-area: bottom-btns;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .CancelButton {
    font-weight: 400;
    font-size: 14px;
  }
  .SaveButton {
    font-weight: 500;
    font-size: 14px;
  }
}

.birthdateArea {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  &__Item {
    flex-grow: 1;
  }
}

.phoneNumberArea {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: 40px;
  gap: 8px;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.InputImageContainer {
  width: 100%;
  height: 100%;
}
