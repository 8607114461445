@import "../../styles/fira-theme.scss";

.GreetingsWrapper {
  min-height: 78px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .TextWrapper {
    display: flex;
    flex-direction: column;
    .Title {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
    }
    .Info {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.DashboardTabWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;

  .TabsWrapper {
    height: 32px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-bottom: 16px;
  }
  .Content {
    height: 100%;
    width: 100%;
  }
}

.DashboardTabButtonWrapper {
  cursor: pointer;
  height: 32px;
  box-sizing: border-box;
  border: 1px solid #d5dde0;
  padding: 6px 12px;
  border-radius: 4px;
  transition: 0.4s ease-in-out;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  background-color: $main-background;

  &.Active {
    cursor: default;
    border-color: $dark-background;
    background-color: $dark-background;
    color: $primary-color;
  }
}
