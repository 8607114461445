@import '../../../styles/fira-theme.scss';

.LandingSideBar {
  width: 70px;
  height: 100%;
  background-color: white;
  border-right: 1px solid #D5DDE0;
  padding: 16px 8px;
  box-sizing: border-box;
  overflow: hidden;
  gap:16px;
  display: flex;
  flex-direction: column;
}

//Buttons
.LandingButton {
  max-width: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;

  &__Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    &.active {
      background-color: $primary-color;
      border-radius: 4px;
    }
  }
  &__Title {
    font-size: 10px;
    font-weight: 600;
    max-width: 64px;
    white-space: pre-wrap;
    -webkit-user-select: none; /* Safari and older versions of Chrome */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Standard syntax */
  }
}