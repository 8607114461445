@import "../../../styles/fira-theme.scss";

.Wrapper {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 1px 8px;
  box-sizing: border-box;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
}

.Form {
  background-color: $light-background;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  .Title {
    padding: 14px 14px 14px 32px;
    border-bottom: 1px #d5dde0 solid;
    font-weight: 700;
    size: 16px;
    line-height: 24px;
  }
  form {
    padding: 40px 83px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    .Header {
      display: flex;
      flex-direction: column;
      gap: 16px;
      > * {
        text-align: center;
      }
      :first-child {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      }
      :last-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .Inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .Icon {
        color: $danger-color;
      }
      .InputWrapper {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        gap: 16px;
        > :first-child {
          font-weight: 500;
          font-size: 16px;
        }
        .ValueInputs {
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr 1fr;
        }
      }
      > :last-child {
        align-self: flex-start;
      }
      .AddText {
        font-weight: 600;
        font-size: 14px;
      }
    }
    .SubmitWrapper {
      gap: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
