@import "../../../styles/fira-theme.scss";

@mixin light-text {
  font-size: 14px;
  font-weight: 400;
  color: #8b8d97;
}

.EventGeneralDetailsWrapper {
  width: 100%;
  display: grid;
  grid-template-rows: 1;
  grid-template-columns: 1.3fr 0.7fr;
  gap: 40px;
  overflow-y: scroll;
  height: 100%;
  padding-right: 5px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $light-gray-color;
    border-radius: 4px;
  }
  &::-moz-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (max-width: 810px) {
    grid-template-columns: 1fr;
  }
  .FormWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .itemlabel {
      font-size: 14px;
    }
    .FormItem {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      label {
        font-size: 16px;
        font-weight: 500;
      }
      .DateTimeItem {
        display: flex;
        flex-direction: row;
        gap: 30px;
      }
    }
    .FormItemRow {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 8px;
    }

    .TextEditorInputWrapper {
      height: 113px;
    }

    .CategoriesContainer {
      max-height: 120px;
      overflow-y: scroll;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      .CategoryTag {
        cursor: pointer;
        padding: 0 6px;
        min-width: 76px;
        height: 24px;
        background-color: #f7f8f9;
        border-radius: 5px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        display: inline-block;
        text-align: center;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &.selected {
          background-color: $primary-color;
        }
      }
    }

    .CategoriesContainer::-webkit-scrollbar {
      display: none;
    }
  }
  .MultimediaWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 22px;
    @media (max-width: 755px) {
      flex-direction: row;
    }
    @media (max-width: 620px) {
      flex-direction: column;
    }

    .VideoContainer,
    .ImageContainer {
      width: 100%;
      height: 212px;
      border-radius: 8px;
      background-color: $main-background;
      overflow: hidden;

      .NoImage,
      .NoVideo {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        padding: 20px;
        gap: 8px;
        text-align: center;
        .Icon {
          width: 46.67px;
          height: 46.67px;
          background-color: rgba(0, 0, 0, 0.4);
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 24px;
          }
        }
        .Upload {
          display: flex;
          flex-direction: row;
          align-items: center;
          svg {
            font-size: 20px;
          }
          :first-child {
            margin-right: 12px;
          }
          :nth-child(2) {
            margin-right: 4px;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
          }
          :nth-child(3) {
            @include light-text;
          }
        }

        .InfoMessage {
          @include light-text;
        }
        .Format {
          font-size: 12px;
          font-weight: 400;
          :nth-child(2) {
            color: #8b8d97;
            position: relative;
            left: 4px;
          }
        }
      }

      .ImagePreview,
      .VideoPreview {
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        img,
        video {
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: center;
        }
        .DeleteIcon {
          z-index: 9999;
          position: absolute;
          top: 9px;
          right: 8px;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          border: 1px solid $danger-color;
          border-radius: 4px;
          cursor: pointer;
          svg {
            color: $danger-color;
          }
        }
      }
    }
  }
}

//Video Controls
#videoControls {
  display: flex;
  gap: 8px;
  height: 21px;
  width: 100%;
  z-index: 20;
  position: absolute;
  bottom: 8px;
  padding: 0 8px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .video-control {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 12px;
      path {
        opacity: 1;
      }
    }
  }
  .progress-bar {
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.4);
    height: 21px;
    border-radius: 6px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

//Video Controls

.rc-slider-track {
  background-color: #ff0005 !important;
}
