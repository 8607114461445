@import "../../../styles/fira-theme.scss";

.ColorInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  label {
    font-weight: 500;
    font-size: 16px;
  }
  .ColorInput {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    span {
      color: #010202;
      font-weight: 400;
      font-size: 12px;
    }
    &__selector {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      cursor: pointer;
      box-sizing: border-box;
    }
    &__noColor {
      width: 16px;
      height: 16px;
      background-color: $light-background;
      border-radius: 2px;
      border: 1px solid $medium-gray-color;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &__line {
        width: 23px;
        height: 1px;
        background-color: $medium-gray-color;
        transform: rotate(-45deg);
        position: absolute;
      }
    }
    input[type="text"] {
      border: 0;
      border-bottom: 1px solid #d0d0d0;
      width: 80px;
      font-size: 14px;
      font-weight: 400;
      font-family: "Montserrat";
      color: #010202;
      &:focus {
        border-bottom: 1px solid $primary-color;
        outline: none;
      }
    }
  }
}

.ColorPicker {
  position: absolute;
  z-index: 5;
}

.subtitle {
  font-weight: 300;
  font-size: 14px;
}

.WhiteColor {
  border: 1px solid $medium-gray-color;
}
