@import "../../styles/fira-theme.scss";

.NavButton {
  display: flex;
  gap: 12px;
  height: 32px;
  width: 100%;
  text-decoration: none;
  color: $dark-secondary-background;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  border-radius: 4px;
  padding-left: 8px;
  box-sizing: border-box;
  &__Icon {
    font-size: 28px;
    position: relative;
    display: flex;
    width: 28px;
    justify-content: center;
  }
}

.BottomIcon {
  font-size: 28px;
  position: relative;
  bottom: 3px;
}

.NavButton:hover {
  border: 0.5px solid $dark-secondary-background;
}

.isActive {
  background-color: $primary-color;
  border: none;
}

.navSubButton {
  height: 17px;
  text-decoration-line: none;
  align-items: center;
  display: grid;
  grid-template-columns: 16px 1fr;
  padding-left: 63px;
  .subButtonName {
    color: #6e7175;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    &.isSubActive {
      font-weight: 600;
    }
  }
}
