@import '../../styles/fira-theme.scss';

.InputRange {
  width: 100%;
  position: relative;
  height: 24px;
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 0;
    z-index: 999;
    position: absolute;
    top: 2px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    background-color: $primary-color;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  input[type="range"]::-moz-range-thumb {
    height: 8px;
    width: 8px;
    background-color: $primary-color;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  .fauxInputBg {
    width: 100%;
    height: 8px;
    background-color: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset;
    border-radius: 4px;
    position: absolute;
  }

  .fauxInputProgress {
    width: 100%;
    height: 8px;
    background-color: transparent;
    border-radius: 4px;
    position: absolute;
    z-index: 999;
  }

}