@import "../../../styles/fira-theme.scss";

.CreateStoreWrapperSuccess{
  width: 384px;
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 0 30px 0;
  height: 500px;

  .Text-success{
    text-align: center;
    margin-top: 14px;
    margin-left: 35px;
    margin-right: 35px;
  }
}

.CreateStoreWrapper {
  width: 384px;
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 0 30px 0;
  height: 600px;

  .row {
    display: flex;
    flex-direction: row;

    &.space {
      justify-content: space-between;
      width: 100%;
    }

    &.center {
      align-items: center;
    }
    .mr {
      margin-right: 8px;
    }
  }

  .header {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    position: relative;
    justify-content: center;
    .icon {
      font-size: 28px;
      margin-right: 57px;
      cursor: pointer;
      //float: left;
      position: absolute;
      left: 36px;
    }
  }

  .formCreateStoreContainer {
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    overflow: hidden;
    .scroll-view {
      height: 100%;
      overflow: scroll;
      padding-right: 15px;
    }

    .formGroup {
      margin-top: 20px;
      position: relative;

      &.mr {
        margin-right: 9px;
      }
    }

    .buttonGroup {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      width: 275px;
      justify-content: center;
    }

    .imageContainer {
      margin-bottom: 14px;
    }
  }
}

.row:first-child {
  margin: 0;
}
